import axios from "axios";
import { NavLink } from "react-router-dom";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult, Statistic } from "../api/reponseResult";

class BoxappDashboardBackend extends BoxappBackend {
  monthlyRevenue = async (): Promise<Statistic> => {
    try {
      const data = await this.get(`${this.baseURL}/dashboard/monthlyRevenue`);
      return data as any as Statistic;
    } catch (err) {
      throw err;
    }
  };
  latestOrdersStatus = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/dashboard/latestOrdersStatus`);
    } catch (err) {
      throw err;
    }
  };
  latestStorageStatus = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/dashboard/latestStorageStatus`);
    } catch (err) {
      throw err;
    }
  };
  latestMonthOrders = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/dashboard/latestMonthOrders`);
    } catch (err) {
      throw err;
    }
  };
}
let _BoxappDashboardBackend: BoxappDashboardBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappDashboardBackend = () => {
  if (!_BoxappDashboardBackend) {
    _BoxappDashboardBackend = new BoxappDashboardBackend();
  }
  return _BoxappDashboardBackend;
};

export { getBoxappDashboardBackend };
