import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Table,
  Button,
} from "reactstrap";

import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import { BxButton } from "../../Button";
import { AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./storageRent.scss";
import {
  useCustomerContext,
  CUSTOMER_DIALOG_TYPES,
} from "../../../context/CustomerProviderContext";
import { useBranchContext } from "../../../context/BranchProviderContext";

import { Customer } from "src/models/customer";
import { MultiLangText } from "src/models/multiLangText";
import RentalPeriod, { RentPeriod } from "./RentalPeriod";
import { Branch } from "src/models/branch";
import { Order } from "src/models/order";
import { Contract } from "src/models/contract";
import { getBoxappContractBackend } from "src/helpers/boxapp_contract_helper";
import { Storage } from "src/models/storage";
import { RentMode } from "./StorageRent";
import Decimal from "decimal.js";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import StorageSearch from "./StorageSearch";
import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "src/context/GlobalModalContext";

export interface ContractPanelProps {
  customer?: Customer;
  storages?: Storage[];
  mode?: string;
  totalSteps: number;
  onCancel: () => void;
  isFinished: boolean;
  open: boolean;
  step: number;
  branch: Branch;
  order?: Order;
  startDate: number;
  contract?: Contract;
  rentMode: RentMode;
  onSubmit: (data: any) => void;
  onContractConflict: (data?: Contract) => void;
}

const ContractPanel = (props: ContractPanelProps) => {
  const [customer, setCustomer] = useState<Customer>();
  const { i18n } = useTranslation();
  const [remarks, setRemarks] = useState<string>("");
  const [totalDiscountAmount, setTotalDiscountAmount] = useState<Decimal>();
  const [totalOriginalPrice, setTotalOriginalPrice] = useState<Decimal>();
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const [rentType, setRentType] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY">();
  const [rentPeriod, setRentPeriod] = useState<number>(0);
  const [contract, setContract] = useState<Contract>();
  const [contracts, setContracts] = useState<Contract[]>();
  const [searchStorage, setSearchStorage] = useState(false);
  const { showDialog, hideDialog } = useCustomerContext();
  const { showModal } = useGlobalModalContext();
  const [contractConflict, setContractConflict] = useState<Contract>();
  const { contextBranch } = useBranchContext();

  const [isAddingMonthChecked, setIsAddingMonthChecked] = useState(false);
  const [addFreeMonthsCount, setAddFreeMonthsCount] = useState<number>(0);
  const [freeMonths, setFreeMonths] = useState<number>(0);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [freeDays, setFreeDays] = useState<number>(0);
  const [contractStorages, setContractStorages] = useState<
    {
      id?: string;
      monthly_price: Decimal;
      yearly_price: Decimal;
      weekly_price: Decimal;
      daily_price: Decimal;
      originalPrice: Decimal;
      discountAmount: Decimal;
      finalPrice: Decimal;
      code: string;
      address?: MultiLangText;
    }[]
  >(
    props.storages?.map((storage: Storage) => {
      return {
        id: storage?.id,
        monthly_price: new Decimal(storage?.monthly_price ?? 0),
        yearly_price: new Decimal(storage?.yearly_price ?? 0),
        weekly_price: new Decimal(storage?.weekly_price ?? 0),
        daily_price: new Decimal(storage?.daily_price ?? 0),
        originalPrice: new Decimal(storage?.yearly_price ?? 0),
        discountAmount: new Decimal(0),
        finalPrice: new Decimal(storage?.yearly_price ?? 0),
        code: storage?.code ?? "",
        address: storage?.address,
      };
    }) ?? []
  );
  const [customerType, setCustomerType] = useState(
    props.customer ? "EXISTING" : "NEW"
  );
  const [newCustomerName, setNewCustomerName] = useState("");

  const finalPrice = useMemo(
    () => new Decimal(totalOriginalPrice ?? 0).minus(totalDiscountAmount ?? 0),
    [totalOriginalPrice, totalDiscountAmount]
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (startDate && endDate && customer?.id) {
      const storage_ids: string[] =
        contractStorages
          ?.filter((s) => s.id !== undefined)
          .map((s) => s.id as string) ?? [];
      if (storage_ids.length === 0) {
        return;
      }

      getBoxappContractBackend()
        .getContracts({
          storage_ids: storage_ids,
          customer_id: customer?.id,
          start_date: startDate,
          end_date: endDate,
          status: "ACTIVE",
        })
        .then((res) => {
          const conflict = res?.contracts?.data?.find(
            (c) => c.customer?.id === customer?.id
          );
          if (props.onContractConflict) {
            props.onContractConflict(conflict);
          }

          setContractConflict(conflict);
          setContracts(res?.contracts?.data as Contract[]);
        })
        .catch((err) => {
          if (props.onContractConflict) {
            props.onContractConflict(undefined);
          }
          setContractConflict(undefined);
          setContracts(undefined);
        });
    } else {
      if (props.onContractConflict) {
        props.onContractConflict(undefined);
      }
      setContractConflict(undefined);
      setContracts(undefined);
    }
  }, [startDate, endDate, customer]);

  useEffect(() => {
    setCustomer(undefined);
    setContract(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setRentPeriod(0);
    setRentType(undefined);
    switch (props.rentMode) {
      case RentMode.ContinueRent: {
        setCustomer(props.customer);
        setContract(props?.contract);
        setStartDate(props.contract?.start_date!);
        setEndDate(props.contract?.end_date);
        break;
      }
      case RentMode.NewContract: {
        const contract = props.contract ?? props.storages?.[0]?.contract;
        setCustomer(props.customer);
        // setContract(contract);
        if (contract) {
          const start_date = moment
            .unix(contract?.end_date)
            .add(1, "days")
            .unix();
          setStartDate(start_date);
          setRentPeriod(contract?.rent_period);
          setRentType(contract?.rent_type);
          setEndDate(
            moment
              .unix(start_date)
              .add(
                contract?.rent_period,
                contract?.rent_type?.toLowerCase() as any
              )
              .subtract(1, "days")
              .unix()
          );
        } else {
          setStartDate(moment().unix());
        }

        break;
      }

      default: {
      }
    }
  }, [props.rentMode]);

  const searchCustomer = () => {
    if (!contextBranch) {
      return;
    }
    showDialog(CUSTOMER_DIALOG_TYPES.SEARCH_MODAL, {
      branch: contextBranch,
      onSearched: (customer) => {
        if (!customer) {
          return;
        }
        setCustomer(customer);
        hideDialog();
      },
      onClose: () => {
        hideDialog();
      },
    });
  };

  {
    /* DISCOUNT CONST SECTION START */
  }
  const applyDiscount = (percentage: number) => {
    const discount = new Decimal(totalOriginalPrice ?? 0)
      .times(percentage / 100)
      .toNumber();
    setTotalDiscountAmount(new Decimal(discount));
    const remarkText = i18n.t("Applied {{percentage}}% Discount", {
      percentage,
    });
    setRemarks((prev) => (prev ? `${prev}[${remarkText}]` : `[${remarkText}]`));
  };

  const [discountType, setDiscountType] = useState<
    "percentage" | "freeMonths" | "addFreeMonths" | "freeDays" | null
  >(null);

  const handleCheckboxChange = (
    type: "percentage" | "freeMonths" | "addFreeMonths" | "freeDays"
  ) => {
    setRemarks((prev) => (prev ? prev.replace(/\[.*?\]/g, "") : ""));

    if (discountType === type) {
      if (type === "addFreeMonths" && isAddingMonthChecked) {
        // toggleFreeMonths(addFreeMonthsCount);
        setIsAddingMonthChecked(false);
      }
      setDiscountType(null);
      resetInputs();
    } else {
      setDiscountType(type);
    }
  };

  const applyFreeDaysAsDiscount = (days: number) => {
    if (
      totalOriginalPrice !== undefined &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      const startDateMoment = moment.unix(startDate);
      const endDateMoment = moment.unix(endDate);
      const totalDays = endDateMoment.diff(startDateMoment, "days") + 1;

      const dailyPrice = new Decimal(totalOriginalPrice).div(totalDays);
      const discount = Math.round(dailyPrice.times(days).toNumber());
      setTotalDiscountAmount(new Decimal(discount));

      const remarkText = i18n.t("Applied {{days}} Day(s) Free as Discount", {
        days,
      });
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const applyFreeMonthsAsDiscount = (months: number) => {
    if (
      totalOriginalPrice !== undefined &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      let discount;
      const startDateMoment = moment.unix(startDate);
      const endDateMoment = moment.unix(endDate);
      const totalDays = endDateMoment.diff(startDateMoment, "days");

      if (rentType === "MONTH") {
        const monthlyPrice = new Decimal(totalOriginalPrice).div(rentPeriod);
        discount = Math.round(monthlyPrice.times(months).toNumber());
      } else {
        const monthlyPrice = new Decimal(totalOriginalPrice).div(
          totalDays / 30
        );
        discount = Math.round(monthlyPrice.times(months).toNumber());
      }

      setTotalDiscountAmount(new Decimal(discount));
      const remarkText = i18n.t(
        "Applied {{months}} Month(s) Free as Discount",
        {
          months,
        }
      );
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const toggleFreeMonths = (months: number) => {
    if (
      startDate !== undefined &&
      endDate !== undefined &&
      rentPeriod !== undefined
    ) {
      let newRentPeriod = rentPeriod;

      if (rentType === "MONTH") {
        newRentPeriod = isAddingMonthChecked
          ? (rentPeriod || 0) - months
          : (rentPeriod || 0) + months;
      }

      const currentEndDate = moment.unix(endDate);
      const updatedEndDate = currentEndDate
        .add(isAddingMonthChecked ? -months : months, "month")
        .unix();

      setEndDate(updatedEndDate);
      setRentPeriod(newRentPeriod);
      setIsAddingMonthChecked(!isAddingMonthChecked);

      if (!isAddingMonthChecked) {
        const remarkText = i18n.t("Add {{months}} Month(s) Free", { months });
        setRemarks((prev) =>
          prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
        );
      } else {
        const remarkText = i18n.t("Add {{months}} Month(s) Free", { months });
        setRemarks((prev) => (prev ? prev.replace(`[${remarkText}]`, "") : ""));
      }
    }
  };

  const resetInputs = () => {
    setDiscountPercentage(0);
    setFreeMonths(0);
    setAddFreeMonthsCount(0);
    setIsAddingMonthChecked(false);
    setTotalDiscountAmount(new Decimal(0));
  };

  {
    /* DISCOUNT CONST SECTION END */
  }
  return (
    <AvForm
      hidden={!props.open}
      action="#"
      id={"CollapseForm" + props.step}
      title={
        !props.isFinished
          ? `${i18n.t("Contract")}`
          : `${i18n.t("Contract")} (${moment
              .unix(startDate as number)
              ?.format("YYYY-MM-DD")} - ${moment
              .unix(endDate as number)
              ?.format("YYYY-MM-DD")})`
      }
      onSubmit={async () => {
        if (props.rentMode === RentMode.ContinueRent) {
          if (contract) {
            props.onSubmit({
              ...contract,
              customer: customer,
            });
            return;
          }
        } else if (props.rentMode === RentMode.NewContract) {
          if (!contract) {
            if (!customer) {
              console.log("customer");
              return;
            }
            if (!totalOriginalPrice) {
              console.log("originalPrice");
              return;
            }
            if (!startDate || !endDate) {
              return;
            }
            if (startDate >= endDate && rentType !== "DAY") return;
            if (finalPrice?.isNegative()) {
              console.log("finalPrice");
              return;
            }
            if (!rentType) {
              console.log("rentType");
              return;
            }

            if (!contextBranch) {
              console.log("contextBranch");
              return;
            }
            try {
              const { contract } = await getBoxappContractBackend().create({
                branch_id: contextBranch?.id,
                start_date: startDate,
                end_date: endDate,
                rent_type: rentType,
                rent_period: rentPeriod,
                customer_id: customer?.id,
                contract_storages:
                  (contractStorages
                    ?.map((contractStorage) => {
                      if (!contractStorage?.id) {
                        return undefined;
                      }
                      return {
                        storage_id: contractStorage?.id,
                        original_price:
                          contractStorage?.originalPrice.toNumber(),
                        discount_amount:
                          contractStorage?.discountAmount?.toNumber(),
                        final_price: contractStorage?.finalPrice.toNumber(),
                      };
                    })
                    ?.filter((s) => s !== undefined) as Array<{
                    storage_id: string;
                    original_price: number;
                    discount_amount?: number;
                    final_price: number;
                  }>) ?? [],
                final_price: finalPrice.toNumber(),
                original_price: totalOriginalPrice.toNumber(),
                remarks: remarks,
              });
              if (contract) {
                showModal(MODAL_TYPES.ALERT_MODAL, {
                  title: i18n.t("Create Contract Success"),
                  messageType: "success",
                  message: i18n.t("Create Contract Success"),
                });
                props.onSubmit({
                  ...contract,
                  customer: customer,
                });
                return;
              }
            } catch (e) {
              const errorMsg =
                (e as any)?.data?.detail ??
                "" + (e as any)?.data?.message ??
                "" + e?.toString();
              showModal(MODAL_TYPES.ALERT_MODAL, {
                title: i18n.t("rent rental location failed"),
                messageType: "error",
                message: errorMsg,
              });
            }
          } else {
            props.onSubmit({
              ...contract,
              customer: customer,
            });

            return;
          }
        }
      }}
    >
      <Card outline color="light" className="border">
        <CardBody>
          <FormGroup className="mt-2">
            <p className="font-weight-bold border-bottom border-3 border-secondary font-size-13">
              {`1. ${i18n.t("Customer")}:`}
            </p>

            {props.mode === RentMode.NewContract ? (
              <>
                {/* 添加“现有客户”和“新客户”按钮 */}
                <div
                  className="btn-group btn-group-toggle mb-2"
                  data-toggle="buttons"
                >
                  {props.customer && (
                    <>
                      <label
                        className={
                          customerType === "EXISTING"
                            ? "btn btn-success active w-lg"
                            : "btn btn-outline-success w-lg"
                        }
                        style={{ borderRadius: "5px", marginRight: "5px" }}
                      >
                        <input
                          type="radio"
                          name="customerType"
                          id="existingCustomer"
                          onClick={() => setCustomerType("EXISTING")}
                        />
                        {i18n.t("Existing Customer Extend Contract")}
                      </label>
                      <label
                        className={
                          customerType === "NEW"
                            ? "btn btn-success active w-lg"
                            : "btn btn-outline-success w-lg"
                        }
                        style={{ borderRadius: "5px" }}
                      >
                        <input
                          type="radio"
                          name="customerType"
                          id="newCustomer"
                          onClick={() => setCustomerType("NEW")}
                        />
                        {i18n.t("New Customer New Contract")}
                      </label>
                    </>
                  )}
                </div>

                {/* 客户信息字段 */}
                <Row>
                  <Col xs="10">
                    <div
                      onClick={() => {
                        if (
                          customerType === "NEW" &&
                          props.mode === RentMode.NewContract
                        ) {
                          searchCustomer();
                        }
                      }}
                      style={{
                        cursor:
                          customerType === "NEW" ? "pointer" : "not-allowed",
                      }}
                    >
                      <AvField
                        name="customerName"
                        placeholder={i18n.t("Customer Name")}
                        type="text"
                        readOnly
                        errorMessage={i18n.t("Select a customer")}
                        className={
                          customerType === "EXISTING"
                            ? "form-control bg-light text-gray bg-opacity-10"
                            : "form-control"
                        }
                        validate={{ required: { value: true } }}
                        id="validationCustomName"
                        value={customer?.other_name ?? ""}
                      />
                    </div>
                  </Col>
                  {customerType === "NEW" &&
                    props.mode === RentMode.NewContract && (
                      <Col xs="2">
                        <Link
                          to="#"
                          color="light"
                          className="waves-effect waves-light btn"
                          onClick={() => {
                            if (props.mode !== "extend") {
                              searchCustomer();
                            }
                          }}
                        >
                          <i className="bx bx-search-alt font-size-16 align-middle mr-2"></i>
                        </Link>
                      </Col>
                    )}
                </Row>

                {/* 显示客户的信息 */}
                {customer && (
                  <Row>
                    <Col xs="12">
                      {`${customer?.last_name} ${customer?.first_name}`}
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              // 当模式不是 NewContract 时，保持原有代码
              <>
                <Row>
                  <Col xs="10">
                    <AvField
                      name="customerName"
                      placeholder={i18n.t("Customer Name")}
                      type="text"
                      readOnly
                      errorMessage={i18n.t("Select a customer")}
                      className="form-control bg-light text-gray bg-opacity-10"
                      validate={{ required: { value: true } }}
                      disabled
                      id="validationCustomName"
                      value={customer?.other_name ?? ""}
                      onClick={() => {
                        if (props.mode === RentMode.NewContract) {
                          searchCustomer();
                        }
                      }}
                    />
                  </Col>
                  {props.mode === RentMode.NewContract && (
                    <Col xs="2">
                      <Link
                        to="#"
                        color="light"
                        className="waves-effect waves-light btn"
                        onClick={() => {
                          if (props.mode !== "extend") {
                            searchCustomer();
                          }
                        }}
                      >
                        <i className="bx bx-search-alt font-size-16 align-middle mr-2"></i>
                      </Link>
                    </Col>
                  )}
                </Row>
                {customer && (
                  <Row>
                    <Col xs="12">
                      {`${customer?.last_name} ${customer?.first_name}`}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </FormGroup>

          {props.rentMode === RentMode.NewContract && (
            <div>
              <FormGroup className="mt-2">
                <StorageSearch
                  modal={searchStorage}
                  branchId={contextBranch?.id}
                  selectStorageMode={"OnlyAvailable"}
                  onSearched={(storage) => {
                    if (
                      !contractStorages.find((s) => s.code === storage?.code)
                    ) {
                      const newContractStorages = contractStorages;
                      newContractStorages.push({
                        id: storage?.id,
                        monthly_price: new Decimal(storage?.monthly_price ?? 0),
                        yearly_price: new Decimal(storage?.yearly_price ?? 0),
                        weekly_price: new Decimal(storage?.weekly_price ?? 0),
                        daily_price: new Decimal(storage?.daily_price ?? 0),
                        originalPrice: new Decimal(storage?.yearly_price ?? 0),
                        discountAmount: new Decimal(0),
                        finalPrice: new Decimal(storage?.yearly_price ?? 0),
                        code: storage?.code ?? "",
                        address: storage?.address,
                      });
                      setContractStorages(newContractStorages);
                      setSearchStorage(false);
                    }
                  }}
                  onClose={() => {
                    setSearchStorage(false);
                  }}
                />

                <p className="font-weight-bold border-bottom border-3 border-secondary font-size-13">
                  {`2. ${i18n.t("Property Information")}:`}
                  {/* <Link
                    to="#"
                    className="text-primary"
                    onClick={() => {
                      setSearchStorage(true);
                    }}
                  >
                    +
                  </Link> */}
                </p>

                <Row className="mb-4">
                  <Col xs={12}>
                    <table className="table table-striped">
                      <thead>
                        <Tr>
                          <Th className="co-name">{i18n.t("Code")}</Th>
                          <Th className="co-name">{i18n.t("Address")}</Th>
                          <Th>{i18n.t("Orignal Price")}</Th>
                        </Tr>
                      </thead>
                      <tbody>
                        {contractStorages?.map((storage, i) => {
                          return (
                            <Tr
                              key={i}
                              xl="2"
                              lg="2"
                              sm="4"
                              xs="6"
                              className={`col-sm-1 col-md-1 mt-1 mr-1`}
                            >
                              <Td>
                                <span className="font-size-15">
                                  {storage?.code}
                                </span>
                              </Td>
                              <Td>
                                <span className="font-size-15">
                                  {storage?.address?.[
                                    i18n.language as keyof MultiLangText
                                  ] ?? ""}
                                </span>
                              </Td>
                              <Td>
                                <span className="font-size-15">
                                  {storage?.originalPrice?.toNumber()}
                                </span>
                              </Td>
                              <Td>
                                {i !== 0 && (
                                  <i
                                    className="btn text-primary mdi mdi-trash-can-outline font-size-18"
                                    onClick={async () => {
                                      setContractStorages(
                                        contractStorages.filter(
                                          (s, index) => index !== i
                                        )
                                      );
                                    }}
                                  ></i>
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </FormGroup>
              <p className="font-weight-bold border-bottom border-3 border-secondary font-size-13">
                {`3. ${i18n.t("Rental Period")}:`}
              </p>
              <Row className="mt-2">
                <Col>
                  <RentalPeriod
                    name={props.rentMode}
                    allow_yearly={contextBranch?.allow_yearly}
                    allow_monthly={contextBranch?.allow_monthly}
                    allow_weekly={contextBranch?.allow_weekly}
                    allow_daily={contextBranch?.allow_daily}
                    periodTitle={i18n.t("Contract")}
                    startDate={Number(startDate) ?? moment().unix()}
                    endDate={Number(endDate) ?? moment().unix()}
                    defaultRentType={rentType ?? "YEAR"}
                    rentPeriod={Number(rentPeriod) ?? 1}
                    onChange={(e: RentPeriod) => {
                      console.log(e.rent_type);
                      setStartDate(e.start_date);
                      setEndDate(e.end_date);
                      setRentType(e.rent_type);
                      setRentPeriod(e.rent_period);
                      const newContractStorages = contractStorages.map(
                        (storage) => {
                          return {
                            ...storage,
                            originalPrice: new Decimal(e.rent_period).times(
                              (e.rent_type === "YEAR"
                                ? storage.yearly_price
                                : e.rent_type === "MONTH"
                                ? storage.monthly_price
                                : e.rent_type === "WEEK"
                                ? storage.weekly_price
                                : storage.daily_price) ?? 0
                            ),
                          };
                        }
                      );
                      setContractStorages(newContractStorages ?? []);
                      setTotalOriginalPrice(
                        newContractStorages.reduce(
                          (acc, storage) => acc.plus(storage.originalPrice),
                          new Decimal(0)
                        )
                      );
                    }}
                  ></RentalPeriod>
                </Col>
              </Row>
              <FormGroup className="mt-2">
                {startDate && endDate && (
                  <Row>
                    <Col xs="12">
                      {contractConflict && (
                        <Alert
                          color="warning"
                          className="offcanvas alert-dismissible fade show mt-1 md-1 p-1 text-center font-size-13"
                          role="alert"
                        >
                          <i className="fas fa-exclamation-triangle mr-2"></i>
                          <div className="text-center">
                            <b>{`${i18n.t("Current Contract")}`}</b>
                          </div>
                          <div className="text-center">
                            {`${i18n.t("Contract Period")}: [${
                              startDate
                                ? moment.unix(startDate).format("YYYY-MM-DD")
                                : "N/A"
                            } - ${
                              endDate
                                ? moment.unix(endDate).format("YYYY-MM-DD")
                                : "N/A"
                            }]`}
                          </div>
                          <div className="text-center">
                            {`${i18n.t(
                              "This customer has a contract overlapping with the rental period."
                            )}`}
                          </div>

                          <div className="text-center">
                            {`${i18n.t("You can now")}：`}
                          </div>
                          <div className="text-center">
                            {`${i18n.t(
                              "1. Adjust the contract period above."
                            )}`}
                          </div>
                          <div className="text-center">
                            {`${i18n.t(
                              "2. Select the used contract below to continue."
                            )}`}
                          </div>
                          <div className="text-center">
                            {`${i18n.t(
                              "3. Or find the contract in Contract Management and set it to end."
                            )}`}
                          </div>
                        </Alert>
                      )}
                      {contractConflict && (
                        <Table>
                          <Thead>
                            <tr>
                              <th>{i18n.t("Contract ID")}</th>
                              <th>{i18n.t("Customer")}</th>
                              <th>{i18n.t("Contract Period")}</th>
                              <th>{i18n.t("Orignal Price")}</th>
                              <th>{i18n.t("Discount Amount")}</th>
                              <th>{i18n.t("Final Price")}</th>
                            </tr>
                          </Thead>
                          <Tbody>
                            {contracts &&
                              contracts?.length > 0 &&
                              contracts?.map((c, i) => {
                                return (
                                  <tr
                                    style={{ cursor: "pointer" }}
                                    color={
                                      contractConflict &&
                                      contractConflict.id === c.id
                                        ? "warning"
                                        : ""
                                    }
                                    key={i}
                                    onClick={() => {
                                      setContract(c);
                                      setStartDate(c.start_date);
                                      setEndDate(c.end_date);
                                      setRentType(c.rent_type);
                                      setRentPeriod(c.rent_period);
                                      setTotalOriginalPrice(
                                        c.original_price
                                          ? new Decimal(c.original_price)
                                          : new Decimal(0)
                                      );
                                      setTotalDiscountAmount(
                                        c.discount_amount
                                          ? new Decimal(c.discount_amount)
                                          : new Decimal(0)
                                      );
                                      setRemarks(c.remarks);
                                    }}
                                  >
                                    <td>
                                      <i className="bx bxs-label bx-tada mr-2 text-danger font-size-20" />
                                      {c.id}
                                    </td>
                                    <td>{`${c.customer?.last_name} ${c.customer?.first_name}`}</td>
                                    <td>{`${moment
                                      .unix(c.start_date)
                                      .format("YYYY-MM-DD")} - ${moment
                                      .unix(c.end_date)
                                      .format("YYYY-MM-DD")} (${
                                      c.rent_period
                                    } ${i18n.t(c.rent_type)})`}</td>
                                    <td>{c.original_price}</td>
                                    <td>{c.discount_amount}</td>
                                    <td>{c.final_price}</td>
                                  </tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      )}
                    </Col>
                  </Row>
                )}
              </FormGroup>
            </div>
          )}
          <Row>
            <Col>
              {contract && (
                <Row className="mt-2">
                  <Col xs="12">
                    <Card>
                      <CardHeader
                        className={
                          props.rentMode === RentMode.ContinueRent
                            ? "bg-success"
                            : "bg-warning"
                        }
                      >
                        {props.rentMode === RentMode.ContinueRent
                          ? i18n.t("Contract")
                          : i18n.t(
                              "Found that you have an existing contract in this period. Please use Coniune Rent to create order, or select a new period."
                            )}
                      </CardHeader>
                      <CardBody>
                        <Row className="border-bottom mb-2">
                          <Col>
                            <p className="mb-2">
                              {i18n.t("Contract ID")}: <br />
                              {i18n.t(contract?.id)}
                            </p>
                          </Col>
                          <Col>
                            <p className="mb-2">
                              {i18n.t("Deposit Number")}: <br />
                              {i18n.t(
                                contract?.deposit_order?.order_no ?? "N/A"
                              )}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="mb-2">
                              {i18n.t("Contract Period")}: <br />
                              {`${moment
                                .unix(contract?.start_date)
                                .format("YYYY-MM-DD")} - ${moment
                                .unix(contract?.end_date)
                                .format("YYYY-MM-DD")} (${
                                contract?.rent_period
                              } ${i18n.t(contract?.rent_type)})`}
                            </p>
                          </Col>
                        </Row>
                        <Label className="font-weight-bold">
                          {i18n.t("Price")}:
                        </Label>
                        <Row className="border-bottom mb-2">
                          <Col>
                            <p className="mb-2">
                              {i18n.t("Original Price")}: <br />
                              {contract?.original_price}
                            </p>
                          </Col>
                          <Col>
                            <p className="mb-2">
                              {i18n.t("Discount Amount")}: <br />
                              {contract?.discount_amount}
                            </p>
                          </Col>
                          <Col>
                            <p className="mb-2">
                              {i18n.t("Final Price")}: <br />
                              {contract?.final_price}
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              {!contract && (
                <p className="font-weight-bold border-bottom border-3 border-secondary font-size-13">
                  {`4. ${i18n.t("Price")}:`}
                </p>
              )}
              {!contract && (
                <Row className="mt-2">
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label>{i18n.t("Original Price")}</Label>
                      <AvField
                        name="originalAmount"
                        placeholder={i18n.t("Original Amount")}
                        type="number"
                        errorMessage={i18n.t("invalid Original Amount")}
                        className="form-control  font-size-15"
                        id="validatiomOriginalAmount"
                        value={totalOriginalPrice?.toNumber()}
                        validate={{
                          min: { value: 0 },
                          required: { value: true },
                        }}
                        onChange={(e: any) =>
                          setTotalOriginalPrice(
                            new Decimal(e.target.value ?? 0)
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="3">
                    <FormGroup className="mb-4">
                      <Label>{i18n.t("Discount Amount")}</Label>
                      <AvField
                        name="discountAmount"
                        placeholder={i18n.t("Discount Amount")}
                        type="number"
                        errorMessage={i18n.t("invalid Discount Amount")}
                        className="form-control"
                        id="validatiomDiscountAmount"
                        value={totalDiscountAmount}
                        validate={{ max: { value: totalOriginalPrice } }}
                        onChange={(e: any) =>
                          setTotalDiscountAmount(
                            new Decimal(e.target.value ?? 0)
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="3">
                    <FormGroup>
                      <Label>{i18n.t(" ")}</Label>
                      <BxButton
                        className="btn btn-secondary waves-effect mt-2 btn-block d-flex justify-content-between align-items-center font-size-13"
                        type="button"
                        color="secondary"
                        onClick={toggleVisibility}
                      >
                        {i18n.t("Apply Discounts")}
                        <i
                          className={`fas fa-chevron-${
                            isOpen ? "up" : "down"
                          } ml-2`}
                        ></i>
                      </BxButton>
                    </FormGroup>
                  </Col>
                  {/* DISCOUNT BUTTON SECTION START */}
                  {isOpen && (
                    <Row
                      style={{
                        backgroundColor: "#eaeeef",
                        border: "1px solid #dee2e6",
                        borderRadius: "0.25rem",
                        padding: "1rem",
                      }}
                      className="mb-4 mt-1"
                    >
                      <Col xs="12">
                        <div style={{ color: "red", marginBottom: "10px" }}>
                          {`(${i18n.t("ONLY Apply for the property.")} ${i18n.t(
                            "One discount apply at the same time only"
                          )})`}
                        </div>
                      </Col>
                      <Col xs="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={discountType === "percentage"}
                              onChange={() =>
                                handleCheckboxChange("percentage")
                              }
                              disabled={
                                discountType !== null &&
                                discountType !== "percentage"
                              }
                              style={{
                                cursor:
                                  discountType !== null &&
                                  discountType !== "percentage"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            />
                            <span
                              style={{
                                color:
                                  discountType !== null &&
                                  discountType !== "percentage"
                                    ? "gray"
                                    : "black",
                              }}
                            >
                              {`${i18n.t("Apply Discount")} %`}
                            </span>
                          </Label>
                          <div className="input-group mt-2">
                            <Input
                              type="number"
                              value={discountPercentage}
                              onChange={(e) =>
                                setDiscountPercentage(Number(e.target.value))
                              }
                              disabled={discountType !== "percentage"}
                              style={{
                                backgroundColor:
                                  discountType !== "percentage"
                                    ? "#e9ecef"
                                    : "white",
                              }}
                            />
                            <Button
                              onClick={() => applyDiscount(discountPercentage)}
                              disabled={discountType !== "percentage"}
                            >
                              {i18n.t("Apply Discount")}
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={discountType === "freeDays"}
                              onChange={() => handleCheckboxChange("freeDays")}
                              disabled={
                                discountType !== null &&
                                discountType !== "freeDays"
                              }
                              style={{
                                cursor:
                                  discountType !== null &&
                                  discountType !== "freeDays"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            />
                            <span
                              style={{
                                color:
                                  discountType !== null &&
                                  discountType !== "freeDays"
                                    ? "gray"
                                    : "black",
                              }}
                            >
                              {i18n.t("Apply X Day Free (as discount)")}
                              <a data-tip data-for="applyXDayFreeTips1">
                                {` ${i18n.t("ⓘ")}`}
                              </a>
                            </span>
                            <ReactTooltip
                              id="applyXDayFreeTips1"
                              effect="solid"
                            >
                              <span>
                                <p>
                                  {`${i18n.t(
                                    "Count the daily rate (Total Day / Rental Price) times X days."
                                  )}`}
                                </p>
                              </span>
                            </ReactTooltip>
                          </Label>
                          <div className="input-group mt-2">
                            <Input
                              type="number"
                              value={freeDays}
                              onChange={(e) =>
                                setFreeDays(Number(e.target.value))
                              }
                              disabled={discountType !== "freeDays"}
                              style={{
                                backgroundColor:
                                  discountType !== "freeDays"
                                    ? "#e9ecef"
                                    : "white",
                              }}
                            />
                            <Button
                              onClick={() => applyFreeDaysAsDiscount(freeDays)}
                              disabled={discountType !== "freeDays"}
                            >
                              {i18n.t("Apply Free Days")}
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col xs="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={discountType === "freeMonths"}
                              onChange={() =>
                                handleCheckboxChange("freeMonths")
                              }
                              disabled={
                                discountType !== null &&
                                discountType !== "freeMonths"
                              }
                              style={{
                                cursor:
                                  discountType !== null &&
                                  discountType !== "freeMonths"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            />
                            <span
                              style={{
                                color:
                                  discountType !== null &&
                                  discountType !== "freeMonths"
                                    ? "gray"
                                    : "black",
                              }}
                            >
                              {i18n.t("Apply X Month Free (as discount)")}
                              <a data-tip data-for="applyXMonthFreeTips1">
                                {` ${i18n.t("ⓘ")}`}
                              </a>
                            </span>

                            <ReactTooltip
                              id="applyXMonthFreeTips1"
                              effect="solid"
                            >
                              <span>
                                <p>
                                  {`${i18n.t(
                                    "If the Rent Period is not MONTH, that will count the daily rate (Total Day / Rental Price) times 30 days."
                                  )}`}
                                </p>
                              </span>
                            </ReactTooltip>
                          </Label>
                          <div className="input-group mt-2">
                            <Input
                              type="number"
                              value={freeMonths}
                              onChange={(e) =>
                                setFreeMonths(Number(e.target.value))
                              }
                              disabled={discountType !== "freeMonths"}
                              style={{
                                backgroundColor:
                                  discountType !== "freeMonths"
                                    ? "#e9ecef"
                                    : "white",
                              }}
                            />
                            <Button
                              onClick={() =>
                                applyFreeMonthsAsDiscount(freeMonths)
                              }
                              disabled={discountType !== "freeMonths"}
                            >
                              {i18n.t("Apply Free Months")}
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={discountType === "addFreeMonths"}
                              onChange={() =>
                                handleCheckboxChange("addFreeMonths")
                              }
                              disabled={
                                discountType !== null &&
                                discountType !== "addFreeMonths"
                              }
                              style={{
                                cursor:
                                  discountType !== null &&
                                  discountType !== "addFreeMonths"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            />
                            <span
                              style={{
                                color:
                                  discountType !== null &&
                                  discountType !== "addFreeMonths"
                                    ? "gray"
                                    : "black",
                              }}
                            >
                              {i18n.t("Add X Month Free")}
                            </span>
                          </Label>
                          <div className="input-group mt-2">
                            <Input
                              type="number"
                              value={addFreeMonthsCount}
                              onChange={(e) =>
                                setAddFreeMonthsCount(Number(e.target.value))
                              }
                              disabled={discountType !== "addFreeMonths"}
                              style={{
                                backgroundColor:
                                  discountType !== "addFreeMonths"
                                    ? "#e9ecef"
                                    : "white",
                              }}
                            />
                            <Button
                              color={
                                isAddingMonthChecked ? "primary" : "secondary"
                              }
                              onClick={() => {
                                toggleFreeMonths(addFreeMonthsCount);
                                setIsAddingMonthChecked(!isAddingMonthChecked);
                              }}
                              disabled={discountType !== "addFreeMonths"}
                            >
                              {isAddingMonthChecked
                                ? i18n.t("Remove Free Months")
                                : i18n.t("Add Free Months")}
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Col xs="12">
                    <FormGroup>
                      <Label>{i18n.t("Final Price")}</Label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Input
                          disabled
                          className="bg-light text-gray bg-opacity-10 font-weight-bold font-size-15"
                          type="number"
                          maxLength={8}
                          name="finalPrice"
                          value={finalPrice?.toNumber()}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {props.rentMode === RentMode.NewContract && (
                <FormGroup className="mb-4">
                  <Label>{`${i18n.t("Contract")}${
                    i18n.language === "en" ? " " : ""
                  }${i18n.t("Remarks")}`}</Label>

                  <Input
                    type="textarea"
                    maxLength={500}
                    name="remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          {contract && (
            <BxButton
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
            >
              {i18n.t(`${props.totalSteps > 1 ? "Next Step" : "Submit"}`)}
              {contract && ` ${contract?.id}`}
            </BxButton>
          )}
          {props.rentMode === RentMode.NewContract && (
            <BxButton
              disabled={
                !!contract || (!!contractConflict && !contract) || !customer
              }
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
            >
              {`${i18n.t(`Create Contract`)} &  ${i18n.t(
                `${props.totalSteps > 1 ? "Next Step" : "Submit"}`
              )}`}
            </BxButton>
          )}
          {props.totalSteps === 1 && (
            <BxButton
              type="submit"
              color="secondary"
              className="mt-3 ml-2"
              form={"CollapseForm" + props.step}
              onClick={props.onCancel}
            >
              {i18n.t("Close")}
            </BxButton>
          )}
        </CardBody>
      </Card>
    </AvForm>
  );
};

export default ContractPanel;
