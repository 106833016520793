import React, { createContext, useContext, useState, useEffect } from "react";

import CustomerSearchModal from "../components/Modal/Customers/CustomerSearch";
import { Branch } from "../models/branch";
import { Customer } from "../models/customer";

export enum CUSTOMER_DIALOG_TYPES {
  SEARCH_MODAL = "SEARCH_CUSTOMER_MODAL",
}

const DIALOG_COMPONENTS = {
  [CUSTOMER_DIALOG_TYPES.SEARCH_MODAL]: CustomerSearchModal,
};
export interface CustomerModalComponent {
  modalType?: CUSTOMER_DIALOG_TYPES;
  modalProps?: CustomeModalProps;
  modalShow: boolean;
}
export interface CustomeModalProps {
  modal?: boolean;
  branch: Branch;
  onClose: () => void;
  onSearched: (customer: Customer) => void;
}
interface CustomeContextProps {
  showDialog: (
    modalType: CUSTOMER_DIALOG_TYPES,
    modalProps: CustomeModalProps
  ) => void;
  hideDialog: () => void;
  store: CustomerModalComponent;
}

const initalState: CustomeContextProps = {
  showDialog: (
    modalType: CUSTOMER_DIALOG_TYPES,
    modalProps: CustomeModalProps
  ) => {},
  hideDialog: () => {},
  store: {
    modalType: undefined,
    modalProps: undefined,
    modalShow: false,
  },
};

export const CustomerContext = createContext(
  initalState as CustomeContextProps
);
export const useCustomerContext = () => useContext(CustomerContext);

const CustomerProvider = ({ children }: { children: React.ReactNode }) => {
  //api helper
  const [store, setStore] = useState<CustomerModalComponent>();

  const showDialog = (
    modalType: CUSTOMER_DIALOG_TYPES,
    modalProps: CustomeModalProps
  ) => {
    setStore({
      modalType,
      modalProps,
      modalShow: true,
    });
  };

  const hideDialog = () => {
    setStore({
      modalType: undefined,
      modalProps: undefined,
      modalShow: false,
    });
  };

  const renderComponent = (p: CustomerModalComponent) => {
    if (!p.modalType) {
      return null;
    }

    const CustomerModalComponent =
      DIALOG_COMPONENTS[p.modalType as CUSTOMER_DIALOG_TYPES];

    if (!CustomerModalComponent) {
      return null;
    }

    return (
      <CustomerModalComponent
        id="customer-modal"
        {...(p?.modalProps as CustomeModalProps)}
        modal={p.modalShow ?? false}
      />
    );
  };

  return (
    <CustomerContext.Provider
      value={
        {
          store,
          showDialog,
          hideDialog,
        } as CustomeContextProps
      }
    >
      {store && renderComponent(store)}
      {children}
    </CustomerContext.Provider>
  );
};
export default CustomerProvider;
