import Decimal from "decimal.js";

export const second = 1;
export const minute = new Decimal(60).times(second).toNumber();
export const hour = new Decimal(60).times(minute).toNumber();
export const day = new Decimal(24).times(hour).toNumber();
export const week = new Decimal(7).times(day).toNumber();
export const duration = {
  second: second,
  minute: minute,
  hour: hour,
  day: day,
  week: week,
};
