import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";

class BoxappSingleProductBackend extends BoxappBackend {
  getSingleProducts = async (params) => {
    try {
      let request = {
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: `/single-products`,
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        params: params,
      };

      const response = await axios(request).catch((e) => { throw e });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  getSingleProduct = async (id) => {
    try {
      const response = await axios({
        method: "get",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/single-product/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  createSingleProduct = async (storage) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/single-products`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: storage,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };

  updateSingleProduct = async (id, storage) => {
    try {
      const response = await axios({
        method: "put",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/single-product/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: storage,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
  bulkCreateStorage = async (singleProducts) => {
    try {
      const response = await axios({
        method: "post",
        baseURL: this.baseURL + "/" + process.env.REACT_APP_TENANT_ID,
        url: `/single-products`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        "Content-Type": "application/json",
        validateStatus: function (status_1) {
          return status_1;
        },
        data: singleProducts,
      });
      if (!(response.status >= 200 && response.status < 300)) {
        throw response;
      }
      return response.data.data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappSingleProductBackend = null;

/**
 * Returns the firebase backend
 */
const getBoxappSingleProductBackend = () => {
  if (!_BoxappSingleProductBackend) {
    _BoxappSingleProductBackend = new BoxappSingleProductBackend();
  }
  return _BoxappSingleProductBackend;
};

export { getBoxappSingleProductBackend };
