import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import "./storage.scss";
import { withTranslation } from 'react-i18next';
import PaymentInfo from "../../../pages/Orders/components/paymentInfo";
import DepositPaymentInfo from "../../../pages/Deposit/components/paymentInfo";

import { getBoxappCustomerBackend } from "src/helpers/boxapp_customer_helper";

import { Payment } from "src/models/payment";
import { Order } from "src/models/order";
import { DepositOrder } from "src/models/depositOrder";
import { Wallet } from "src/models/wallet";
import { Invoice } from "src/models/invoice";

interface StoragePaymentInfoProps {
  order?: Order;
  depositOrder?: DepositOrder
  invoice?: Invoice
  title?: string;
  mode: "depositPaymentInfo" | "paymentInfo" | "createPayment" | "paymentConfirm";
  onPaidSuccess: (payment: Payment) => void;
  onClose: () => void;

  onSuccess: (mode: string, payload: any) => void;
}

const StoragePaymentPanel = (props: StoragePaymentInfoProps) => {
  const [wallet, setWallet] = useState<Wallet>();
  const fetchCustomer = useCallback(async () => {
    if (props.order?.customer?.id) {
      const res = await getBoxappCustomerBackend().getCustomer(
        props.order?.customer?.id
      );
      setWallet(res?.customer?.wallet);
    }
  }, [props.order?.customer?.id]);

  useEffect(() => {
    if (!wallet) {
      fetchCustomer();
    }
  }, [wallet]);

  return (
    <Card  >
      <CardTitle>
        <span className={`font-weight-bold h5 `}>
          {props.title}
        </span>
      </CardTitle>
      <CardBody>
        {props.mode === "depositPaymentInfo" ? (
          <DepositPaymentInfo
            mode={"create"}
            wallet={wallet}
            customerId={props.depositOrder?.customer?.id}
            invoice={props.invoice}
            onPaidSuccess={props.onPaidSuccess}
            onClose={props.onClose}
          />
        ) : (
          <PaymentInfo
            mode={"create"}
            wallet={wallet}
            customerId={props.order?.customer?.id}
            invoice={props.invoice}
            onPaidSuccess={props.onPaidSuccess}
            onClose={props.onClose}
          />
        )}
      </CardBody>
    </Card>
  )

};


export default (withTranslation()(StoragePaymentPanel));
