import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Row,
  Label,
  Alert,
} from "reactstrap";
import moment from "moment-timezone";
import { withTranslation, useTranslation } from "react-i18next";

import "./storageRent.scss";

import { useStorageContext } from "../../../context/StorageProviderContext";

import { useBranchContext } from "../../../context/BranchProviderContext";

import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { CreateOrderRequest } from "../../../helpers/boxapp_order_helper";

import { Storage } from "src/models/storage";
import { Contract } from "src/models/contract";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { Customer } from "src/models/customer";
import { RentMode, RentPanelSteps } from "./StorageRent";
import StorageRentContractPanel from "./StorageRentContractPanel";
import StorageRentOrderPanel from "./StorageRentOrderPanel";
import SectionDividerWithLeftText from "src/pages/Storages/components/SectionDividerWithLeftText";

export interface StorageRentNewContractProps {
  contract: Contract;
  mode: string;
  onSuccess: (action: string, storage: Storage) => void;
  onClose: () => void;
  modal: boolean;
  customer: Customer;
}

const StorageRentNewContract = (props: StorageRentNewContractProps) => {
  const { i18n } = useTranslation();
  const [process, setProcess] = useState<number[]>([]);
  const { contextStorage } = useStorageContext();
  const [customer, setCustomer] = useState<Customer>(props.customer);
  const [contract, setContract] = useState<Contract>();
  const { contextBranch } = useBranchContext();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { showModal } = useGlobalModalContext();
  const [steps, setSteps] = useState<string[]>([
    RentPanelSteps.Contract,
    RentPanelSteps.Order,
  ]);

  const isFinished = useCallback(
    (i: number) => process?.indexOf(i) > -1,
    [process]
  );
  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);

  const togglemodal = () => {
    props.onClose();
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="xl"
        fullscreen={true}
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t(RentMode.NewContract)}
          </ModalHeader>

          <ModalBody>
            <Row>
              <Col md="9" className="left-section">
                <Row>
                  <Col>
                    <FormGroup>
                      <div className="mt-1">
                        <Row>
                          {steps.map((e, i) => {
                            return (
                              <Col
                                key={i}
                                md={4}
                                className="btn"
                                onClick={() => {
                                  setCurrentStep(i);
                                }}
                              >
                                <div
                                  className={`avatar-sm rounded-circle  align-self-center mini-stat-icon float-left `}
                                >
                                  <span
                                    className={`avatar-title rounded-circle  ${
                                      currentStep === steps.length - 1 &&
                                      formValid
                                        ? "bg-success"
                                        : currentStep === i
                                        ? "bg-primary"
                                        : isFinished(i)
                                        ? "bg-success"
                                        : "bg-light "
                                    } `}
                                  >
                                    {i + 1}
                                  </span>
                                </div>
                                <div className="float-left h-100 ml-4 align-items-center ">
                                  <h5 className="pt-3 h-100 justify-content-center align-items-center  font-size-14 mb-1 text-dark">
                                    {i18n.t(e)}
                                  </h5>
                                </div>
                                {steps?.length - 1 !== i && (
                                  <i
                                    className={`bx bx-right-arrow-alt  pt-3 font-size-22  ${
                                      currentStep === i
                                        ? "text-primary"
                                        : isFinished(i)
                                        ? "text-success"
                                        : "text-light"
                                    }`}
                                  ></i>
                                )}
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <StorageRentContractPanel
                  rentMode={RentMode.NewContract}
                  totalSteps={steps.length}
                  // panel={step}
                  step={1}
                  onSubmit={async (e: any) => {
                    setCustomer(e?.customer);
                    setContract(e as Contract);
                    setCurrentStep(0 + 1);
                    setProcess([...process, 0]);
                  }}
                  contract={contract}
                  customer={customer!}
                  branch={contextBranch!}
                  storages={[contextStorage!]}
                  mode={props.mode}
                  isFinished={isFinished(0)}
                  onCancel={() => {
                    setProcess(process.filter((p) => p !== 0));
                  }}
                  open={currentStep === 0}
                  startDate={moment().unix()}
                  onContractConflict={(e: Contract | undefined) => {}}
                  // orderDate={moment().unix()}
                  // paidItems={[]}
                ></StorageRentContractPanel>
                <StorageRentOrderPanel
                  rentMode={RentMode.NewContract}
                  totalSteps={steps.length}
                  // panel={step}
                  step={2}
                  onSubmit={async (e: CreateOrderRequest) => {
                    if (contextBranch?.id && contextStorage?.id) {
                      try {
                        const res = await getBoxappStorageBackend().rentStorage(
                          contextBranch?.id,
                          contextStorage?.id,
                          e
                        );
                        if (res) {
                          showModal(MODAL_TYPES.ALERT_MODAL, {
                            title: i18n.t("rent rental location Success"),
                            messageType: "success",
                            message: i18n.t("rent rental location Success"),
                          });
                          props.onSuccess("rent", contextStorage!);
                        }
                      } catch (e) {
                        const errorMsg =
                          (e as any)?.data?.detail ??
                          "" + (e as any)?.data?.message ??
                          "" + e?.toString();
                        showModal(MODAL_TYPES.ALERT_MODAL, {
                          title: i18n.t("rent rental location failed"),
                          messageType: "error",
                          message: errorMsg,
                        });
                      }
                    }
                  }}
                  contract={contract}
                  customer={customer!}
                  branch={contextBranch!}
                  // storages={[]}
                  // mode={props.mode}
                  isFinished={isFinished(1)}
                  onCancel={() => {
                    setProcess(process.filter((p) => p !== 1));
                  }}
                  open={currentStep === 1}
                  // startDate={moment().unix()}
                  orderDate={moment().unix()}
                  paidItems={[]}
                ></StorageRentOrderPanel>
              </Col>
              <Col md="3" className="right-section pl-3">
                <SectionDividerWithLeftText
                  label={`${i18n.t("Remarks")}`}
                ></SectionDividerWithLeftText>

                {props?.contract && (
                  <>
                    <FormGroup className="">
                      <Row>
                        <Col xs="12">
                          <Label className="font-size-13 font-weight-bold">
                            {i18n.t("Customer Remarks")}
                          </Label>

                          <Label className="font-size-13 font-weight-bold">
                            {`(${i18n.t("Admin View Only")})`}
                          </Label>

                          <Alert
                            color={customer?.remarks ? "success" : "secondary"}
                            className="alert-dismissible fade show font-size-13"
                            role="warning"
                          >
                            <p>
                              {customer?.remarks ? customer?.remarks : "N/A"}
                            </p>
                          </Alert>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup className=" pt-2">
                      <Row>
                        <Col xs="12">
                          <Label className="font-size-13 font-weight-bold">
                            {i18n.t("Last Contract Remarks")}
                          </Label>

                          <br />
                          <Label className="font-size-13">
                            {`${i18n.t("Customer")}: ${
                              props.customer?.other_name
                            }`}
                          </Label>
                          <br />
                          <Label className="font-size-13">
                            {`${i18n.t("Contract Period")}: ${moment
                              .unix(props?.contract?.start_date as number)
                              .format("YYYY-MM-DD")} - ${moment
                              .unix(props?.contract?.end_date as number)
                              .format("YYYY-MM-DD")}`}
                          </Label>

                          <Alert
                            color={
                              props.contract?.remarks ? "success" : "secondary"
                            }
                            className="alert-dismissible fade show font-size-13 mt-2"
                            role="warning"
                          >
                            <p>
                              {props.contract?.remarks
                                ? props.contract?.remarks
                                : "N/A"}
                            </p>
                          </Alert>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                  </>
                )}

                <FormGroup className=" pt-2">
                  <Row>
                    <Col xs="12">
                      <Label className="font-size-13 font-weight-bold">
                        {`${i18n.t(contextStorage?.property_type)} - ${
                          contextStorage?.display_id
                        }`}
                      </Label>
                      <br />
                      <Label className="font-size-13 font-weight-bold">
                        {i18n.t("Property Remarks")}
                      </Label>

                      <Alert
                        color={
                          contextStorage?.remarks ? "success" : "secondary"
                        }
                        className="alert-dismissible fade show font-size-13 mt-2"
                        role="warning"
                      >
                        <p>
                          {contextStorage?.remarks ? (
                            contextStorage.remarks
                              .split("\n")
                              .map((line, index, array) => (
                                <React.Fragment key={index}>
                                  {line}
                                  {index < array.length - 1 && <br />}
                                </React.Fragment>
                              ))
                          ) : (
                            <p>N/A</p>
                          )}
                        </p>
                      </Alert>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentNewContract);
