import { Contract } from "./contract";
import { Customer } from "./customer";
import { Order } from "./order";

export interface RentRecord {
  customer: Customer;
  customer_id: string;
  storage_id: string;
  storage: Storage;
  order_id: string;
  order: Order;
  contract_id: string;
  contract: Contract;
  order_status: "PENDING" | "VOID" | "CANCELLED" | "PARTIAL_COMPLETED" | "UNALLOCATED" | "COMPLETED" | "SETTLED";
  status: "PENDING" | "TERMINATED" | "CANCELLED" | "RENT";
  termination_date: number;
  move_out_date: number;
  rent_type: string
  rent_period: number;
  price: number;
  rent_start_date: number;
  rent_end_date: number;
  order_no: string;
  order_date: number;
  current_view: boolean;
}

export const orderStatusBadge = {
  "COMPLETED": "badge-success",
  "SETTLED": "badge-success",
  "PENDING": "badge-warning",
  "CANCELLED": "badge-secondary",
  "VOID": "badge-secondary",
  "PARTIAL_COMPLETED": "badge-danger",
  "UNALLOCATED": "badge-danger",
};

export const rentalStatusBadge = {
  "RENT": "badge-success",
  "PENDING": "badge-warning",
  "CANCELLED": "badge-secondary",
  "TERMINATED": "badge-danger",
};