import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
export type PaymentItemAllocation = {
  paid_item_id: string;
  amount: number;
};
class BoxappPaymentBackend extends BoxappBackend {
  getPayments = async (filter: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/payments`, filter);
    } catch (err) {
      throw err;
    }
  };
  getPayment = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/payments/${id}`);
    } catch (err) {
      throw err;
    }
  };

  allocatePayment = async (
    paymentId: string,
    param: {
      payment_item_allocations: PaymentItemAllocation[];
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/payments/${paymentId}/_allocate`, param);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappPaymentBackend: BoxappPaymentBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappPaymentBackend = () => {
  if (!_BoxappPaymentBackend) {
    _BoxappPaymentBackend = new BoxappPaymentBackend();
  }
  return _BoxappPaymentBackend;
};

export { getBoxappPaymentBackend };
