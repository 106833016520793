export enum OrderType {
  Rent = "Rent",
  Deposit = "Deposit",
}

export const orderTypeBadge = {
  Rent: "badge-success",
  Deposit: "badge-primary",

};
