import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { MultiLangText } from "src/models/multiLangText";

class BoxappBranchBackend extends BoxappBackend {
  createBranch = async (branch: {

    code: string;
    name: MultiLangText;
    short_name: MultiLangText;
    address: MultiLangText;
    email: string;
    phone: string;
    allow_yearly: boolean;
    allow_monthly: boolean;
    allow_weekly: boolean;
    allow_daily: boolean;
    is_print_chop?: boolean;
    deposit_prefix?: string;
    order_prefix?: string;
    invoice_prefix?: string;
    payment_prefix?: string;
    receipt_prefix?: string;
    transfer_prefix?: string;
    list_display?: string;
    br?: string;
    cr?: string;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches`, branch);

    } catch (err) {
      throw err;
    }
  };
  disableBranch = async (id: string, disable: any): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/branches/${id}/disable`, { disable: disable });
    } catch (err) {
      throw err;
    }
  };

  sortBranch = async (id: string, sort: any): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${id}/sort`, { sort: sort });
    } catch (err) {
      throw err;
    }
  };
  updateBranch = async (id: string, branch: {
    code?: string;
    name?: MultiLangText;
    short_name?: MultiLangText;
    address?: MultiLangText;
    email?: string;
    phone?: string;
    allow_yearly?: boolean;
    allow_monthly?: boolean;
    allow_weekly?: boolean;
    allow_daily?: boolean;
    is_print_chop?: boolean;
    deposit_prefix?: string;
    order_prefix?: string;
    invoice_prefix?: string;
    payment_prefix?: string;
    receipt_prefix?: string;
    transfer_prefix?: string;
    list_display?: string;
    br?: string;
    cr?: string;
  }): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/branches/${id}`, branch);
    } catch (err) {
      throw err;
    }
  };
  updateBranchTandC = async (id: string, payload: any): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/branches/${id}/tandc`, payload);

    } catch (err) {
      throw err;
    }
  };
  getBranches = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/branches`);

    } catch (err) {
      throw err;
    }
  };
  getBranch = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/branches/${id}`);

    } catch (err) {
      throw err;
    }
  };
  uploadBranchLogo = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      return await this.upload(`${this.baseURL}/branches/${id}/logo`, formData);

    } catch (err) {
      throw err;
    }
  };
  uploadBranchChopStamp = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      return await this.upload(`${this.baseURL}/branches/${id}/chop-stamp`, formData);
    } catch (err) {
      throw err;
    }
  };

}

let _BoxappBranchBackend: BoxappBranchBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappBranchBackend = () => {
  if (!_BoxappBranchBackend) {
    _BoxappBranchBackend = new BoxappBranchBackend();
  }
  return _BoxappBranchBackend;
};

export { getBoxappBranchBackend };
