import React, { FC } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import "./columnFilter.scss";
import { UUID } from "crypto";

export interface Column {
  viewSortOnly?: boolean;
  field: string;
  label: string;
  sortable?: boolean;
  sort?: "asc" | "desc";
  sortField?: string;
  searchable: boolean;
  enable?: boolean;
  width?: number;
}
export interface DataRow {
  id?: UUID | string;
  nest?: { [x: string]: any }[] 
  [x: string]: any
}
export interface ColumnFilterProps {
  title: string;
  columns: Column[];
  onFilter: (field: string, value: string) => void;
}
const ColumnFilter: FC<ColumnFilterProps> = ({ title, columns, onFilter }) => {
  return (
    <Card>
      <CardBody>
        <Row className="mb-4">
          <Col>
            <CardTitle>{title} </CardTitle>
          </Col>
        </Row>
        <Row>
          {columns?.map((col, i) => {
            return (
              <Col xs={3} key={i}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input font-size-14 largerCheckbox"
                    type="checkbox"
                    checked={col.enable ?? false}
                    onChange={(e) => {
                      onFilter(col.field, e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label font-size-14"
                    htmlFor="defaultCheck1"
                  >
                    {col.label}
                  </label>
                </div>
              </Col>
            );
          })}
        </Row>
      </CardBody>
    </Card>
  );
};

export default ColumnFilter;
