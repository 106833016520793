import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";
import i18next from "i18next";
import { ModalProps } from "./ModalProps";

const RemovalActionModal = (props: ModalProps) => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, onConfirm, onCancel } = modalProps || {};
  const [delMessage, setDelMessage] = useState("");
  const handleModalToggle = () => {
    hideModal();
    if (onCancel) {
      onCancel();
    }
  };

  const handleModalActionConfirm = (e: any) => {
    if (delMessage === "UNDERSTAND") {
      hideModal();
      if (onConfirm) {
        onConfirm(e);
      }
    }
  };

  return (
    <SweetAlert
      title={i18next.t(title ?? "")}
      warning
      showCancel
      show={true}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      confirmBtnText={i18next.t("OK")}
      cancelBtnText={i18next.t("CANCEL")}
      onConfirm={handleModalActionConfirm}
      onCancel={() => handleModalToggle()}
    >
      {i18next.t("Please Type")} [<b>UNDERSTAND</b>]
      <input
        type="text"
        className="form-control mb-2"
        onChange={(e) => {
          setDelMessage(e.target.value);
        }}
      />
    </SweetAlert>
  );
};

export default RemovalActionModal;
