export enum API_ACTION {
  API_CREATE_ORDERS = "API_CREATE_ORDERS",
  API_CREATE_DEPOSIT = "API_CREATE_DEPOSIT",

  API_GET_ORDERS = "API_GET_ORDERS",
  API_GET_ORDER = "API_GET_ORDER",
  API_GET_DEPOSIT_ORDER = "API_GET_DEPOSIT_ORDER",
  API_GET_ORDER_EVENTS = "API_GET_ORDER_EVENTS",
  API_VOID_ORDER = "API_VOID_ORDER",
  API_UPDATE_ORDER = "API_UPDATE_ORDER",
  API_CREATE_PAYMENT = "API_CREATE_PAYMENT",
  API_UPDATE_PAYMENT = "API_UPDATE_PAYMENT",
  API_VOID_PAYMENT = "API_VOID_PAYMENT",

  //  API_GET_PAID_ITEMS = "API_GET_PAID_ITEMS",
  //  API_CREATE_ORDER_ITEMS = "API_CREATE_ORDER_ITEMS",

  API_DOWNLOAD_ORDER_ATTACHMENT = "API_DOWNLOAD_ORDER_ATTACHMENT",
  API_UPLOAD_ORDER_ATTACHMENT = "API_UPLOAD_ORDER_ATTACHMENT",
  API_GET_ORDER_ATTACHMENTS = "API_GET_ORDER_ATTACHMENTS",
  API_DOWNLOAD_RECEIPTES = "API_DOWNLOAD_RECEIPTES",

  API_GET_ORDER_FOLLOWS = "API_GET_ORDER_FOLLOWS",
  API_GET_ORDERS_FOLLOWS = "API_GET_ORDERS_FOLLOWS",
  API_CREATE_ORDER_FOLLOW = "API_CREATE_ORDER_FOLLOW",

  API_GET_CONTRACTS = "API_GET_CONTRACTS",
  API_GET_CONTRACT = "API_GET_CONTRACT",
  API_CREATE_CONTRACT = "API_CREATE_CONTRACT",
  API_UPDATE_CONTRACT = "API_UPDATE_CONTRACT",
  API_EXTEND_CONTRACT = "API_EXTEND_CONTRACT",
  API_END_CONTRACT = "API_END_CONTRACT",

  API_GET_INVOICE = "API_GET_INVOICE",
  API_CREATE_INVOICE = "API_CREATE_INVOICE",
  API_UPDATE_INVOICE = "API_UPDATE_INVOICE",
  API_VOID_INVOICE = "API_VOID_INVOICE",
  API_CANCEL_INVOICE = "API_CANCEL_INVOICE",

  API_GET_PAYMENTS = "API_GET_PAYMENTS",
  API_GET_PAYMENT = "API_GET_PAYMENT",

  API_CHANGE_PASSWORD = "API_CHANGE_PASSWORD",
  API_LOGIN = "API_LOGIN",
  API_MFA_LOGIN = "API_MFA_LOGIN",
  API_MFA_SEND = "API_MFA_SEND",
  API_GET_MFA_QRCODE = "API_GET_MFA_QRCODE",

  API_LOGOUT = "API_LOGOUT",

  API_GET_AUTH_USER = "API_GET_AUTH_USER",
  API_GET_AUTH_PERMISSIONS = "API_GET_AUTH_PERMISSIONS",
  API_GET_ROLES = "API_GET_ROLES",
  API_GET_PERMISSIONS = "API_GET_PERMISSIONS",
  API_EDIT_ROLE = "API_EDIT_ROLE",
  API_CREATE_ROLE = "API_CREATE_ROLE",
  API_UPDATE_ACCOUNT = "API_UPDATE_ACCOUNT",
  API_CREATE_ACCOUNT = "API_CREATE_ACCOUNT",
  API_GET_ACCOUNTS = "API_GET_ACCOUNTS",

  API_GET_COMPANY_INFO = "API_GET_COMPANY_INFO",
  API_UPDATE_COMPANY_TANDC = "API_UPDATE_COMPANY_TANDC",
  API_UPDATE_COMPANY_INFO = "API_UPDATE_COMPANY_INFO",
  API_UPLOAD_COMPANY_LOGO = "API_UPLOAD_COMPANY_LOGO",

  API_GET_BRANCH = "API_GET_BRANCH",
  API_GET_BRANCHES = "API_GET_BRANCHES",
  API_CREATE_BRANCH = "API_CREATE_BRANCH",
  API_UPDATE_BRANCH = "API_UPDATE_BRANCH",
  API_SORT_BRANCH = "API_SORT_BRANCH",
  API_DISABLE_BRANCH = "API_DISABLE_BRANCH",
  API_UPDATE_BRANCH_TANDC = "API_UPDATE_BRANCH_TANDC",
  API_UPLOAD_BRANCH_LOGO = "API_UPLOAD_BRANCH_LOGO",

  API_GET_CATEGORIES = "API_GET_CATEGORIES",
  API_CREATE_CATEGORY = "API_CREATE_CATEGORY",
  API_UPDATE_CATEGORY = "API_UPDATE_CATEGORY",
  API_GET_FEATURES = "API_GET_FEATURES",
  API_CREATE_FEATURE = "API_CREATE_FEATURE",
  API_UPDATE_FEATURE = "API_UPDATE_FEATURE",

  API_GET_CUSTOMER = "API_GET_CUSTOMER",
  API_GET_CUSTOMER_RENT_RECORDS = "API_GET_CUSTOMER_RENT_RECORDS",
  API_GET_CUSTOMERS = "API_GET_CUSTOMERS",

  API_GET_CUSTOMER_ATTACHMENTS = "API_GET_CUSTOMER_ATTACHMENTS",
  API_UPLOAD_CUSTOMER_ATTACHMENT = "API_UPLOAD_CUSTOMER_ATTACHMENT",
  API_CREATE_CUSTOMER = "API_CREATE_CUSTOMER",
  API_UPDATE_CUSTOMER = "API_UPDATE_CUSTOMER",
  API_CREATE_WALLET = "API_CREATE_WALLET",
  API_WITHDRAW_WALLET = "API_WITHDRAW_WALLET",
  API_TOP_UP_WALLET = "API_TOP_UP_WALLET",
  API_GET_WALLETS = "API_GET_WALLETS",
  API_GET_TRANSACTIONS = "API_GET_TRANSACTIONS",

  API_GET_LEAD = "API_GET_LEAD",
  API_GET_LEADS = "API_GET_LEADS",
  API_CREATE_LEAD = "API_CREATE_LEAD",
  API_UPDATE_LEAD = "API_UPDATE_LEAD",
  API_CREATE_LEAD_CALL = "API_CREATE_LEAD_CALL",

  API_GET_STORAGES = "API_GET_STORAGES",
  API_GET_STORAGE = "API_GET_STORAGE",
  API_GET_STORAGE_RENT_RECORDS = "API_GET_STORAGE_RENT_RECORDS",
  API_GET_STORAGE_EVENTS = "API_GET_STORAGE_EVENTS",
  API_GET_STORAGE_ORDERS = "API_GET_STORAGE_ORDERS",
  API_CREATE_STORAGE = "API_CREATE_STORAGE",
  API_CREATE_STORAGES = "API_CREATE_STORAGES",
  API_UPDATE_STORAGE = "API_UPDATE_STORAGE",
  API_UPDATE_STORAGES = "API_UPDATE_STORAGES",
  API_RENT_STORAGE = "API_RENT_STORAGE",
  API_RENT_CANCEL = "API_RENT_CANCEL",
  API_RENT_CONFIRM = "API_RENT_CONFIRM",
  API_RENT_EXTEND = "API_RENT_EXTEND",
  API_TERMINATE_STORAGE = "API_TERMINATE_STORAGE",
  API_MOVE_STORAGE = "API_MOVE_STORAGE",
  API_MAINTENANCE_STORAGE = "API_MAINTENANCE_STORAGE",
  API_RESUME_STORAGE = "API_RESUME_STORAGE",
  API_RELEASE_STORAGE = "API_RELEASE_STORAGE",
  API_DONATE_STORAGE_ITEM = "API_DONATE_STORAGE_ITEM",

  API_GET_SINGLE_PRODUCTS = "API_GET_SINGLE_PRODUCTS",
  API_CREATE_SINGLE_PRODUCT = "API_CREATE_SINGLE_PRODUCT",
  API_UPDATE_SINGLE_PRODUCT = "API_UPDATE_SINGLE_PRODUCT",
  API_GET_SINGLE_PRODUCT = "API_GET_SINGLE_PRODUCT",

  API_GET_CONSUMABLE_PRODUCTS = "API_GET_CONSUMABLE_PRODUCTS",
  API_CREATE_CONSUMABLE_PRODUCT = "API_CREATE_CONSUMABLE_PRODUCT",
  API_UPDATE_CONSUMABLE_PRODUCT = "API_UPDATE_CONSUMABLE_PRODUCT",
  API_GET_CONSUMABLE_PRODUCT = "API_GET_CONSUMABLE_PRODUCT",

  API_REPORT_STORAGES = "API_REPORT_STORAGES",
  API_REPORT_ORDERS = "API_REPORT_ORDERS",
  API_REPORT_MONTHLY = "API_REPORT_MONTHLY",
  API_REPORT_CONTRACTS = "API_REPORT_CONTRACTS",

  API_GET_NOTIFICATIONS = "API_GET_NOTIFICATIONS",
  API_UPDATE_NOTIFICATIONS = "API_UPDATE_NOTIFICATIONS",

  API_GET_CUSTOM_FIELDS = "API_GET_CUSTOM_FIELDS",
  API_CREATE_CUSTOM_FIELD = "API_CREATE_CUSTOM_FIELD",
  API_UPDATE_CUSTOM_FIELD = "API_UPDATE_CUSTOM_FIELD",

  API_GET_CHARGE_TYPES = "API_GET_CHARGE_TYPES",
  API_CREATE_CHARGE_TYPE = "API_CREATE_CHARGE_TYPE",
  API_UPDATE_CHARGE_TYPE = "API_UPDATE_CHARGE_TYPE",

  API_GET_CHARGE_TYPE_CATEGORIES = "API_GET_CHARGE_TYPE_CATEGORIES",
  API_CREATE_CHARGE_TYPE_CATEGORY = "API_CREATE_CHARGE_TYPE_CATEGORY",

  API_GET_EXPEND_TYPES = "API_GET_EXPEND_TYPES",
  API_CREATE_EXPEND_TYPE = "API_CREATE_EXPEND_TYPE",
  API_UPDATE_EXPEND_TYPE = "API_UPDATE_EXPEND_TYPE",
  API_GET_EXPEND_TYPE_CATEGORIES = "API_GET_EXPEND_TYPE_CATEGORIES",
  API_CREATE_EXPEND_TYPE_CATEGORY = "API_CREATE_EXPEND_TYPE_CATEGORY",

  API_CREATE_TEMPLATE = "API_CREATE_TEMPLATE",
  API_UPDATE_TEMPLATE = "API_UPDATE_TEMPLATE",
  API_GET_TEMPLATES = "API_GET_TEMPLATES",
  API_GET_TEMPLATE = "API_GET_TEMPLATE",
  API_GEN_PDF = "API_GEN_PDF",

  API_GET_DASHBOARD_MONTHLY_REVENUE = "API_GET_DASHBOARD_MONTHLY_REVENUE",
  API_GET_DASHBOARD_LATEST_ORDER_STATUS = "API_GET_DASHBOARD_LATEST_ORDER_STATUS",
  API_GET_DASHBOARD_LATEST_MONTHLY_ORDER = "API_GET_DASHBOARD_LATEST_MONTHLY_ORDER",
  API_GET_DASHBOARD_LATEST_STORAGE_STATUS = "API_GET_DASHBOARD_LATEST_STORAGE_STATUS",

  API_GET_SETTINGS = "API_GET_SETTINGS",
  API_SET_SETTINGS = "API_SET_SETTINGS",
}
