import React, { useEffect, useState, useMemo, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import "./invoiceInfo.scss";
import moment from "moment-timezone";
import {
  Alert,
  Row,
  Col,
  Input,
  Label,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap";

// Redux
import SubmissionModal from "../../../components/Modal/SubmissionModal";
import { BxButton } from "../../../components/Button/index";
import { useTranslation } from "react-i18next";
import { MultiLangText } from "src/models/multiLangText";
import { Payment } from "src/models/payment";
import Decimal from "decimal.js";
import {
  PaymentItemAllocation,
  getBoxappPaymentBackend,
} from "src/helpers/boxapp_payment_helper";
import { useGlobalModalContext } from "src/context/GlobalModalContext";
import { MODAL_TYPES } from "src/components/Modal/ModalProps";

export interface PaymentAllocationProps {
  id?: string;
  payment: Payment;
  modal: boolean;
  onSuccess: (payment: Payment) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const PaymentAllocation = (props: PaymentAllocationProps) => {
  const { i18n } = useTranslation();
  const { showModal, loading, setLoading } = useGlobalModalContext();
  const [errorMessage, setErrorMessage] = useState<string>();
  //payment_item_allocations
  const [paymentItemAllocations, setPaymentItemAllocations] = useState<
    PaymentItemAllocation[]
  >([]);
  const [payment, setPayment] = useState<Payment>();

  useEffect(() => {
    if (props.payment) {
      setPayment(props.payment);
      setPaymentItemAllocations(
        props.payment?.payment_items?.map((x) => {
          return {
            paid_item_id: x.paid_item_id,
            amount: x.amount,
          };
        }) ?? []
      );
    }
    //   if (props.payment?.id) {
    //     getBoxappPaymentBackend()
    //       .getPayment(props.payment?.id)
    //       .then((res) => {
    //         const { payment } = res;
    //         setPayment(payment);
    //         setPaymentItemAllocations(
    //           payment?.payment_items?.map((x) => {
    //             return {
    //               paid_item_id: x.paid_item_id,
    //               amount: x.amount,
    //             };
    //           }) ?? []
    //         );
    //       });
    //   }
  }, [props.payment]);

  const onSubmit = async () => {
    setErrorMessage(undefined);
    console.log(paymentItemAllocations);
    if (
      paymentItemAllocations
        ?.reduce((a, b) => {
          return new Decimal(a ?? 0).plus(b.amount ?? 0);
        }, new Decimal(0))
        .greaterThan(payment?.amount ?? 0)
    ) {
      setErrorMessage(i18n.t("Allocate Amount max is") + props.payment?.amount);
      return;
    }
    // props.onSuccess(props.payment!);
    try {
      if (props.payment?.id) {
        const allocateItem = paymentItemAllocations?.filter(
          (x) => x.amount > 0
        );
        if (allocateItem?.length === 0) {
          setErrorMessage(
            i18n.t("Allocate Amount Not Equal to Payment Amount")
          );
          return;
        }
        const { payment } = await getBoxappPaymentBackend().allocatePayment(
          props.payment?.id ?? "",
          {
            payment_item_allocations: allocateItem,
          }
        );
        if (payment) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: i18n.t("Allocate Payment Success"),
            messageType: "warning",
            message: i18n.t(
              "If paid via wallet, have to allocate in payment page in the order."
            ),
          });
          if (props.onSuccess) {
            props.onSuccess(payment);
          }
        }
      }
    } catch (e) {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t((e as any)?.data?.messa),
        messageType: "error",
        message: (e as any)?.data?.detail ?? e,
      });
    }
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <SubmissionModal
      id={props.id}
      size={"lg"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Payment Allocation")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          <Row>
            <Col>
              <Alert
                color="warning"
                className="offcanvas alert-dismissible fade show mt-5 md-5 p-3 text-center font-size-13"
                role="alert"
              >
                <i className="fas fa-exclamation-trianglemr-2"></i>
                <p>
                  <b>
                    {i18n.t(
                      "If customer paid via wallet, remember to allocate that in the Payment section of Order Page."
                    )}
                  </b>
                </p>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <FormGroup className="border-top border-5 pt-4 md-4 w-100">
                <Label>{i18n.t("Payment Amount")}</Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Input
                    className={"bg-light text-gray bg-opacity-10"}
                    disabled={true}
                    value={props.payment?.amount}
                    name="paymentAmount"
                    type="number"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="border-top border-5 pt-4 md-4">
            <Col xs="3">
              <Label
                className="font-12 d-flex justify-content-center"
                htmlFor=""
              >
                {i18n.t("Paid Items")}
              </Label>
            </Col>
            <Col xs="3">
              <Label
                className="font-12 d-flex justify-content-center"
                htmlFor=""
              >
                {i18n.t("Pending Price")} $
              </Label>
            </Col>
            <Col xs="1"></Col>
            <Col xs="3">
              <Label
                className="font-12 d-flex justify-content-center"
                htmlFor=""
              >
                {i18n.t("Allocate Price")} $
              </Label>
            </Col>
            <Col xs="2">
              <Label
                className="font-12 d-flex justify-content-center"
                htmlFor=""
              >
                {i18n.t("Status")}
              </Label>
            </Col>
          </Row>
          {payment?.payment_items?.length === 0 && (
            <Row className="ml-2 mb-1">
              <Col xs="12">
                <Label>{i18n.t("No Paid Items")}</Label>
              </Col>
            </Row>
          )}

          {payment?.payment_items?.map((paymentItem, i) => {
            const pendingAmount = new Decimal(
              paymentItem?.paid_item?.final_price ?? 0
            )
              .minus(paymentItem?.paid_item?.paid_amount ?? 0)
              .toNumber();

            return (
              <Row className="ml-2 mb-1 align-items-center" key={i}>
                <Col xs="3" className="d-flex justify-content-center">
                  <Label>
                    {
                      paymentItem?.paid_item?.item_name?.[
                        i18n.language as keyof MultiLangText
                      ]
                    }
                  </Label>
                </Col>

                <Col xs="3" className="d-flex justify-content-center">
                  <ReactTooltip
                    id={`tipsPendingAmount-${paymentItem?.paid_item?.id}`}
                    effect="solid"
                  >
                    <span>
                      <p>
                        {`${i18n.t("Final Price")}: 
                        ${new Decimal(
                          paymentItem?.paid_item?.final_price ?? 0
                        ).toNumber()}`}
                      </p>
                      <p>
                        {`${i18n.t("Paid Amount")}: 
                        ${new Decimal(
                          paymentItem?.paid_item?.paid_amount ?? 0
                        ).toNumber()}`}
                      </p>
                    </span>
                  </ReactTooltip>
                  <div className="input-group">
                    <a
                      data-tip
                      data-for={`tipsPendingAmount-${paymentItem?.paid_item?.id}`}
                      className="mr-1 d-flex align-items-center"
                    >
                      {i18n.t("ⓘ")}
                    </a>
                    <Input
                      className={"bg-light text-gray bg-opacity-10"}
                      disabled={true}
                      type="number"
                      maxLength={8}
                      name={"pendingToPayAmount"}
                      value={new Decimal(
                        paymentItem?.paid_item?.final_price ?? 0
                      )
                        .minus(paymentItem?.paid_item?.paid_amount ?? 0)
                        .toNumber()}
                    />
                  </div>
                </Col>

                <Col xs="1" className="d-flex justify-content-center">
                  <BxButton
                    onClick={() => {
                      const pendingToPay = new Decimal(
                        paymentItem?.paid_item?.final_price ?? 0
                      )
                        .minus(paymentItem?.paid_item?.paid_amount ?? 0)
                        .toNumber();

                      const amountToAllocate = Math.min(
                        pendingToPay,
                        props.payment?.amount || 0
                      );

                      if (paymentItem?.paid_item?.id) {
                        // Update paymentItemAllocations state
                        const index = paymentItemAllocations.findIndex(
                          (x) => x.paid_item_id === paymentItem?.paid_item?.id
                        );
                        if (index > -1) {
                          paymentItemAllocations[index].amount =
                            amountToAllocate;
                        } else {
                          paymentItemAllocations.push({
                            paid_item_id: paymentItem?.paid_item?.id,
                            amount: amountToAllocate,
                          });
                        }
                        setPaymentItemAllocations([...paymentItemAllocations]);

                        // Update payment state
                        if (payment && payment.payment_items) {
                          payment.payment_items[i].amount = amountToAllocate;
                          setPayment({
                            ...payment,
                            payment_items: [...payment.payment_items],
                          });
                        }
                      }
                    }}
                  >
                    ⇒
                  </BxButton>
                </Col>
                <Col xs="3" className="d-flex justify-content-center">
                  {paymentItem?.paid_item?.id && (
                    <Input
                      name={`paidItemPrice${i}`}
                      type="number"
                      value={paymentItem?.amount ?? 0}
                      disabled={
                        new Decimal(paymentItem?.paid_item?.final_price ?? 0)
                          .minus(paymentItem?.paid_item?.paid_amount ?? 0)
                          .toNumber() === 0
                      }
                      onChange={(e: any) => {
                        const allocatedAmount = Number(e.target.value);
                        if (paymentItem?.paid_item?.id) {
                          const index =
                            paymentItemAllocations?.findIndex(
                              (x) =>
                                x.paid_item_id === paymentItem?.paid_item?.id
                            ) ?? -1;
                          if (index > -1) {
                            paymentItemAllocations[index].amount =
                              allocatedAmount;
                          } else {
                            paymentItemAllocations.push({
                              paid_item_id: paymentItem?.paid_item?.id,
                              amount: allocatedAmount,
                            });
                          }
                          if (payment.payment_items) {
                            payment.payment_items[i].amount = allocatedAmount;
                            setPayment((prev) => {
                              if (prev) {
                                return {
                                  ...prev,
                                  payment_items: payment.payment_items,
                                };
                              } else {
                                return prev;
                              }
                            });
                          }
                          setPaymentItemAllocations(paymentItemAllocations);
                        }
                      }}
                    />
                  )}
                </Col>
                <Col xs="2" className="d-flex justify-content-center">
                  {i18n.t(paymentItem?.paid_item?.status ?? "")}
                </Col>
              </Row>
            );
          })}
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default PaymentAllocation;
