import React, { useEffect, useState } from "react";
import { FormGroup, Label, InputGroup, Col, Row, Button } from "reactstrap";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { triggerAsyncId } from "async_hooks";

export interface PaidItemPeriodProps {
  startDate?: number;
  endDate?: number;
  dateRequired?: boolean;
  disabled?: boolean;
  name?: string;
  onChange?: (data: { start_date?: number; end_date?: number }) => void;
}

const PaidItemPeriod = (props: PaidItemPeriodProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [activeAdjustment, setActiveAdjustment] = useState<number | null>(null);
  const [dayAdjustment, setDayAdjustment] = useState<number>(0);
  const [isEndDateManuallySet, setIsEndDateManuallySet] =
    useState<boolean>(false);
  const { i18n } = useTranslation();

  // 初始化 startDate 和 endDate 从 props
  useEffect(() => {
    if (props?.startDate) {
      setStartDate(moment.unix(props.startDate).toDate());
    }
    if (props.endDate) {
      setEndDate(moment.unix(props.endDate).toDate());
    }
  }, [props.startDate, props.endDate]);

  // 计算 endDate 基于 startDate, activeAdjustment, 和 dayAdjustment
  useEffect(() => {
    if (!isEndDateManuallySet) {
      if (startDate) {
        let baseDate = moment(startDate);
        if (activeAdjustment) {
          baseDate = baseDate
            .add(activeAdjustment, "months")
            .subtract(1, "days");
        }
        baseDate = baseDate.add(dayAdjustment, "days");
        setEndDate(baseDate.toDate());
      } else {
        setEndDate(null);
      }
    }
  }, [startDate, activeAdjustment, dayAdjustment, isEndDateManuallySet]);

  // 当 endDate 改变时，通知父组件
  useEffect(() => {
    if (props.onChange) {
      props.onChange({
        start_date: startDate ? moment(startDate).unix() : undefined,
        end_date: endDate ? moment(endDate).unix() : undefined,
      });
    }
  }, [startDate, endDate, props]);

  // 切换月度调整（互斥）
  const toggleAdjustment = (months: number) => {
    setActiveAdjustment((prev) => (prev === months ? null : months));
    setIsEndDateManuallySet(false);
  };

  // 调整天数
  const handleDayAdjustment = (delta: number) => {
    setDayAdjustment((prev) => prev + delta);
    setIsEndDateManuallySet(false);
  };

  const setStartDateToday = () => {
    setStartDate(moment().toDate());
    setActiveAdjustment(null);
    setDayAdjustment(0);
    setIsEndDateManuallySet(true);
  };

  // 设置 endDate 为今天，并重置所有调整
  const setEndDateToday = () => {
    setEndDate(moment().toDate());
    setActiveAdjustment(null);
    setDayAdjustment(0);
    setIsEndDateManuallySet(true);
  };

  // 清除开始日期
  const cleanStartDate = () => {
    setStartDate(null);
    setActiveAdjustment(null);
    setDayAdjustment(0);
    setIsEndDateManuallySet(false);
  };

  // 清除开始和结束日期
  const cleanStartEndDate = () => {
    setStartDate(null);
    setEndDate(null);
    setActiveAdjustment(null);
    setDayAdjustment(0);
    setIsEndDateManuallySet(false);
  };

  return (
    <Row className="mb-3">
      {/* Start Date */}
      <Col xs="12" sm="6">
        <FormGroup>
          <Label>{i18n.t("Start Date")}</Label>
          <InputGroup>
            <DatePicker
              placeholderText={!props.dateRequired ? i18n.t("Optional") : ""}
              dateFormat="yyyy-MM-dd"
              isClearable
              selected={startDate}
              name={`${props.name}-startDate`}
              className="form-control"
              onChange={(date: Date) => {
                setStartDate(date);
                setIsEndDateManuallySet(false);
              }}
              showYearDropdown
              disabled={props.disabled}
            />
          </InputGroup>
        </FormGroup>
      </Col>
      {/* End Date */}
      <Col xs="12" sm="6">
        <FormGroup>
          <Label>{i18n.t("End Date")}</Label>
          <InputGroup>
            <DatePicker
              placeholderText={!props.dateRequired ? i18n.t("Optional") : ""}
              dateFormat="yyyy-MM-dd"
              name={`${props.name}-endDate`}
              selected={endDate}
              isClearable
              className="form-control"
              onChange={(date: Date) => {
                setEndDate(date);
                setIsEndDateManuallySet(true);
              }}
              showYearDropdown
              disabled={props.disabled}
            />
          </InputGroup>
          {props.dateRequired && !endDate && (
            <span className="text-danger mt-1 d-block">
              {i18n.t("End date must be entered")}
            </span>
          )}
        </FormGroup>
        {/* Adjust Months Buttons */}
        <div className="d-flex mt-2">
          <FormGroup>
            <Label>{i18n.t("Quick Add Months")}</Label>
            <InputGroup className="flex-wrap">
              {/* 定义要添加的月份调整 */}
              {[1, 2, 3, 4, 5, 6, 12].map((months) => (
                <Button
                  key={months}
                  color={
                    activeAdjustment === months ? "primary" : "outline-primary"
                  }
                  size="sm"
                  className="mr-2 mb-2 p-2"
                  onClick={() => toggleAdjustment(months)}
                  disabled={!startDate || props.disabled}
                >
                  {`+${months}`}
                </Button>
              ))}
            </InputGroup>
            {/* 显示已加的月份 */}
            {activeAdjustment && (
              <span className="ml-2">
                {`${i18n.t("Added")} ${activeAdjustment} ${i18n.t("months")}`}
              </span>
            )}
          </FormGroup>
        </div>
      </Col>
      <Col xs="12" sm="6"></Col>
      {/* Adjust Days Buttons */}
      <Col xs="12" sm="6" className="mt-3">
        <FormGroup>
          <Label>{i18n.t("Quick Add Days")}</Label>
          <InputGroup>
            <Button
              color="primary"
              size="sm"
              className="mr-2 mb-2 p-2"
              onClick={() => handleDayAdjustment(-1)}
              disabled={!startDate || props.disabled}
            >
              {i18n.language === "en"
                ? `${i18n.t("Day")} -1`
                : `-1 ${i18n.t("Day")}`}
            </Button>
            <Button
              color="primary"
              size="sm"
              className="mr-2 mb-2 p-2"
              onClick={() => handleDayAdjustment(1)}
              disabled={!startDate || props.disabled}
            >
              {i18n.language === "en"
                ? `${i18n.t("Day")} +1`
                : `+1 ${i18n.t("Day")}`}
            </Button>
          </InputGroup>
          {/* 显示已加的天数 */}
          {dayAdjustment !== 0 && (
            <span className="ml-2">
              {`${i18n.t("Added")} ${dayAdjustment} ${i18n.t("days")}`}
            </span>
          )}
        </FormGroup>
      </Col>
      {/* Clean Buttons for Start Date */}
      <Col xs="12" sm="6" className="mt-3">
        <FormGroup>
          <Label>{i18n.t("Adjust Start Date")}</Label>
          <InputGroup>
            <Button
              color="warning"
              size="m"
              onClick={setStartDateToday}
              disabled={props.disabled}
              className="mr-2 mb-2"
            >
              {i18n.t("Today")}
            </Button>
            <Button
              color="danger"
              size="m"
              className="mr-2 mb-2"
              onClick={cleanStartDate}
              disabled={props.disabled}
            >
              {i18n.t("Clean Start Date")}
            </Button>
          </InputGroup>
        </FormGroup>
      </Col>
      {/* Clean Buttons for End Date */}
      <Col xs="12" sm="6" className="mt-3">
        <FormGroup>
          <Label>{i18n.t("Adjust End Date")}</Label>
          <InputGroup>
            <Button
              color="warning"
              size="m"
              onClick={setEndDateToday}
              disabled={props.disabled}
              className="mr-2 mb-2"
            >
              {i18n.t("Today")}
            </Button>
            <Button
              color="danger"
              size="m"
              className="mr-2 mb-2"
              onClick={cleanStartEndDate}
              disabled={props.disabled}
            >
              {i18n.t("Clean Start and End Date")}
            </Button>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PaidItemPeriod;
