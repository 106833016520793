import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Role } from "../models/role";

interface createRoleParams {
  name?: string;
  permissions?: string;
}
interface updateRoleParams extends createRoleParams { }

class BoxappRoleBackend extends BoxappBackend {
  getPermissions = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/permissions`);
    } catch (err) {
      throw err;
    }
  };
  getRoles = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/roles`, query);
    } catch (err) {
      throw err;
    }
  };
  getRole = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/roles/${id}`);
    } catch (err) {
      throw err;
    }
  };


  updateRole = async (id: string, role: updateRoleParams): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/roles/${id}`, role);
    } catch (err) {
      throw err;
    }
  };

  createRole = async (role: createRoleParams): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/roles`, role);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappRoleBackend: BoxappRoleBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappRoleBackend = () => {
  if (!_BoxappRoleBackend) {
    _BoxappRoleBackend = new BoxappRoleBackend();
  }
  return _BoxappRoleBackend;
};

export { getBoxappRoleBackend };
