import React, { useState, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import i18next from "i18next";
import { BxButton } from "../Button";
import { ModalProps } from "./ModalProps";

const SubmissionModal = (props: ModalProps) => {
  const togglemodal = () => {
    if (props.onClose) props.onClose();
  };
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  // const ref = useRef();
  return (
    <Modal
      fullscreen={props.fullScreen ?? false}
      size={props.size ?? "lg"}
      isOpen={props.show ?? false}
      role="dialog"
      backdrop="static"
      autoFocus={true}
      centered={true}
      className={props.className}
      tabIndex={-1}
      toggle={togglemodal}
    >
      <div className="modal-content">
        <AvForm
          id={props.id}
          action="#"
          // ref={ref}
          onValidSubmit={() => {
            setSubmitButtonBlock(true);
            if (props.onSubmit) props.onSubmit();
            setTimeout(() => {
              setSubmitButtonBlock(false);
            }, 2000);
          }}
        >
          <ModalHeader tag="h4">
            <span>{i18next.t(props.title ?? "")}</span>
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>{props.children}</CardBody>
            </Card>
          </ModalBody>
          <ModalFooter hidden={props.hideFooter}>
            <BxButton
              disabled={submitButtonBlock ?? false}
              type="submit"
              color="success"
              form={props.id}
            >
              {i18next.t("Submit")}
            </BxButton>
            <Button type="button" color="secondary" onClick={togglemodal}>
              {i18next.t("Close")}
            </Button>
          </ModalFooter>
        </AvForm>
      </div>
    </Modal>
  );
};

export default SubmissionModal;
