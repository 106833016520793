import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";

class BoxappRentRecordBackend extends BoxappBackend {
  getRentRecords = async (params: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/rent-records`, params);
    } catch (err) {
      throw err;
    }
  };

  createStorage = async (params: {
    order_id: string;
    storage_id: string;
    customer_id: string;
    contract_id: string;
    status: string;
    termination_date: number;
    move_out_date: number;
    current_view?: boolean;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/rent-records`, params);
    } catch (err) {
      throw err;
    }
  };

  update = async (params: {
    customer_id: string;
    order_id: string;
    storage_id: string;
    termination_date?: number;
    move_out_date?: number;
    current_view?: boolean;
  }): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/rent-records`, params);
    } catch (err) {
      throw err;
    }
  };

  setCurrentView = async (param: {
    order_id: string;
    storage_id: string;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/rent-records/current-view`, param);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappRentRecordBackend: BoxappRentRecordBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappRentRecordBackend = () => {
  if (!_BoxappRentRecordBackend) {
    _BoxappRentRecordBackend = new BoxappRentRecordBackend();
  }
  return _BoxappRentRecordBackend;
};

export { getBoxappRentRecordBackend };
