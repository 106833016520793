import React, { useState, useEffect, useMemo } from "react";

import {
  Alert,
  FormGroup,
  Container,
  Row,
  Col,
  Input,
  Button,
  Table,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import Select from "react-select";
import {
  optionBranches,
  optionStatus,
  titleOptionGroup,
  optionCustomerTypeGroup,
  optionNoticeMethods,
} from "../../util/options";
import AsyncSelect from "react-select/async";
import { useTranslation, withTranslation } from "react-i18next";
import { getBoxappCustomerBackend } from "../../helpers/boxapp_customer_helper";
import { CustomerType } from "src/util/customerType";
import { Customer } from "src/models/customer";
import { MODAL_TYPES } from "src/components/Modal/ModalProps";
import { useGlobalModalContext } from "src/context/GlobalModalContext";
import { MultiLangText } from "src/models/multiLangText";
import SectionDividerWithLeftText from "../Storages/components/SectionDividerWithLeftText";

import "./CustomerCreate.css"; // 確保引入自定義的CSS文件

export interface CustomerCreateProps {
  customer?: any;
  modal: boolean;
  mode: "create" | "edit" | "view";
  onSuccess: (customer: Customer) => void;
  onClose: () => void;
}

const CustomerCreate = (props: CustomerCreateProps) => {
  const { i18n } = useTranslation();
  const [branchesGroup, setBranchesGroup] = useState<{
    label: string;
    value: string;
  }>();
  const [code, setCode] = useState<string>();
  const [customerTypeGroup, setCustomerTypeGroup] = useState<{
    label: string;
    value: CustomerType;
  }>();
  const { showModal } = useGlobalModalContext();
  const [otherName, setOtherName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [address, setAddress] = useState<MultiLangText>();
  const [idNo, setIdNo] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [codeError, setCodeError] = useState(null);
  const [errorMsg, setErrorMsg] = useState<string>();
  const generateID = () => {
    const date = moment().format("YYYY-MM-DD");
    const randomDigits = Math.floor(Math.random() * 100000)
      .toString()
      .padStart(5, "0");
    return `${date}_${randomDigits}`;
  };
  const [noticeMethods, setNoticeMethods] = useState<string[]>();
  const handleAddCustomerClick = () => {
    const newID = generateID();
    openModal(newID);
  };

  const [remarks, setRemarks] = useState<string>();
  const [statusGroup, setStatusGroup] = useState<{
    label: string;
    value: "Active" | "Disable";
  }>({
    label: i18n.t("Active"),
    value: "Active",
  });
  const [titleGroup, setTitleGroup] = useState<{
    label: string;
    value: string;
  }>({
    label: i18n.t("N/A"),
    value: "N/A",
  });

  useEffect(() => {
    if (props.customer) {
      setFirstName(props.customer.first_name);
      setLastName(props.customer.last_name);
      setOtherName(props.customer.other_name);
      setIdNo(props.customer.id_no);
      setRemarks(props.customer.remarks);
      setPhone(props.customer.phone);
      setCode(props.customer.code);
      setEmail(props.customer.email);
      setAddress(props.customer.address);
      if (props.customer.customer_type) {
        setCustomerTypeGroup(
          optionCustomerTypeGroup[0]?.options?.find((option) => {
            return option.value === props.customer.customer_type;
          })
        );
      }
      if (props.customer.status) {
        const op = optionStatus[0].options?.find((option) => {
          return option.value === props.customer.status;
        });
        op && setStatusGroup(op);
      }
      if (props.customer.title) {
        const op = titleOptionGroup[0].options?.find((option) => {
          return option.value === props.customer.title;
        });
        op && setTitleGroup(op);
      }
    } else {
      setBranchesGroup(undefined);
      setFirstName("");
      setLastName("");
      setEmail(undefined);
      setIdNo("");
      setPhone("");
      setCode("");
      setAddress(undefined);
      setCustomerTypeGroup(optionCustomerTypeGroup[0].options[0]);
      setStatusGroup(optionStatus[0].options[0]);
      setTitleGroup(titleOptionGroup[0].options[0]);
    }
  }, [props.customer]);

  const togglemodal = () => {
    setFirstName("");
    setLastName("");
    setOtherName("");
    setPhone("");
    setRemarks("");
    setIdNo("");
    setCode("");
    setAddress(undefined);
    setStatusGroup({
      label: i18n.t("Active"),
      value: "Active",
    });
    setTitleGroup({
      label: i18n.t("N/A"),
      value: "N/A",
    });
    props.onClose();
  };
  const loadBranchOptions = async (inputValue: string) => {
    const options = await optionBranches();
    return options.filter((branch) => branch.value !== "ALL");
  };
  const onSubmit = async () => {
    const BoxappCustomerBackend = getBoxappCustomerBackend();
    const { customers } = await BoxappCustomerBackend.getCustomers({
      code: code,
      search_mode: "eq",
    });

    if (!branchesGroup || !branchesGroup?.value) {
      setErrorMsg("Please select at least one branch");
      return;
    }
    if (customers?.data?.length === 0) {
      setCodeError(null);
      const interval = 2000;
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
      }, interval);

      if (props.mode === "edit") {
        try {
          const res = await getBoxappCustomerBackend().updateCustomer(
            props.customer.id,
            {
              first_name: firstName,
              last_name: lastName,
              other_name: otherName,
              customer_type:
                customerTypeGroup?.value || CustomerType.CUSTOMER_INDIVIDUAL,
              phone: phone,
              id_no: idNo,
              status: statusGroup?.value || "Active",
              code: code,
              email: email,
              address: address,
              title: titleGroup?.value ?? "N/A",
              branch_id: branchesGroup?.value,
            }
          );
          if (res && res.customer) {
            showModal(MODAL_TYPES.ALERT_MODAL, {
              title: i18n.t("update customer Success"),
              messageType: "success",
              message: i18n.t("Update customer Success"),
            });
            props.onSuccess(res.customer);
          }
        } catch (e) {
          const errorMsg =
            (e as any)?.data?.detail ??
            "" + (e as any)?.data?.message ??
            "" + e?.toString();
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: i18n.t("update customer failed"),
            messageType: "error",
            message: errorMsg,
          });
        }
      } else if (props.mode === "create") {
        try {
          const res = await getBoxappCustomerBackend().createCustomer({
            first_name: firstName,
            last_name: lastName,
            other_name: otherName,
            customer_type:
              customerTypeGroup?.value || CustomerType.CUSTOMER_INDIVIDUAL,
            phone: phone,
            id_no: idNo!,
            status: statusGroup?.value || "Active",
            code: code!,
            email: email,
            address: address,
            title: titleGroup?.value ?? "N/A",
            branch_id: branchesGroup?.value,
            notice_methods: noticeMethods,
          });
          if (res && res.customer) {
            showModal(MODAL_TYPES.ALERT_MODAL, {
              title: i18n.t("Create customer Success"),
              messageType: "success",
              message: i18n.t("Create customer Success"),
            });
            props.onSuccess(res.customer);
          }
        } catch (e) {
          const errorMsg =
            (e as any)?.data?.detail ??
            "" + (e as any)?.data?.message ??
            "" + e?.toString();
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: i18n.t("create customer failed"),
            messageType: "error",
            message: errorMsg,
          });
        }
      }
    } else {
      setCodeError(i18n.t("custom code should be unique"));
    }
  };

  return (
    <Modal
      size="xl"
      isOpen={props.modal}
      role="dialog"
      backdrop="static"
      autoFocus={true}
      centered={true}
      className=""
      tabIndex={-1}
      toggle={togglemodal}
    >
      <div className="modal-content">
        <AvForm
          className="form-horizontal formGroup"
          onValidSubmit={() => onSubmit()}
        >
          {/* Modal Header with Save & Close Buttons */}
          <ModalHeader toggle={togglemodal}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {i18n.t("Customer")} {props.customer ? props.customer.id : ""}
              </div>
            </div>
          </ModalHeader>

          {/* Modal Body with Two Sections */}
          <ModalBody>
            <Alert
              color="success"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              <i className="far fa-smile mr-2"> </i>
              <p>
                1.{" "}
                {i18n.t("Customer can only be searched if assigned branched.")}
              </p>
              <p>2. {i18n.t("Customer Code CANNOT be duplicated.")}</p>
            </Alert>
            <Row>
              {/* 左側主要內容區域 */}
              <Col md="9" className="left-section">
                <SectionDividerWithLeftText
                  label={`1. ${i18n.t("Required Information")}`}
                ></SectionDividerWithLeftText>
                <Row>
                  <Col xs={6}>
                    <FormGroup className="select2-container">
                      <label className="control-label">
                        <>
                          {i18n.t("Branch")}
                          <span style={{ color: "red" }}>
                            ({i18n.t("Required")})*
                          </span>
                        </>
                      </label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={branchesGroup}
                        placeholder={i18n.t("Select...")}
                        onChange={async (option) => {
                          if (option) setBranchesGroup(option);
                        }}
                        loadOptions={async () => {
                          const b = await optionBranches();

                          return b.filter((branch) => {
                            return branch.value !== "ALL";
                          });
                        }}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>

                    <span className="failed">{errorMsg}</span>
                  </Col>
                  <Col md="3">
                    <AvField
                      name="code"
                      label={
                        <>
                          {i18n.t("Customer Code")}
                          <span style={{ color: "red" }}>
                            ({i18n.t("Required")})*
                          </span>
                        </>
                      }
                      value={code}
                      className="form-control"
                      onChange={(e: any) => {
                        setCode(e.target?.value);
                      }}
                      placeholder={`${i18n.t("Customer Code")}`}
                      readOnly={props.mode === "view"}
                      errorMessage={i18n.t("This field have to be enter")}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            codeError ?? i18n.t("This field have to be enter"),
                        },
                      }}
                    />
                  </Col>

                  <Col md="3">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded waves-effect waves-light"
                      onClick={() => {
                        const newID = generateID();
                        setCode(newID); // Assuming `setCode` updates the `code` value in state
                      }}
                      style={{ marginTop: "28px" }} // Adjust this value based on your form alignment needs
                    >
                      <i className="mdi mdi-plus mr-1"></i>{" "}
                      {i18n.t("Generate ID")}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>{i18n.t("Customer Type")}</Label>
                      <Select
                        value={customerTypeGroup}
                        // isDisabled={props.mode  !== "edit"}
                        onChange={(e: any) => setCustomerTypeGroup(e)}
                        options={optionCustomerTypeGroup}
                        classNamePrefix="select-selection"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <AvField
                      name="otherName"
                      label={
                        customerTypeGroup?.value === "CUSTOMER_CORPORATE" ? (
                          <>
                            {i18n.t("Company Name")}
                            <span style={{ color: "red" }}>
                              ({i18n.t("Required")})*
                            </span>
                          </>
                        ) : (
                          <>
                            {i18n.t("Other Name")}
                            <span style={{ color: "red" }}>
                              ({i18n.t("Required")})*
                            </span>
                          </>
                        )
                      }
                      value={otherName}
                      className="form-control"
                      onChange={(e: any) => setOtherName(e.target.value)}
                      validate={{ required: { value: true } }}
                      placeholder={
                        customerTypeGroup?.value === "CUSTOMER_CORPORATE"
                          ? `${i18n.t("Enter Company Name")}`
                          : `${i18n.t("Enter Other Name")}`
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <AvField
                      name="phone"
                      label={
                        <>
                          {`${i18n.t("Phone")}  (${i18n.t(
                            "Can WhatsApp Use"
                          )})`}
                          <span style={{ color: "red" }}>
                            ({i18n.t("Required")})*
                          </span>
                        </>
                      }
                      value={phone}
                      className="form-control"
                      onChange={(e: any) => e && setPhone(e.target?.value)}
                      placeholder={`${i18n.t("Phone")} (${i18n.t(
                        "Can WhatsApp Use"
                      )})`}
                      readOnly={props.mode === "view"}
                      type="number"
                      errorMessage={i18n.t("This field have to be enter")}
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: i18n.t("Only Numbers"),
                        },
                      }}
                    />
                  </Col>
                </Row>

                <SectionDividerWithLeftText
                  label={`2. ${i18n.t("Other Information")}`}
                ></SectionDividerWithLeftText>
                <Row>
                  <Col md="6">
                    <AvField
                      name="email"
                      label={`${i18n.t("Email")}  (${i18n.t("Can Email Use")})`}
                      value={email}
                      className="form-control"
                      onChange={(e: any) => e && setEmail(e.target?.value)}
                      placeholder={i18n.t("Enter Email")}
                      type="email"
                      // required
                      validate={
                        {
                          // required: { value: false },
                          // pattern: {
                          //   value: "^[0-9]+$",
                          //   errorMessage: i18n.t("Only Numbers"),
                          // },
                        }
                      }
                    />
                  </Col>
                  <Col md="6">
                    <AvField
                      name="idNo"
                      label={
                        customerTypeGroup?.value === "CUSTOMER_CORPORATE"
                          ? i18n.t("BR Number")
                          : i18n.t("ID No")
                      }
                      value={idNo}
                      className="form-control"
                      onChange={(e: any) => setIdNo(e.target.value)}
                      placeholder={
                        customerTypeGroup?.value === "CUSTOMER_CORPORATE"
                          ? i18n.t("Enter BR Number")
                          : i18n.t("Enter ID No")
                      }
                      readOnly={props.mode === "view"}
                      errorMessage={i18n.t("This field have to be enter")}
                    // validate={{ required: { value: true } }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <AvField
                        type="textarea"
                        name="address"
                        label={
                          customerTypeGroup?.value === "CUSTOMER_CORPORATE"
                            ? i18n.t("Company Address")
                            : i18n.t("Address")
                        }
                        value={address}
                        className="form-control"
                        onChange={(e: any) => setAddress(e.target.value)}
                        placeholder={
                          customerTypeGroup?.value === "CUSTOMER_CORPORATE"
                            ? i18n.t("Enter Company Address")
                            : i18n.t("Enter Address")
                        }
                        readOnly={props.mode === "view"}
                        errorMessage={i18n.t("This field have to be enter")}
                      // validate={{ required: { value: true } }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <SectionDividerWithLeftText
                  label={`3. ${i18n.t("Optional Information")}`}
                ></SectionDividerWithLeftText>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>{i18n.t("Title")}</Label>
                      <Select
                        value={titleGroup}
                        // disable={props.mode === "view"}
                        // onChange={(e) => setTitleGroup(e)}
                        onChange={(option) => {
                          if (option) setTitleGroup(option);
                        }}
                        options={titleOptionGroup}
                        classNamePrefix="select-selection"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <AvField
                      name="lastname"
                      label={i18n.t("Last Name")}
                      value={lastName}
                      className="form-control"
                      onChange={(e: any) => e && setLastName(e.target?.value)}
                      placeholder={i18n.t("Enter Last Name")}
                      readOnly={props.mode === "view"}
                      errorMessage={i18n.t("This field have to be enter")}
                    // validate={{ required: { value: true } }}
                    />
                  </Col>
                  <Col md="6">
                    <AvField
                      name="firstname"
                      label={i18n.t("First Name")}
                      value={firstName}
                      className="form-control"
                      onChange={(e: any) => e && setFirstName(e.target?.value)}
                      placeholder={i18n.t("Enter First Name")}
                      readOnly={props.mode === "view"}
                      errorMessage={i18n.t("This field have to be enter")}
                    // validate={{ required: { value: true } }}
                    />
                  </Col>
                </Row>
              </Col>

              {/* 右側管理員備註區域 */}
              <Col md="3" className="right-section">
                <SectionDividerWithLeftText
                  label={`${i18n.t("Important Information")}`}
                ></SectionDividerWithLeftText>
                <FormGroup className="mb-4">
                  <Label>{`${i18n.t("Remarks")} (${i18n.t(
                    "Only the Admin can see"
                  )})`}</Label>
                  <Input
                    type="textarea"
                    maxLength={500}
                    style={{ height: "150px" }}
                    name="remarks"
                    onChange={(e: any) => setRemarks(e.target.value)}
                    value={remarks}
                  />
                </FormGroup>
                {/* 如果還有其他需要管理員注意的內容，可以在這裡添加 */}

                {/* <FormGroup className="border-top border-1 pt-2">
                  <Label>{i18n.t("Notice Methods")}</Label>
                  {optionNoticeMethods?.[0]?.options?.map((option) => {
                    return (
                      <FormGroup check key={option?.value}>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={noticeMethods?.includes(option.value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setNoticeMethods([
                                  ...(noticeMethods || []),
                                  option.value,
                                ]);
                              } else {
                                setNoticeMethods(
                                  noticeMethods?.filter(
                                    (method) => method !== option.value
                                  )
                                );
                              }
                            }}
                          />
                          {option.label}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </FormGroup> */}
              </Col>
            </Row>
            {/* 其他表單內容和表格 */}
            <Row hidden={props.mode === "create"}>
              <Col md="6">
                <FormGroup>
                  <Label>{i18n.t("Status")}</Label>
                  <Select
                    value={statusGroup}
                    onChange={(option) => {
                      if (option) {
                        setStatusGroup(option);
                      }
                    }}
                    options={optionStatus}
                    classNamePrefix="select-selection"
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="table-responsive">
              <Table
                className="table table-centered table-nowrap"
                hidden={!props.customer}
              >
                <thead>
                  <tr>
                    <th scope="col">{i18n.t("Storage")}</th>
                    <th scope="col">{i18n.t("Storage Type")}</th>
                    <th scope="col">{i18n.t("Status")}</th>
                    <th scope="col">{i18n.t("Due Date")}</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </Table>
            </div>
          </ModalBody>

          {/* Modal Footer with Save & Close Buttons */}
          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              hidden={props.mode === "view"}
            >
              {i18n.t("Save")}
            </Button>
            <Button type="button" color="secondary" onClick={togglemodal}>
              {i18n.t("Close")}
            </Button>
          </ModalFooter>
        </AvForm>
      </div>
    </Modal>
  );
};
export default CustomerCreate;

function openModal(newID: string) {
  throw new Error("Function not implemented.");
}
