import Decimal from "decimal.js";
import { Storage } from "src/models/storage";

export const calPrice = (
  item: {
    rent_type?: string;
    rent_period?: number;
  },
  storage: Storage
): number => {
  switch (item?.rent_type) {
    case "MONTH": {
      return new Decimal(item?.rent_period ?? 1)
        .times(storage?.monthly_price ?? 0)
        .toNumber();
    }
    case "YEAR": {
      return new Decimal(item?.rent_period ?? 1)
        .times(storage?.yearly_price ?? 0)
        .toNumber();
    }
    case "WEEK": {
      return new Decimal(item?.rent_period ?? 1)
        .times(storage?.weekly_price ?? 0)
        .toNumber();
    }
    case "DAY": {
      return new Decimal(item?.rent_period ?? 1)
        .times(storage?.daily_price ?? 0)
        .toNumber();
    }
    default: {
      return 0;
    }
  }
};
