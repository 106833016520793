import axios from "axios";
import { Data, ResponseResult } from "src/api/reponseResult";

require("dotenv").config();
export class BoxappBackend {
  baseURL: string =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_SERVER ?? ""
      : "/api";

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
  get = async (url: string, query?: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        validateStatus: (status) => {
          return status ? true : false;
        },
        params: query,
      });
      if (status === 401) {
        localStorage.clear();
        window.location.href = "/error-401";

      } if (status === 403) {
        // localStorage.clear();
        // window.location.href = "/error-403";

      } if (status === 404) {
        window.location.href = "/error-404";

      } else if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err: any) {
      if (err && !err?.status) {
        console.log(err)
        window.location.href = `/error-network`
      }
      throw err;
    }
  }
  put = async (url: string, body?: any): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(url, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        validateStatus: (status) => {
          return status ? true : false;
        },
      });
      if (status === 401) {
        localStorage.clear();
        window.location.href = "/error-401";

      } if (status === 403) {
        // localStorage.clear();
        // window.location.href = "/error-403";

      } if (status === 404) {
        window.location.href = "/error-404";

      } else if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err: any) {
      if (err && !err?.status) {
        console.log(err)
        window.location.href = `/error-network`
      }
      throw err;
    }
  }
  post = async (url: string, body?: any): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(url, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        validateStatus: (status) => {
          return status ? true : false;
        },
      });
      if (status === 401) {
        localStorage.clear();
        window.location.href = "/error-401";

      } if (status === 403) {
        // localStorage.clear();
        // window.location.href = "/error-403";

      } if (status === 404) {
        window.location.href = "/error-404";

      } else if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err: any) {
      if (err && !err?.status) {
        console.log(err)
        window.location.href = `/error-network`
      }
      throw err;
    }
  }
  delete = async (url: string): Promise<Data> => {
    try {
      const { data, status } = await axios.delete<ResponseResult>(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        validateStatus: (status) => {
          return status ? true : false;
        },
      });
      if (status === 401) {
        localStorage.clear();
        window.location.href = "/error-401";

      } if (status === 403) {
        // localStorage.clear();
        // window.location.href = "/error-403";

      } if (status === 404) {
        window.location.href = "/error-404";

      } else if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err: any) {
      if (err && !err?.status) {
        console.log(err)
        window.location.href = `/error-network`
      }
      throw err;
    }
  }
  upload = async (url: string, body?: any): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(url, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        validateStatus: (status) => {
          return status ? true : false;
        },
      });
      if (status === 401) {
        localStorage.clear();
        window.location.href = "/error-401";

      } if (status === 403) {
        // localStorage.clear();
        // window.location.href = "/error-403";

      } if (status === 404) {
        window.location.href = "/error-404";

      } else if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err: any) {
      if (err && !err?.status) {
        console.log(err)
        window.location.href = `/error-network`
      }
      throw err;
    }
  }
}
let _BoxappBackend: BoxappBackend | null = null;
const getBoxappBackend = () => {
  if (!_BoxappBackend) {
    _BoxappBackend = new BoxappBackend();
  }
  return _BoxappBackend;
};

export { getBoxappBackend };
