import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Order } from "../models/order";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
import { MultiLangText } from "src/models/multiLangText";
export interface CreateOrderRequest extends Order {
  invoice_remarks: string;
}
export interface CreateDepositOrderRequest {
  original_price: number;
  discount_amount?: number;
  final_price: number;
  remarks: string;
  customer_id: string;
  storage_id?: string;
  branch_id?: string;
  contract_id?: string;
  paid_items: Array<{
    item_type?: "STORAGE" | "WALLET" | "PREPAID" | "MISCELLANEOUS" | string;
    item_name?: MultiLangText;
    item_id?: string;
    quantity_start?: number;
    quantity_end?: number;
    final_price?: number;
    order_type?: string;
    order_id?: string;
    start_date?: number;
    end_date?: number;
    original_price?: number;
    discount_amount?: number;
    item?: any;
    sort?: number
  }>
  invoice_remarks: string;

}
export interface GetOrdersRequest {
  storage_id?: string;
  customer_id?: string;
  contract_id?: string;
  branch_id?: string;
  order_type?: string;
  status?: string;
  created_by?: string;
  last_edited_date?: number;
  order_start_date?: number;
  order_end_date?: number;
  order_start_date_from?: number;
  order_start_date_to?: number;
  order_end_date_from?: number;
  order_end_date_to?: number;
  paid_amount_from?: number;
  paid_amount_to?: number;
  account_receivable_from?: number;
  account_receivable_to?: number;
  limit?: number;
  sort?: string;
  page?: number;

}
class BoxappDepositBackend extends BoxappBackend {
  //deposit
  create = async (
    order: CreateDepositOrderRequest
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/deposits`, order);
    } catch (err) {
      throw err;
    }
  };

  getDeposits = async (query: {
    storage_id?: string;
    customer_id?: string;
    contract_id?: string;
    branch_id?: string;
    order_type?: string;
    status?: string;
    created_by?: string;
    last_edited_date?: number;
    order_start_date?: number;
    order_end_date?: number;
    order_start_date_from?: number;
    order_start_date_to?: number;
    order_end_date_from?: number;
    order_end_date_to?: number;
    page?: number;
    limit?: number;
    sort?: string;


  }): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/deposits`, {
        ...query,
        order_type: "Deposit",
      });
    } catch (err) {
      throw err;
    }
  };
  getDeposit = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/deposits/${id}`);

    } catch (err) {
      throw err;
    }
  };

  getOrderEvents = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/deposits/${id}/events`, query);
    } catch (err) {
      throw err;
    }
  };
  updateOrder = async (
    id: string,
    param: {
      remarks?: string;
      status?: string;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/deposits/${id}`, param);
    } catch (err) {
      throw err;
    }
  };

  createPayment = async (
    id: string,
    payment: {
      invoice_type: InvoiceType;
      order_type: OrderType;
      ref_no?: string;
      payment_amount: number;
      remarks?: string;
      payment_method: string;
      payment_date: number;
      wallet_id?: string;
      wallet_payment_amount: number;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/invoices/${id}/payments`, payment);
    } catch (err) {
      throw err;
    }
  };
  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      return await this.upload(`${this.baseURL}/deposits/${id}/attachments`, formData);
    } catch (err) {
      throw err;
    }
  };
  getOrderAttachment = async (attachmentID: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/attachments/${attachmentID}`);
    } catch (err) {
      throw err;
    }
  };


  getReceipt = async (
    id: string,
    receiptType: string,
    lng: string
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/deposits/${id}/receipt`, {
        receipt_type: receiptType,
        language: lng,
      });
    } catch (err) {
      throw err;
    }
  };
  void = async (
    id: string,
    param: {
      remarks: string;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/deposits/${id}/void`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

let _BoxappDepositBackend: BoxappDepositBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappDepositBackend = () => {
  if (!_BoxappDepositBackend) {
    _BoxappDepositBackend = new BoxappDepositBackend();
  }
  return _BoxappDepositBackend;
};

export { getBoxappDepositBackend };
