export enum MODAL_TYPES {
  ALERT_MODAL = "ALERT_MODAL",
  MESSAGE_MODAL = "MESSAGE_MODAL",
  ACTION_MODAL = "ACTION_MODAL",
  REMOVAL_ACTION_MODAL = "REMOVAL_ACTION_MODAL",
}

export interface ModalComponent {
  modalType?: MODAL_TYPES;
  modalProps?: ModalProps;
  modalShow: boolean;
}

export interface ModalProps {
  title?: string;
  message?: string;
  messageType?: string;
  onConfirm?: (e?: any) => void;
  onCancel?: () => void;
  id?: string;
  show?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  hideFooter?: boolean;
  className?: string;
  children?: React.ReactNode;
  size?: string;
  fullScreen?: boolean;
}
