import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";
import { ModalProps } from "./ModalProps";
import i18next from "i18next";

const MessageModal = (props: ModalProps) => {
  const { hideModal, store } = useGlobalModalContext();
  const { title, message, messageType } = store?.modalProps || {};

  const handleModalToggle = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
    hideModal();
  };
  return (
    <SweetAlert
      title={i18next.t(title ?? "")}
      success={messageType === "success"}
      show={true}
      confirmBtnText={i18next.t("OK")}
      cancelBtnText={i18next.t("CANCEL")}
      onConfirm={handleModalToggle}
    >
      {message ? i18next.t(message) : ""}
    </SweetAlert>
  );
};

export default MessageModal;
