import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import "./storage.scss";
import { useTranslation, withTranslation } from "react-i18next";
import StoragePaymentPanel from "./StoragePaymentPanel";
import { Order } from "src/models/order";
import { DepositOrder } from "src/models/depositOrder";
import { Invoice } from "src/models/invoice";
import { Payment } from "src/models/payment";

interface StoragePaymentInfoProps {
  modal: boolean;
  order?: Order;
  depositOrder?: DepositOrder;
  newOrder?: Order;
  invoice?: Invoice
  mode: "depositPaymentInfo" | "paymentInfo" | "createPayment" | "paymentConfirm";
  onPaidSuccess: (payment: Payment) => void;
  onClose: () => void;
  onSuccess: (mode: string, payload: any) => void;

}


const StoragePaymentInfo = (props: StoragePaymentInfoProps) => {
  const { i18n } = useTranslation();
  const order = useMemo(
    () => (props.order?.status === "COMPLETED" ? props.newOrder : props.order),
    [props.order, props.newOrder]
  );

  const depositOrder = props.depositOrder
  const invoice = useMemo(() => {
    if (props.invoice) {
      return props.invoice
    }
    if (depositOrder) {
      return depositOrder?.invoices?.find(
        (inv) =>
          inv.status !== "COMPLETED"
      );
    }
    return order?.invoices?.find(
      (inv) =>
        inv.status !== "COMPLETED"
    );
  }, [order, depositOrder, props.invoice]);

  const togglemodal = () => {
    props.onClose();
  };
  return (
    <Modal
      isOpen={props.modal}
      role="dialog"
      backdrop="static"
      autoFocus={true}
      centered={true}
      tabIndex={-1}
      size="xl"
      fullscreen={true}
      toggle={togglemodal}
    >
      <div className="modal-content">
        <ModalHeader toggle={togglemodal} tag="h4">
          <span>{i18n.t("Payment Info")}</span>
        </ModalHeader>
        <ModalBody>
          <div>
            <StoragePaymentPanel
              mode={props.mode}
              title={depositOrder ? i18n.t("Deposit Order") : order ? i18n.t("Rental Order") : ""}
              order={order}
              depositOrder={depositOrder}
              invoice={invoice}
              onPaidSuccess={(p) => {
                if (props.onPaidSuccess)
                  props.onPaidSuccess(p);
              }}
              onSuccess={(p) => {
                if (props.onSuccess)
                  props.onSuccess(props.mode, p);
              }}
              onClose={() => togglemodal()}
            />
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default (withTranslation()(StoragePaymentInfo));
