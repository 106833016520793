import { PaymentItem } from "./paymentItem";
export interface Payment {
  id: string;
  invoice_id: string;
  invoice: any;
  payment_items?: PaymentItem[];
  payment_no: string;
  payment_method: string;
  payment_type: string;
  payment_date: number;
  ref_no: string;
  transaction_id: string;
  ref_filename: string;
  ref: string;
  billing_amount: number;
  amount: number;
  status: PaymentStatus;
  remarks: string;
  payment_gateway: string;
  created_at: number;
  updated_at: number;
  customer_id: string;
}
export enum PaymentStatus {
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  PAID = "PAID",
  VOID = "VOID",
  UNALLOCATED = "UNALLOCATED",
}


export const paymentStatusBadge = {
  "PAID": "badge-success",
  "PENDING": "badge-warning",
  "CANCELLED": "badge-secondary",
  "VOID": "badge-secondary",
  "UNALLOCATED": "badge-danger",
};

