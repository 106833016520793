import React, { createContext, useContext, useState } from "react";

import { store } from "../util/storage";
import { Branch } from "../models/branch"; 

interface BranchContextProps { 
  contextBranch?: Branch;
  setContextBranch: (branch: Branch) => void;
  contextBranches: Branch[];
  setContextBranches: (branches: Branch[]) => void;
}
const initalState: BranchContextProps = {
 
  contextBranch: undefined,
  setContextBranch: () => {},
  contextBranches: [],
  setContextBranches: () => {},
};

export const BranchContext = createContext(initalState as BranchContextProps);
export const useBranchContext = () => useContext(BranchContext);

const BranchProvider = ({ children }: { children: React.ReactNode }) => { 
  const [contextBranch, setContextBranch] = useState<Branch>();
  const [contextBranches, setContextBranches] = useState<Branch[]>(
    store.getWithExpiry("branches")?.data
  );

  return (
    <BranchContext.Provider
      value={{ 
        contextBranch,
        setContextBranch,
        contextBranches,
        setContextBranches,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};
export default BranchProvider;
