import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Contract } from "../models/contract";
import { ContractStatus } from "src/util/contractStatus";

class BoxappContractBackend extends BoxappBackend {

  getContracts = async (query: {
    customer_id?: string;
    branch_id?: string;
    storage_ids?: string[];
    status?: string;
    start_date?: number;
    end_date?: number;
    start_date_from?: number;
    start_date_to?: number;
    end_date_from?: number;
    end_date_to?: number;
    termination_date_from?: number;
    termination_date_to?: number;
    is_terminated?: boolean;
    deposit_amount_from?: number;
    deposit_amount_to?: number;
    is_move_out?: boolean;
    move_out_date_from?: number;
    move_out_date_to?: number;
    deposit_order_id?: string;
    page?: number;
    limit?: number;
  }): Promise<Data> => {
    try {
      const newQuery = {
        ...query,
        storage_ids: query?.storage_ids ? query?.storage_ids.join(",") : undefined,

      };
      return await this.get(`${this.baseURL}/contracts`, newQuery);
    } catch (err) {
      throw err;
    }
  };

  getContract = async (id: string, query?: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/contracts/${id}`, query);
    } catch (err) {
      throw err;
    }
  };

  create = async (contract: {
    branch_id: string;
    contract_storages: Array<{
      storage_id: string;
      original_price: number;
      discount_amount?: number;
      final_price: number;
    }>;
    customer_id: string;
    start_date: number;
    end_date: number;
    rent_type: "YEAR" | "MONTH" | "WEEK" | "DAY";
    rent_period: number;
    original_price: number;
    discount_amount?: number;
    final_price: number;
    remarks: string;
    move_in_date?: number;
    move_out_date?: number;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/contracts`, contract);
    } catch (err) {
      throw err;
    }
  };
  update = async (
    id: string,
    contract: {
      status?: ContractStatus;
      remarks?: string;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/contracts/${id}`, contract);
    } catch (err) {
      throw err;
    }
  };
  extend = async (contract: Contract): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/contracts/extend`, contract);
    } catch (err) {
      throw err;
    }
  };
  end = async (contract: Contract): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/contracts/end`, contract);
    } catch (err) {
      throw err;
    }
  };
  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      return await this.upload(`${this.baseURL}/contracts/${id}/attachments`, formData);

    } catch (err) {
      throw err;
    }
  };
  getAttachment = async (attachmentID: string): Promise<any> => {
    try {
      return await this.getAttachment(`${this.baseURL}/attachments/${attachmentID}`);
    } catch (err) {
      throw err;
    }
  };

  getAttachments = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/contracts/${id}/attachments`, query);
    } catch (err) {
      throw err;
    }
  };

  getLastOrder = async (
    contractId: string,
    query: any
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/contracts/${contractId}/orders/last`, query);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappContractBackend: BoxappContractBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappContractBackend = () => {
  if (!_BoxappContractBackend) {
    _BoxappContractBackend = new BoxappContractBackend();
  }
  return _BoxappContractBackend;
};

export { getBoxappContractBackend };
