import { BoxappBackend } from "./boxapp_helper";
import { Data } from "../api/reponseResult";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
import { MultiLangText } from "src/models/multiLangText";
export interface CreateOrderRequest {

  start_date: number;
  end_date: number;
  rent_type: "YEAR" | "MONTH" | "WEEK" | "DAY";
  rent_period: number;
  original_price: number;
  discount_amount: number;
  final_price: number;
  remarks?: string;
  paid_items: Array<{
    item_type?: "STORAGE" | "WALLET" | "PREPAID" | "MISCELLANEOUS" | string;
    item_name?: MultiLangText;
    item_id?: string;
    quantity_start?: number;
    quantity_end?: number;
    final_price?: number;
    order_type?: string;
    order_id?: string;
    start_date?: number;
    end_date?: number;
    original_price?: number;
    discount_amount?: number;
    item?: any;
    sort?: number
  }>;
  customer_id?: string;
  storage_id?: string;
  branch_id?: string;
  contract_id?: string;
  invoice_remarks?: string;
}
export interface CreateDepositOrderRequest {
  original_price: number;
  discount_amount?: number;
  final_price: number;
  remarks: string;
  customer_id: string;
  storage_id?: string;
  branch_id?: string;
  contract_id?: string;
  paid_items: Array<{
    item_type?: "STORAGE" | "WALLET" | "PREPAID" | "MISCELLANEOUS" | string;
    item_name?: MultiLangText;
    item_id?: string;
    quantity_start?: number;
    quantity_end?: number;
    final_price?: number;
    order_type?: string;
    order_id?: string;
    start_date?: number;
    end_date?: number;
    original_price?: number;
    discount_amount?: number;
    item?: any;
    sort?: number
  }>
  invoice_remarks: string;

}
export interface GetOrdersRequest {
  storage_id?: string;
  customer_id?: string;
  contract_id?: string;
  branch_id?: string;
  order_type?: string;
  status?: string;
  created_by?: string;
  last_edited_date?: number;
  order_start_date?: number;
  order_end_date?: number;
  order_start_date_from?: number;
  order_start_date_to?: number;
  order_end_date_from?: number;
  order_end_date_to?: number;
  paid_amount_from?: number;
  paid_amount_to?: number;
  account_receivable_from?: number;
  account_receivable_to?: number;
  limit?: number;
  sort?: string;
  page?: number;

}

export interface CreatePaymentRequest {
  invoice_type: InvoiceType;
  order_type: OrderType;
  ref_no?: string;
  payment_amount: number;
  remarks?: string;
  payment_method: string;
  payment_date: number;
  wallet_id?: string;
  wallet_payment_amount: number;
  bank_id?: string;
}
class BoxappOrderBackend extends BoxappBackend {
  //deposit
  createDepositOrder = async (
    order: CreateDepositOrderRequest
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/orders?orderType=Deposit`, order);
    } catch (err) {
      throw err;
    }
  };

  //order
  createOrder = async (order: CreateOrderRequest): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/orders`, order);
    } catch (err) {
      throw err;
    }
  };
  getDeposits = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders`, {
        ...query,
        order_type: "Deposit",
      });
    } catch (err) {
      throw err;
    }
  };
  getDeposit = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}?orderType=Deposit`);

    } catch (err) {
      throw err;
    }
  };
  getOrders = async (query: GetOrdersRequest): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders`, query);
    } catch (err) {
      throw err;
    }
  };
  getOrder = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}`);
    } catch (err) {
      throw err;
    }
  };

  getOrderEvents = async (id: string, query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}/events`, query);
    } catch (err) {
      throw err;
    }
  };
  updateOrder = async (
    id: string,
    param: {
      remarks?: string;
      status?: string;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/orders/${id}`, param);
    } catch (err) {
      throw err;
    }
  };
  updateOrderBranch = async (id: string): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/orders/${id}/branch`, {});
    } catch (err) {
      throw err;
    }
  };
  updateOrderCustomer = async (
    id: string,
    orderType: string
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/orders/${id}/customer`, {});
    } catch (err) {
      throw err;
    }
  };
  createPayment = async (
    id: string,
    payment: CreatePaymentRequest
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/invoices/${id}/payments`, payment);
    } catch (err) {
      throw err;
    }
  };
  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("orderType", payload.orderType);
      return await this.upload(`${this.baseURL}/orders/${id}/attachments`, formData);
    } catch (err) {
      throw err;
    }
  };
  getOrderAttachment = async (attachmentID: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/attachments/${attachmentID}`);
    } catch (err) {
      throw err;
    }
  };

  getReceipt = async (
    id: string,
    receiptType: string,
    lng: string
  ): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/orders/${id}/receipt`, {
        receipt_type: receiptType,
        language: lng,
      });
    } catch (err) {
      throw err;
    }
  };
  void = async (
    id: string,
    param: {
      remarks: string;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/orders/${id}/void`, param);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

let _BoxappOrderBackend: BoxappOrderBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappOrderBackend = () => {
  if (!_BoxappOrderBackend) {
    _BoxappOrderBackend = new BoxappOrderBackend();
  }
  return _BoxappOrderBackend;
};

export { getBoxappOrderBackend };
