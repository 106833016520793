import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "./storage.scss";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { useTranslation } from "react-i18next";
import { DepositOrder } from "src/models/depositOrder";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import { Contract } from "src/models/contract";
import { getBoxappContractBackend } from "src/helpers/boxapp_contract_helper";
import { TerminateRentStorageRequest } from "src/helpers/boxapp_storage_helper";

interface StorageTerminateContractPanelProps extends RouteComponentProps {
  // totalSteps: number;
  // isFinished: boolean;
  // step: number;

  // mode: string;
  order: Order;
  customer: Customer;
  depositOrder: DepositOrder;
  onCancel: () => void;
  onSubmit: (data: TerminateRentStorageRequest) => void;
  // onSuccess: (mode: string, storage: any) => void;
}

const StorageTerminateContractPanel = (
  props: StorageTerminateContractPanelProps
) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const [terminateDate, setTerminateDate] = useState<Date | null>(new Date()); // 初始化為今天
  const [remarks, setRemarks] = useState("");
  const [depositRemarks, setDepositRemarks] = useState("");
  const [contract, setContract] = useState<Contract>(); // [TODO

  const [moveOutDate, setMoveOutDate] = useState<Date | null>(null);
  useEffect(() => {
    if (props.order && props.order?.contract_id) {
      setRemarks(props.order ? props.order.remarks : "");
      getBoxappContractBackend()
        .getContract(props.order?.contract_id, {})
        .then((response) => {
          setContract(response.contract);
        });
    }
  }, [props.order]);

  return !props.order ? null : (
    <AvForm
      action="#"
      id={"CollapseForm"}
      onSubmit={async () => {
        if (!terminateDate) {
          return;
        }
        if (props.onSubmit) {
          props.onSubmit({
            order_id: props.order.id,
            remarks: remarks,
            customer_id: props.customer.id,
            move_out_date: moveOutDate
              ? moment(moveOutDate).startOf("day").unix()
              : undefined,
            termination_date: moment(terminateDate).startOf("day").unix(),
            deposit_remarks: depositRemarks,
          });
        }
      }}
    >
      <Card outline color="dark" className="border">
        <CardBody>
          <span className="mt-4 font-size-15 font-weight-bold">
            {`${i18n.t("Contract Details")}${
              i18n.language === "en" ? " " : ""
            }`}
          </span>

          <Row className="mt-2">
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Property")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{contextStorage?.code}</p>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Contract No")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{contract?.id}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Row>
                <Col>
                  <Label>{i18n.t("Customer")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{props.customer?.other_name}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <span className="mt-4 font-size-15 font-weight-bold">
            {`${i18n.t("Current Contract Period")}`}
          </span>

          <Row>
            <Col xs="6">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label>{i18n.t("Contract Start Date")} </Label>
                  <p>
                    {moment
                      .unix(contract?.start_date as number)
                      .format("YYYY/MM/DD")}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label>{i18n.t("Contract End Date")} </Label>
                  <p>
                    {moment
                      .unix(contract?.end_date as number)
                      .format("YYYY/MM/DD")}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <span className="mt-4 font-size-15 font-weight-bold">
            {`${i18n.t("Current Order Rental Period")}`}
          </span>

          <Row>
            <Col xs="6">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label>{i18n.t("Order Start Date")} </Label>
                  <p>
                    {moment.unix(props.order.start_date).format("YYYY/MM/DD")}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs="12" className="mt-3">
                  <Label>{i18n.t("Order End Date")} </Label>
                  <p>
                    {props.order.end_date
                      ? moment.unix(props.order.end_date).format("YYYY/MM/DD")
                      : ""}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card outline color="dark" className="border">
        <CardBody>
          <Row>
            <Col xs="12">
              <Alert
                color="success"
                className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
                role="alert"
              >
                <i className="far fa-smile mr-2"></i>
                <p>{`1. ${i18n.t(
                  "The end date of the contract must be filled in."
                )}`}</p>
                <p>{`2. ${i18n.t(
                  "If the tenant's moving-out date is uncertain, it can be filled in later."
                )}`}</p>
              </Alert>
            </Col>
            <Col xs="12" className="mt-3">
              <Label>
                {`${i18n.t("Contract")}${
                  i18n.language === "en" ? " " : ""
                }${i18n.t("Terminate At")}`}
              </Label>
              {contract?.end_date && (
                <InputGroup>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    required
                    name="terminateDate"
                    selected={terminateDate}
                    className="form-control"
                    onChange={(date: Date | null) => setTerminateDate(date)}
                    showYearDropdown
                    placeholderText={i18n.t("Required")}
                  />
                </InputGroup>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="12" className="mt-3">
              <Label>{i18n.t("Move Out At")}</Label>
              <Row>
                <Col xs="9">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    name="moveOutDate"
                    selected={moveOutDate}
                    className="form-control"
                    onChange={(date: Date | null) => setMoveOutDate(date)}
                    showYearDropdown
                    isClearable
                    placeholderText={i18n.t(
                      "If the tenant's moving-out date is uncertain, it can be filled in later."
                    )}
                  />
                </Col>
                <Col xs="3" className="d-flex align-items-center">
                  <Button
                    color="info"
                    onClick={() => setMoveOutDate(new Date())} // 設置為今天的日期
                    block
                  >
                    <i className="bx bxs-calendar font-size-15 mr-2" />
                    {i18n.t("Today")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="mt-3">
              <BxButton type="submit" color="success" form={"CollapseForm"}>
                {i18n.t("Submit")}
              </BxButton>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12" className="mt-3">
              <FormGroup>
                <Label>{i18n.t("Remarks")}</Label>
                <Input
                  type="textarea"
                  maxLength={500}
                  name="remarks"
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup className="border-top border-5 pt-4 md-4">
                <Label>{`${i18n.t("Deposit")} ${i18n.t("Remarks")}`}</Label>
                <Input
                  type="textarea"
                  // disabled
                  maxLength={500}
                  name="deposit_remarks"
                  onChange={(e) => setDepositRemarks(e.target.value)}
                  value={depositRemarks}
                />
              </FormGroup>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </AvForm>
  );
};

export default withRouter(withTranslation()(StorageTerminateContractPanel));
