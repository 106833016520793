import React from "react";
import { Button, ButtonProps } from "reactstrap";
import { useGlobalModalContext } from "../../context/GlobalModalContext";

export interface BxButtonProps extends ButtonProps {
  icon?: React.ReactNode;
}
export const BxButton = (props: BxButtonProps) => {
  const { loading } = useGlobalModalContext();

  return (
    <Button
      {...props}
      form={props.form}
      loading={undefined}
      className={`${props.className} `}
      disabled={props.disabled || loading}
    >
      {props.icon && !loading && props.icon}
      <i
        className="bx bx-loader-circle bx-spin mr-2"
        hidden={!loading && !props.loading}
      ></i>
      {props.children}
    </Button>
  );
};
