import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Feature } from "../models/feature";

class BoxappFeatureBackend extends BoxappBackend {
  createFeature = async (feature: Feature): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/features`, feature);
    } catch (err) {
      throw err;
    }
  };
  updateFeature = async (id: string, feature: Feature): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/features/${id}`, feature);
    } catch (err) {
      throw err;
    }
  };
  getFeatures = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/features`, query);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappFeatureBackend: BoxappFeatureBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappFeatureBackend = () => {
  if (!_BoxappFeatureBackend) {
    _BoxappFeatureBackend = new BoxappFeatureBackend();
  }
  return _BoxappFeatureBackend;
};

export { getBoxappFeatureBackend };
