import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Row,
  Label,
  Alert,
} from "reactstrap";
import { BxButton } from "../../Button";
import moment from "moment-timezone";
import { withTranslation, useTranslation } from "react-i18next";

// Redux

import "./storageRent.scss";

import { useStorageContext } from "../../../context/StorageProviderContext";

import { useBranchContext } from "../../../context/BranchProviderContext";

import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { getBoxappContractBackend } from "../../../helpers/boxapp_contract_helper";

import { Order } from "src/models/order";
import { Storage } from "src/models/storage";
import { Contract } from "src/models/contract";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { Customer } from "src/models/customer";
import { getBoxappCustomerBackend } from "src/helpers/boxapp_customer_helper";

import { MultiLangText } from "src/models/multiLangText";
import { RentMode, RentPanelSteps } from "./StorageRent";
import StorageRentContractPanel from "./StorageRentContractPanel";
import StorageRentOrderPanel from "./StorageRentOrderPanel";
import { PaidItem } from "src/models/paidItem";
import { calPrice } from "src/pages/Orders/components/invoicePaidItem";
import { CreateOrderRequest } from "src/helpers/boxapp_order_helper";
import SectionDividerWithLeftText from "src/pages/Storages/components/SectionDividerWithLeftText";

import { useAuthContext } from "src/context/AuthProviderContext";

export interface StorageRentNewOrderProps {
  mode: string;
  onSuccess: (action: string, storage: Storage) => void;
  onClose: () => void;
  modal: boolean;
  order: Order;
  customer: Customer;
  contract: Contract;
  interval?: number;
}

const StorageRentNewOrder = (props: StorageRentNewOrderProps) => {
  const [startDate, setStartDate] = useState<number>();
  const { i18n } = useTranslation();
  const [process, setProcess] = useState<number[]>([]);
  const { contextStorage } = useStorageContext();

  const [customer, setCustomer] = useState<Customer>(props.customer);
  const { contextBranch } = useBranchContext();
  const steps = [RentPanelSteps.Contract, RentPanelSteps.Order];
  const { showModal } = useGlobalModalContext();
  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  useEffect(() => {
    if (!customer && props.customer?.id)
      getBoxappCustomerBackend()
        .getCustomer(props.customer.id)
        .then((res) => {
          setCustomer(res.customer!);
        });
  }, [props.customer]);

  const todayAndContractDayDifference = moment
    .unix(props?.contract?.end_date as number)
    .diff(moment(), "days");

  const currentOrderEndDate = moment
    .unix(contextStorage?.rent_records?.[0]?.rent_end_date as number)
    .format("YYYY-MM-DD");

  const contractStartDayAdd1 = moment
    .unix(contextStorage?.rent_records?.[0]?.rent_end_date as number)
    .add(1, "day")
    .format("YYYY-MM-DD");

  const contractEndDate = moment
    .unix(props?.contract?.end_date as number)
    .format("YYYY-MM-DD");

  const contractRemainRentPeriod = moment(contractEndDate).diff(
    moment(currentOrderEndDate),
    "days"
  );

  const isFinished = useCallback(
    (i: number) => process?.indexOf(i) > -1,
    [process]
  );
  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);

  const togglemodal = () => {
    props.onClose();
  };
  const { checkPermission } = useAuthContext();
  useEffect(() => {
    console.log("props.contract", props.contract);
    if (props.contract?.id) {
      fetchLastOrder();
    }
  }, [props.contract]);

  const fetchLastOrder = async () => {
    if (props.contract?.id) {
      const res = await getBoxappContractBackend().getLastOrder(
        props.contract?.id,
        {}
      );
      if (res?.order) {
        const lastOrder = res?.order;
        const orderItems: PaidItem[] = lastOrder?.invoices
          ?.flatMap((invoice) => {
            if (
              invoice.invoice_type === "Payment" &&
              invoice?.status !== "CANCELLED"
            ) {
              return invoice.paid_items?.filter(
                (item) => item?.item_type !== "PREPAID"
              );
            }
            return [];
          })
          ?.sort((a, b) => Number(a?.sort) - Number(b?.sort)) as PaidItem[];

        if (orderItems && orderItems?.length > 0) {
          const storage = orderItems.find(
            (item) => item?.item_type === "STORAGE"
          );

          const items: PaidItem[] = [
            storage as PaidItem,
            ...orderItems.filter((item) => item?.item_type !== "STORAGE"),
          ];

          setPaidItems(
            items?.map((item, i) => {
              switch (item?.item_type) {
                case "STORAGE":
                  const start_date = item?.end_date
                    ? moment
                        .unix(item?.end_date as number)
                        .add(1, "days")
                        .unix()
                    : undefined;

                  const end_date = start_date
                    ? moment
                        .unix(start_date as number)
                        .add(
                          lastOrder?.rent_period,
                          lastOrder?.rent_type?.toLowerCase() as any
                        )
                        .subtract(1, "days")
                        .unix()
                    : undefined;

                  const original_price = contextStorage
                    ? calPrice(
                        {
                          ...item,
                          rent_type: lastOrder?.rent_type,
                          rent_period: lastOrder?.rent_period,
                        },
                        contextStorage
                      )
                    : 0;
                  return {
                    id: item?.id,
                    item_type: item?.item_type,
                    item_id: item?.item_id,
                    item_name: {
                      en: contextStorage?.code,
                      zh: contextStorage?.code,
                      cn: contextStorage?.code,
                    } as MultiLangText,
                    rent_period: lastOrder?.rent_period,
                    rent_type: item?.rent_type ?? lastOrder?.rent_type,
                    start_date: start_date,
                    end_date: end_date,
                    original_price: original_price,
                    final_price: item?.final_price,
                    status: item?.status,
                    sort: i,
                    payment_account_id: item?.payment_account_id,
                  };
                default: {
                  const chargeType = contextBranch?.charge_types?.find(
                    (chargeType) => chargeType.id === item?.item_id
                  );

                  return {
                    id: item?.id,
                    item_name: chargeType?.charge_name,
                    item_id: item?.item_id,
                    charge_type_id: chargeType?.id,
                    charge_mode: chargeType?.charge_mode,
                    quantize: chargeType?.quantize,
                    unit_price: chargeType?.unit_price,
                    unit: chargeType?.unit,
                    start_date: item?.end_date
                      ? moment
                          .unix(item?.end_date as number)
                          .add(1, "days")
                          .unix()
                      : undefined,
                    quantity_start: item?.quantity_end ?? 0,
                    item_type: item?.item_type,
                    original_price: item?.original_price ?? 0,
                    status: item?.status,
                    sort: i,
                    payment_account_id: item?.payment_account_id,
                  };
                }
              }
            })
          );
        }
      }
    }
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            <h5 className="mt-4 font-13">
              {`${i18n.t(RentMode.ContinueRent)} - ${i18n.t(
                "Rental Location Number"
              )} - ${contextStorage?.code}`}
            </h5>
          </ModalHeader>

          <ModalBody>
            <Row className="p-2">
              <Col md="9" className="left-section">
                <Row>
                  <Col>
                    <FormGroup>
                      <div className="mt-1">
                        <Row>
                          {steps.map((e, i) => {
                            return (
                              <Col
                                key={i}
                                md={4}
                                className="btn"
                                onClick={() => {
                                  setCurrentStep(i);
                                }}
                              >
                                <div
                                  className={`avatar-sm rounded-circle  align-self-center mini-stat-icon float-left `}
                                >
                                  <span
                                    className={`avatar-title rounded-circle  ${
                                      currentStep === steps.length - 1 &&
                                      formValid
                                        ? "bg-success"
                                        : currentStep === i
                                        ? "bg-primary"
                                        : isFinished(i)
                                        ? "bg-success"
                                        : "bg-light "
                                    } `}
                                  >
                                    {i + 1}
                                  </span>
                                </div>
                                <div className="float-left h-100 ml-4 align-items-center ">
                                  <h5 className="pt-3 h-100 justify-content-center align-items-center  font-size-14 mb-1 text-dark">
                                    {i18n.t(e)}
                                  </h5>
                                </div>
                                {steps?.length - 1 !== i && (
                                  <i
                                    className={`bx bx-right-arrow-alt  pt-3 font-size-22  ${
                                      currentStep === i
                                        ? "text-primary"
                                        : isFinished(i)
                                        ? "text-success"
                                        : "text-light"
                                    }`}
                                  ></i>
                                )}
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <StorageRentContractPanel
                  rentMode={RentMode.ContinueRent}
                  totalSteps={steps.length}
                  // panel={step}
                  step={1}
                  onSubmit={async (e: any) => {
                    setCurrentStep(0 + 1);
                    setProcess([...process, 0]);
                  }}
                  contract={props.contract}
                  customer={customer!}
                  branch={contextBranch!}
                  storages={[contextStorage]}
                  mode={props.mode}
                  isFinished={isFinished(0)}
                  onCancel={() => {
                    setProcess(process.filter((p) => p !== 0));
                  }}
                  open={currentStep === 0}
                  startDate={startDate ?? moment().unix()}
                  onContractConflict={(e: any) => {}}
                ></StorageRentContractPanel>
                <StorageRentOrderPanel
                  rentMode={RentMode.ContinueRent}
                  totalSteps={steps.length}
                  // panel={step}
                  step={2}
                  onSubmit={async (e: CreateOrderRequest) => {
                    if (contextBranch?.id && contextStorage?.id) {
                      try {
                        const res = await getBoxappStorageBackend().rentStorage(
                          contextBranch?.id,
                          contextStorage?.id,
                          e
                        );
                        if (res) {
                          showModal(MODAL_TYPES.ALERT_MODAL, {
                            title: i18n.t("rent rental location Success"),
                            messageType: "success",
                            message: i18n.t("rent rental location Success"),
                          });
                          props.onSuccess("rent", contextStorage!);
                        }
                      } catch (e) {
                        const errorMsg =
                          (e as any)?.data?.detail ??
                          "" + (e as any)?.data?.message ??
                          "" + e?.toString();
                        showModal(MODAL_TYPES.ALERT_MODAL, {
                          title: i18n.t("rent rental location failed"),
                          messageType: "error",
                          message: errorMsg,
                        });
                      }
                    }
                    setProcess([...process, 1]);
                  }}
                  contract={props.contract}
                  customer={customer!}
                  branch={contextBranch!}
                  // storage={contextStorage}
                  // mode={props.mode}
                  isFinished={isFinished(1)}
                  onCancel={() => {
                    setProcess(process.filter((p) => p !== 1));
                  }}
                  open={currentStep === 1}
                  // startDate={moment().unix()}
                  orderDate={moment().unix()}
                  paidItems={paidItems}
                ></StorageRentOrderPanel>
              </Col>
              <Col md="3" className="right-section pl-3">
                <SectionDividerWithLeftText
                  label={`${i18n.t("Remarks")}`}
                ></SectionDividerWithLeftText>

                <FormGroup className="">
                  <Row>
                    <Col xs="12">
                      <Label className="font-size-13 font-weight-bold">
                        {i18n.t("Customer Remarks")}
                      </Label>
                      <br />
                      <Label className="font-size-13 font-weight-bold">
                        {`(${i18n.t("Admin View Only")})`}
                      </Label>

                      <Alert
                        color={customer?.remarks ? "success" : "secondary"}
                        className="alert-dismissible fade show font-size-13"
                        role="warning"
                      >
                        <p>{customer?.remarks ? customer?.remarks : "N/A"}</p>
                      </Alert>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <FormGroup className=" pt-2">
                  <Row>
                    <Col xs="12">
                      <Label className="font-size-13 font-weight-bold">
                        {i18n.t("Contract Remarks")}
                      </Label>

                      <Alert
                        color={
                          props.contract?.remarks ? "success" : "secondary"
                        }
                        className="alert-dismissible fade show font-size-13 mt-2"
                        role="warning"
                      >
                        <p>
                          {props.contract?.remarks
                            ? props.contract?.remarks
                            : "N/A"}
                        </p>
                      </Alert>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <FormGroup className=" pt-2">
                  <Row>
                    <Col xs="12">
                      <Label className="font-size-13 font-weight-bold">
                        {`${i18n.t(contextStorage?.property_type)} - ${
                          contextStorage?.display_id
                        }`}
                      </Label>
                      <br />
                      <Label className="font-size-13 font-weight-bold">
                        {i18n.t("Property Remarks")}
                      </Label>

                      <Alert
                        color={
                          contextStorage?.remarks ? "success" : "secondary"
                        }
                        className="alert-dismissible fade show font-size-13 mt-2"
                        role="warning"
                      >
                        <p>
                          {contextStorage?.remarks ? (
                            contextStorage.remarks
                              .split("\n")
                              .map((line, index, array) => (
                                <React.Fragment key={index}>
                                  {line}
                                  {index < array.length - 1 && <br />}
                                </React.Fragment>
                              ))
                          ) : (
                            <p>N/A</p>
                          )}
                        </p>
                      </Alert>
                    </Col>
                  </Row>
                </FormGroup>

                <SectionDividerWithLeftText
                  label={i18n.t("Contract & Order Reminder")}
                ></SectionDividerWithLeftText>

                {/* 警告：租單結束日期大於或等於合約結束日期 */}

                {contractRemainRentPeriod < 0 && (
                  <>
                    <Label className="font-size-13 font-weight-bold">
                      {i18n.t("Reminder")}
                    </Label>
                    <Alert
                      color="warning"
                      className="alert-dismissible fade show font-size-15"
                      role="warning"
                    >
                      <i className="bx bx-error bx-tada mr-2 text-danger font-size-20" />

                      {i18n.t(
                        "The rental order end date has exceeded the contract end date. You can use the contract renewal feature, or you may continue to generate rental orders."
                      )}
                      <div className="mt-2"></div>
                    </Alert>
                    <hr />
                  </>
                )}
                {/* Always Visible Information */}

                <Label className="font-size-13 font-weight-bold">
                  {i18n.t("Remaining Rental Period")}:
                </Label>

                <Alert
                  color={
                    todayAndContractDayDifference < 0 ||
                    contractRemainRentPeriod <= 0
                      ? "warning"
                      : "success"
                  }
                  className="fade show font-size-13"
                  role="info"
                >
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="font-weight-bold">
                          {i18n.t("Today to Contract End Date")}:
                        </span>
                        <span className="font-weight-bold">
                          {moment
                            .unix(props?.contract?.end_date as number)
                            .format("YYYY-MM-DD")}
                        </span>
                      </div>
                      <p className="mt-2 text-muted font-size-20">
                        {todayAndContractDayDifference >= 0 ? (
                          <span className="text-primary font-weight-bold">
                            {`${todayAndContractDayDifference} ${i18n.t(
                              "days remaining"
                            )}`}
                          </span>
                        ) : (
                          <span className="text-danger font-weight-bold">
                            {`${i18n.t("Expired")} ${Math.abs(
                              todayAndContractDayDifference
                            )} ${i18n.t("days ago")}`}
                          </span>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-4 mb-2">
                    <Col>
                      <div className="d-flex flex-column justify-content-between align-items-start">
                        {contractRemainRentPeriod > 0 ? (
                          <>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Contract End Date")}:
                            </span>
                            <span className="text-muted">
                              {moment(contractEndDate).format("YYYY-MM-DD")}
                            </span>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Current Order End Date")}:
                            </span>
                            <span className="text-muted">
                              {currentOrderEndDate}
                            </span>

                            <span className="font-weight-bold mt-2">
                              {i18n.t("Next Rental Start Date")}:
                            </span>
                            <span className="text-muted">
                              {moment(contractStartDayAdd1).format(
                                "YYYY-MM-DD"
                              )}
                            </span>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Available Rental Days")}:
                            </span>
                            <span className="text-success font-weight-bold font-size-20">
                              {`${contractRemainRentPeriod} ${i18n.t("days")}`}
                            </span>
                          </>
                        ) : contractRemainRentPeriod === 0 ? (
                          <>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Contract End Date")}:
                            </span>
                            <span className="text-muted">
                              {moment(contractEndDate).format("YYYY-MM-DD")}
                            </span>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Current Order End Date")}:
                            </span>
                            <span className="text-muted">
                              {currentOrderEndDate}
                            </span>

                            <span className="font-weight-bold mt-2">
                              {i18n.t("Next Rental Start Date")}:
                            </span>
                            <span className="text-muted">
                              {moment(contractStartDayAdd1).format(
                                "YYYY-MM-DD"
                              )}
                            </span>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Available Rental Days")}:
                            </span>
                            <span className="text-danger font-weight-bold font-size-20">
                              {`${i18n.t("No Rental Days Left.")}`}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Contract End Date")}:
                            </span>
                            <span className="text-muted">
                              {contractEndDate}
                            </span>
                            <span className="font-weight-bold mt-2">
                              {i18n.t("Current Order End Date")}:
                            </span>
                            <span className="text-muted">
                              {currentOrderEndDate}
                            </span>

                            <span className="font-weight-bold mt-2">
                              {i18n.t("Available Rental Days")}:
                            </span>
                            <span className="text-danger font-weight-bold font-size-15">
                              {`${i18n.t(
                                "The order has exceeded the contract end date"
                              )} :`}
                              <br /> {/* 新增換行 */}
                              {`${Math.abs(contractRemainRentPeriod)} ${i18n.t(
                                "days"
                              )}`}
                            </span>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Alert>
                <hr />
                <Label className="font-size-13 font-weight-bold">
                  {i18n.t("Rental Details")}:
                </Label>
                <Row>
                  <Col xs="12" sm="12">
                    <Alert
                      color={
                        todayAndContractDayDifference > 0
                          ? "success"
                          : "warning"
                      }
                      className="fade show font-size-13"
                      role="info"
                    >
                      <Row>
                        {/* 合約信息 */}
                        <Col xs="12">
                          <p className="mb-2 font-weight-bold">
                            {i18n.t("Contract Details")}
                          </p>
                          <div>
                            <p>{i18n.t("Contract Start Date")}:</p>
                            <p>
                              {moment
                                .unix(props?.contract?.start_date as number)
                                .format("YYYY-MM-DD")}
                            </p>
                          </div>
                          <div>
                            <p>{i18n.t("Contract End Date")}:</p>
                            <p>
                              {moment
                                .unix(props?.contract?.end_date as number)
                                .format("YYYY-MM-DD")}
                            </p>
                          </div>
                        </Col>
                        <hr className="my-4" style={{ width: "100%" }} />{" "}
                        {/* 分隔線 */}
                        {/* 租單信息 */}
                        <Col xs="12">
                          <p className="mb-2 font-weight-bold">
                            {i18n.t("Rental Order Details")}
                          </p>
                          <div>
                            <p>{i18n.t("Current Order Start Date")}:</p>
                            <p>
                              {moment
                                .unix(
                                  contextStorage?.rent_records?.[0]
                                    ?.rent_start_date as number
                                )
                                .format("YYYY-MM-DD")}
                            </p>
                          </div>
                          <div>
                            <p>{i18n.t("Current Order End Date")}:</p>
                            <p>{currentOrderEndDate}</p>
                          </div>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentNewOrder);
