import React from "react";

interface SectionDividerWithleftTextProps {
  label: string;
}

const SectionDividerWithLeftText: React.FC<SectionDividerWithleftTextProps> = ({
  label,
}) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        marginBottom: "20px",
        marginTop: "10px",
      }}
    >
      <span
        style={{
          position: "relative",
          fontSize: "13px", // 設置字體大小
          fontWeight: "bold", // 設置字體加粗
          color: "#333", // 字體顏色

          paddingRight: "10px",
          zIndex: 1,
        }}
      >
        {label}
      </span>
      <hr
        style={{
          position: "absolute",
          top: "50%", // 控制線條與文字的對齊
          left: "0",
          width: "100%",
          border: "none",
          height: "1px",
          backgroundColor: "#6f6f6f",
          zIndex: 0,
        }}
      />
    </div>
  );
};

export default SectionDividerWithLeftText;
