import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import "./uploadAttachmentDialog.scss";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import CloudIcon from "../../../assets/images/cloud.png";
import PDFIcon from "../../../assets/images/pdf.png";
import { useTranslation } from "react-i18next";
export interface UploadAttachmentDialogProps {
  modal?: boolean;
  id?: string;
  onClose: () => void;
  onSuccess?: (file: File) => void;
  // onUpload: (file: File) => void;
}

const UploadAttachmentDialog = (props: UploadAttachmentDialogProps) => {
  const hiddenFileInput = useRef<any>();
  const [fileValidError, setFileValidError] = useState<string>();
  const [fileValid, setFileValid] = useState<boolean>(true);
  const [fileName, setFileName] = useState<string>();
  const [fileType, setFileType] = useState<string>();
  const [file, setFile] = useState<File>();
  const [dragging, setDragging] = useState(false);
  const { t } = useTranslation();
  const dragRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const { i18n } = useTranslation();
  const togglemodal = () => {
    setFile(undefined);
    setFileName("");
    setFileType("");
    setFileValid(true);
    setFileValidError("");
    props.onClose();
  };

  const chooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = (e.target.files as FileList)[0];
    if (fileUploaded) {
      setFileName(fileUploaded.name);
      setFileType(fileUploaded.type);
      const sizeInMB = fileUploaded.size / 1024 / 1024;
      if (sizeInMB > 5) {
        setFileValid(false);
        setFileValidError(i18n.t("File should not larger than 5MB"));
      } else {
        setFileValid(true);
        setFileValidError("");
        setFile(fileUploaded);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      handleFileSelect(fileUploaded);
    }
    setIsDragOver(false);
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleFileSelect = (fileUploaded: File) => {
    setFileName(fileUploaded.name);
    setFileType(fileUploaded.type);
    const sizeInMB = fileUploaded.size / 1024 / 1024;
    if (sizeInMB > 5) {
      setFileValid(false);
      setFileValidError(i18n.t("File should not larger than 5MB"));
    } else {
      setFileValid(true);
      setFileValidError("");
      setFile(fileUploaded);
    }
  };

  const uploadAttachment = () => {
    if (fileValid && file && props.onSuccess) {
      props.onSuccess(file);
    }
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {`${i18n.t("Attachment Upload")} - ${i18n.t(
              "Click or Drag & Drop"
            )}`}
            <div>{i18n.t("File size limit - 5MB.")}</div>
            <div>
              {i18n.t(
                "Supported formats - image files, .pdf, .csv, .xlsx, .xls, .docx, .doc, .txt"
              )}
            </div>
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <FormGroup className="mb-4">
                  <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className={`drop-area ${isDragOver ? "over" : ""}`}
                  >
                    <Row
                      xs={12}
                      onClick={(e: any) => hiddenFileInput.current.click()}
                    >
                      <Col
                        hidden={!!file}
                        xs={12}
                        className=" d-flex  justify-content-center align-items-center"
                      >
                        <img
                          alt="upload"
                          hidden={!!file}
                          className=" upload-icon-large"
                          src={CloudIcon}
                        />
                      </Col>
                      <Col hidden={!file} xs={12}>
                        <Row xs={12}>
                          <Col
                            hidden={!file}
                            xs={12}
                            className=" d-flex  justify-content-center align-items-center"
                          >
                            <img
                              hidden={!file}
                              alt={i18n.t("Reference")}
                              className="previewImg"
                              src={
                                file
                                  ? fileType?.includes("image")
                                    ? URL.createObjectURL(file)
                                    : PDFIcon
                                  : ""
                              }
                            />
                          </Col>
                        </Row>
                        <Row
                          xs={12}
                          className=" d-flex  justify-content-center align-items-center"
                          hidden={!file}
                        >
                          {fileName}
                        </Row>
                        <input
                          type="file"
                          accept="image/*,.pdf,.csv,.xlsx,.xls,.docx,.doc,.txt"
                          ref={hiddenFileInput as any}
                          onChange={(e: any) => chooseFile(e)}
                          hidden={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <span hidden={fileValid} className="error">
                        {" "}
                      </span>
                      <div className={"error"}>
                        {fileValidError ? fileValidError : fileValid ? "" : ""}
                      </div>
                    </Row>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="success"
              onClick={() => uploadAttachment()}
            >
              {i18n.t("Submit")}
            </Button>
            <Button type="button" color="secondary" onClick={togglemodal}>
              {i18n.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(UploadAttachmentDialog);
