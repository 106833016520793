import React, { useEffect, useState } from "react";
// import { MDBDataTableV5 } from "mdbreact";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Spinners from "../Spinners";
import "./datatables.scss";
import { withTranslation } from "react-i18next";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ColumnFilter, { Column, DataRow } from "./ColumnFilter";
import Select from "react-select";
import {
  Collapse,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { UUID } from "crypto";

export interface DataTableProps {
  id?: string;
  nested?: boolean;
  nestedColumns?: Column[] | undefined;
  children?: React.ReactNode;
  data: { columns: Column[]; rows: DataRow[] };
  loading?: boolean;
  columnfilter?: { label?: string };
  onSearch?: (searchText: string) => void;
  onClear?: () => void;
  onSort?: (
    sortField: string,
    sort: "asc" | "desc",
    viewSortOnly: boolean
  ) => void;
  onRowClick?: (row: any) => void;
  onPage?: (page: number) => void;
  onEntitiesCountChange?: (entitiesCount: number) => void;
  placeholderText?: string;
  activePage?: number;
  totalPage?: number;
  entitiesCount?: number;
  totalData?: number;
  noRecordsFoundLabel?: string;
  pagination?: boolean;
  render?: () => React.ReactNode;
  t?: any;
  responsive?: boolean;
  striped?: boolean;
  exportToCSV?: boolean;
  displayEntries?: boolean;
  entriesOptions?: number[];
}

interface Filter {
  [key: string]: boolean;
}

const DataTable = (props: DataTableProps) => {
  const [columnStatus, setColumnStatus] = useState<Array<Column>>([]);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const paginationEnable = props.pagination === false ? false : true;

  const [collapse, setCollapse] = useState(true);
  const columns = props.columnfilter
    ? columnStatus?.filter((col) => col.enable === true)
    : props.data?.columns;
  // const [columns, setColumns] = useState()
  // useEffect(() => {
  //   setColumns(
  //     props.columnfilter
  //       ? columnStatus?.filter((col) => col.enable === true)
  //       : props.data?.columns
  //   )
  // }, [props.columnfilter, props.data?.columns])
  const [nestOpened, setNestOpened] = useState<(UUID | string)[]>([]);
  const getPagination = (activePage: number) => {
    const totalPage = props?.totalPage ?? 0;
    if (props?.totalPage === 0) {
      return [];
    }
    if (totalPage < 5) {
      return Array.from({ length: totalPage }, (_, i) => i + 1);
    } else {
      if (activePage >= 5) {
        if (totalPage - activePage < 5) {
          return Array.from({ length: 5 }, (_, i) => i + (totalPage - 4));
        }
        const start = Array.from({ length: 2 }, (_, i) => i + activePage - 2);
        const end = Array.from({ length: 2 }, (_, i) => i + activePage + 1);
        // props.totalPage+1 - props.activePage
        return [...start, activePage, ...end];
      } else {
        const start = Array.from({ length: activePage }, (_, i) => i + 1);
        const end = Array.from(
          { length: 5 - start.length },
          (_, i) => i + activePage + 1
        );
        return [...start, ...end];
      }
    }
  };

  const pageSelection = paginationEnable && (
    <Col>
      <Pagination
        aria-label="Page navigation example"
        listClassName="justify-content-start"
      >
        <PaginationItem disabled={!props.activePage || props.activePage < 5}>
          <PaginationLink
            tabIndex={-1}
            onClick={() => {
              if (props.onPage) props.onPage(0);
            }}
          >
            <i className="mdi mdi-chevron-left" />
          </PaginationLink>
        </PaginationItem>
        {getPagination(props.activePage ?? 0)?.map(
          (page, i) =>
            !isNaN(page) &&
            props.activePage && (
              <PaginationItem
                key={i}
                active={page === props.activePage}
                onClick={() => {
                  if (props.onPage) props.onPage(page);
                }}
              >
                <PaginationLink>{page}</PaginationLink>
              </PaginationItem>
            )
        )}
        <PaginationItem
          disabled={
            !props.totalPage ||
            !props.activePage ||
            props.totalPage - props.activePage < 5
          }
        >
          <PaginationLink
            onClick={() => {
              if (props.onPage) props.onPage(props.totalPage ?? 0);
            }}
          >
            <i className="mdi mdi-chevron-right" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </Col>
  );

  const pageCountChangeSelection = (
    <Select
      className="text-sm-right"
      menuPlacement="top"
      value={{
        label: `${props.t("Show")}: ${props.entitiesCount ?? 10}`,
        value: props.entitiesCount ?? 10,
      }}
      onChange={(e: any) => {
        if (props.onEntitiesCountChange) props.onEntitiesCountChange(e?.value);
      }}
      isMulti={false}
      options={[
        {
          options: [
            { label: "10", value: 10 },
            { label: "20", value: 20 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
            { label: "500", value: 500 },
            { label: "1000", value: 1000 },
          ],
        },
      ]}
      classNamePrefix="select2-selection"
    />
  );

  const paginationBar = (
    <Row>
      {paginationEnable && <Col md={2}>{pageCountChangeSelection}</Col>}

      <Col xs={12} md={3}>
        {pageSelection}
      </Col>
      <Col></Col>
      {paginationEnable && (
        <Col xs={12} md={1}>
          {` ${props.t("Total Records")}: ${props.totalData}`}
        </Col>
      )}
    </Row>
  );

  useEffect(() => {
    if (props.id && localStorage.getItem(props.id)) {
      const filter = JSON.parse(localStorage.getItem(props.id) ?? "{}");
      setColumnStatus(
        props.data.columns?.map((col) => {
          try {
            const key = Object.keys(filter)?.find((f) => f === col.field);
            if (key) col.enable = filter[key];
          } catch (e) {
            col.enable = true;
          }
          return col;
        })
      );
    } else {
      const defaultFilter = props.data.columns?.map((col) => {
        col.enable = true;
        return col;
      });
      setColumnStatus(defaultFilter);
    }
  }, [props.data.columns]);

  useEffect(() => {
    if (columnStatus && columnStatus.length > 4) {
      const filter: Filter = {};
      columnStatus.forEach((col) => {
        filter[col.field] = !!col.enable;
      });

      if (props.id) localStorage.setItem(props.id, JSON.stringify(filter));
    }
  }, [columnStatus]);

  const toCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <React.Fragment>
      {props.columnfilter && (
        <Row className="">
          <Col sm="4">
            <Button
              outline
              className="btn waves-effect waves-light btn-sm "
              onClick={toCollapse}
              style={{ cursor: "pointer" }}
            >
              <i
                className={`${
                  collapse ? "bx bxs-down-arrow" : "bx bxs-up-arrow"
                } font-size-16 align-middle mr-2`}
              />
              {props.columnfilter?.label}
            </Button>
          </Col>
          <Col xs={8}></Col>
          <Collapse isOpen={!collapse} className="mb-4">
            <ColumnFilter
              title={props.columnfilter?.label ?? ""}
              columns={columnStatus}
              onFilter={(k, v) => {
                setColumnStatus(
                  columnStatus.map((col) => {
                    if (col.field === k) {
                      col.enable = !col.enable;
                      let filter: Filter = {};
                      if (props.id) {
                        try {
                          filter = JSON.parse(
                            localStorage.getItem(props.id) ?? "{}"
                          );
                        } catch (e) {
                          filter = {};
                        }
                        filter[k] = col.enable;
                      }
                    }
                    return col;
                  })
                );
              }}
            />
            {props.render && props.render()}
          </Collapse>
        </Row>
      )}
      {!!props.loading && (
        <div className={`loading-container overlay-over`}>
          <Spinners
            className="vertical-center"
            loading={!!props.loading}
          ></Spinners>
        </div>
      )}
      {props.children}
      <Row className="mb-2">
        {props.onSearch && !props.loading && (
          <>
            <Col xs={12} md={2}>
              {pageCountChangeSelection}
            </Col>
            <Col xs={12} md={10}>
              <div className="text-sm-right">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="bx bx-search-alt font-size-16 align-middle"></i>
                    </span>
                  </div>
                  <input
                    name="search"
                    autoComplete="off"
                    placeholder={`《 ${props.t("Total Records")}: ${
                      props.totalData
                    } 》 |  ${props.placeholderText || props.t("Search")}`}
                    type="search"
                    className="form-control"
                    value={searchText ?? ""}
                    onChange={async (e) => {
                      setSearchText(e.target.value);
                      if (e.target.value?.length > 0) {
                        if (props.onSearch) props.onSearch(e.target.value);
                      } else {
                        if (props.onClear) props.onClear();
                      }
                    }}
                  />
                  {/* <BxButton color={"primary"} onClick={() => {
                  if (props.onSearch) {
                    props.onSearch(searchText)
                  }
                }}>{props.t("Search")}</BxButton> */}
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>

      <div className="table-responsive ">
        <Table className="table table-striped table-bordered">
          <Thead>
            <Tr>
              {props.nested && <Th></Th>}
              {columns?.map((col, j) => {
                return (
                  <Th key={j} width={col.width}>
                    {col.label}
                    {col.sortable &&
                      (col.sort === "asc" ? (
                        <i
                          className="bx bx-up-arrow-alt"
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            if (props.onSort && col.sortField) {
                              props.onSort(
                                col.sortField,
                                "desc",
                                col?.viewSortOnly ?? false
                              );
                            }
                          }}
                        />
                      ) : (
                        <i
                          className="bx bx-down-arrow-alt"
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            if (props.onSort && col.sortField) {
                              props.onSort(
                                col.sortField,
                                "asc",
                                col?.viewSortOnly ?? false
                              );
                            }
                          }}
                        />
                      ))}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          {props.data.rows?.length === 0 && !!!props.loading ? (
            <Tbody>
              <Tr>
                <Td>{props.noRecordsFoundLabel}</Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody>
              {props.data?.rows?.map((row, i) => {
                if (props.nested && props.nestedColumns) {
                  return (
                    <>
                      <Tr key={i}>
                        <Td>
                          <Link
                            to={"#"}
                            color="primary"
                            className="btn-rounded waves-effect waves-light mb-2 mr-2"
                            onClick={() => {
                              if (row.id) {
                                if (nestOpened.indexOf(row.id) > -1) {
                                  setNestOpened(
                                    nestOpened.filter((item) => item !== row.id)
                                  );
                                } else {
                                  setNestOpened([...nestOpened, row.id]);
                                }
                              }
                            }}
                          >
                            <i className="mdi mdi-plus mr-1"></i>
                          </Link>
                        </Td>
                        {columns?.map((col, j) => {
                          return (
                            <Td
                              style={{ overflowWrap: "break-word" }}
                              key={j}
                              onClick={() => {
                                if (props.onRowClick) {
                                  props.onRowClick(row);
                                }
                              }}
                            >
                              {Array.isArray(row[col?.field])
                                ? props.t("N/A")
                                : row[col?.field]}
                            </Td>
                          );
                        })}
                      </Tr>
                      <Tr key={i + "nest"}>
                        <Td colSpan={columns?.length + 1}>
                          <Collapse
                            isOpen={
                              !!row.id && nestOpened?.indexOf(row.id) > -1
                            }
                          >
                            <Table>
                              <Thead>
                                <Tr>
                                  {props.nestedColumns?.map((col, j) => {
                                    return (
                                      <Th key={j} width={col.width}>
                                        {col.label}
                                      </Th>
                                    );
                                  })}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {row.nest?.map((row: any, i: number) => {
                                  return (
                                    <Tr key={i}>
                                      {props.nestedColumns?.map((col, j) => {
                                        return (
                                          <Td
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                            key={j}
                                            onClick={() => {
                                              if (props.onRowClick) {
                                                props.onRowClick(row);
                                              }
                                            }}
                                          >
                                            {Array.isArray(row[col?.field])
                                              ? props.t("N/A")
                                              : row[col?.field]}
                                          </Td>
                                        );
                                      })}
                                    </Tr>
                                  );
                                })}
                              </Tbody>
                            </Table>
                          </Collapse>
                        </Td>
                      </Tr>
                    </>
                  );
                }
                return (
                  <Tr key={i}>
                    {columns?.map((col, j) => {
                      return (
                        <Td
                          style={{ overflowWrap: "break-word" }}
                          key={j}
                          onClick={() => {
                            if (props.onRowClick) {
                              props.onRowClick(row);
                            }
                          }}
                        >
                          {Array.isArray(row[col?.field])
                            ? props.t("N/A")
                            : row[col?.field]}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </div>
      {paginationBar}
    </React.Fragment>
  );
};
export default withTranslation()(DataTable);
