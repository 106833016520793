import React, { useEffect, useState, useMemo, useCallback } from "react";

import "./invoiceInfo.scss";
import moment from "moment-timezone";

import {
  Alert,
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from "reactstrap";

// Redux
import SubmissionModal from "../../../components/Modal/SubmissionModal";

import { useTranslation } from "react-i18next";
import { Branch } from "src/models/branch";
import { ChargeType } from "src/models/chargeType";
import { PaidItem } from "src/models/paidItem";
import { InvoiceType } from "src/models/invoice";
import Decimal from "decimal.js";
import { OrderType } from "src/util/orderType";
import { MultiLangText } from "src/models/multiLangText";
import { ChargeMode } from "src/util/chargeMode";
import { DepositOrder } from "src/models/depositOrder";
import { getBoxappPaymentAccountBackend } from "src/helpers/boxapp_payment_account_helper";
import { PaymentAccount } from "src/models/paymentAccount";
import Select from "react-select";

export interface MiscellaneousPaidItemProps {
  id?: string;
  deposit?: DepositOrder;
  branch: Branch;
  // storage: Storage;
  // mode: string;
  modal: boolean;
  item?: PaidItem;

  invoiceType: InvoiceType;
  onSuccess: (paidItem: PaidItem) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const MiscellaneousPaidItem = (props: MiscellaneousPaidItemProps) => {
  const { i18n } = useTranslation();
  const [lastOrderPaidItem, setLastOrderPaidItem] = useState<PaidItem>();

  const [errorMessage, setErrorMessage] = useState<any>();
  // const [paidType, setPaidType] = useState<PaidItemChargeTypeOption>();
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [quantize, setQuantize] = useState<boolean>(false);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [unit, setUnit] = useState<string>();
  const [quantityStart, setQuantityStart] = useState<number>(1);
  const [chargeName, setChargeName] = useState<string>();
  const [startDate, setStartDate] = useState<number | undefined>(
    moment().unix()
  );
  const [endDate, setEndDate] = useState<number>();
  const [paymentAccount, setPaymentAccount] = useState<{
    label: string;
    value: string;
  }>();

  const [paymentAccounts, setPaymentAccounts] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  {
    /* 自訂 Payment Account 自動玲 REVENUE START*/
  }
  useEffect(() => {
    getBoxappPaymentAccountBackend()
      .getPaymentAccounts({
        branch_id: props.branch.id,
      })
      .then(({ payment_accounts }: any) => {
        if (!payment_accounts || !payment_accounts.data) {
          console.error("No payment accounts data found.");
          return;
        }

        // 查找 name 為 "REVENUE" 的賬戶
        const revenueAccountData = payment_accounts.data.find(
          (paymentAccount: PaymentAccount) =>
            paymentAccount.name === "REVENUE" &&
            paymentAccount.branch_id === props.branch?.id
        );

        // 格式化所有符合條件的賬戶
        const formattedAccounts = payment_accounts.data
          .filter(
            (paymentAccount: PaymentAccount) =>
              paymentAccount.name === "REVENUE" &&
              paymentAccount.branch_id === props.branch.id
          )
          .map((paymentAccount: PaymentAccount) => {
            console.log("Formatting paymentAccount:", paymentAccount);
            return {
              label: `${paymentAccount.account_type} : ${paymentAccount.name} - ${paymentAccount.account_no}`,
              value: paymentAccount.id,
            };
          });

        setPaymentAccounts(formattedAccounts);

        // 設置選中的賬戶
        if (revenueAccountData) {
          const formattedRevenueAccount = {
            label: `${revenueAccountData.account_type}:${revenueAccountData.name}-${revenueAccountData.account_no}`,
            value: revenueAccountData.id,
          };
          console.log(
            "Setting revenueAccount as selected:",
            formattedRevenueAccount
          );
          setPaymentAccount(formattedRevenueAccount);
        } else if (formattedAccounts.length > 0) {
          console.log(
            "Setting first formatted account as selected:",
            formattedAccounts[0]
          );
          setPaymentAccount(formattedAccounts[0]);
        } else {
          console.warn("No accounts available to set as selected.");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch payment accounts:", error);
        // 你可以在這裡設置錯誤狀態或顯示錯誤信息
      });
  }, [props.branch.id]);
  {
    /* 自訂 Payment Account 自動玲 REVENUE END*/
  }

  useEffect(() => {
    if (props.item) {
      setOriginalPrice(Number(props.item?.original_price) ?? 0);
      setQuantize(props.item?.quantize ?? false);
      setUnitPrice(props.item?.unit_price ?? 0);
      setUnit(props.item?.unit);
      setQuantityStart(props.item?.quantity_start ?? 1);
      setChargeName(props.item?.item_name?.en ?? "");
      setStartDate((props.item?.start_date as number) ?? 0);
      setEndDate((props.item?.end_date as number) ?? 0);
    }
  }, [props.item]);

  const onSubmit = async () => {
    const discount_amount = 0;

    if ((originalPrice ?? 0) < 0) {
      setErrorMessage(i18n.t("paid item price is invalid"));
      return;
    }

    setErrorMessage(undefined);

    props.onSuccess({
      id: props.item?.id,
      order_id: props.deposit?.id,
      order_type: OrderType.Deposit,
      item_id: undefined,
      item_type: "MISCELLANEOUS",
      item_name: {
        en: chargeName,
        zh: chargeName,
        cn: chargeName,
      } as MultiLangText,

      item: {
        id: undefined,
        quantize: true,
        // charge_type_category_id: paidType?.charge_type_category_id,
        // charge_type_category: paidType?.charge_type_category,
        charge_name: {
          en: chargeName,
          zh: chargeName,
          cn: chargeName,
        } as MultiLangText,
        charge_mode: ChargeMode.STAND_ALONE,
        // unit_price: paidType?.unit_price,
        // unit: paidType?.unit,
        // default_quantity: paidType?.default_quantity,
        // branches: paidType?.branches,
        // charge_type_id: paidType?.charge_type_id,
        // sort: paidType?.sort,
        // branch_id: paidType?.branch_id,
      } as ChargeType,
      start_date: startDate,
      end_date: endDate,
      final_price: originalPrice - discount_amount,
      quantize: quantize,
      // unit_price: unitPrice,
      quantity_start: quantityStart ?? 1,
      // quantity_end: undefined,
      // default_quantity: undefined,
      original_price: originalPrice,
      charge_mode: ChargeMode.STAND_ALONE,
      unit: unit,
      discount_amount: discount_amount,
      status: "DRAFT",
      sort: props.item?.sort ?? 0,
      payment_account_id: paymentAccount?.value,
    });
  };

  const onClose = () => {
    props.onClose();
  };

  const maxTransferAmount: Decimal | undefined = useMemo(() => {
    if (props.invoiceType === InvoiceType.Transfer) {
      const paidAmount: Decimal[] =
        props.deposit?.invoices?.flatMap((invoice) => {
          return (
            invoice?.paid_items?.map((paidItem) => {
              if (
                paidItem?.item_type === "MISCELLANEOUS" &&
                (paidItem?.status === "COMPLETED" ||
                  paidItem?.status === "PARTIAL_COMPLETED")
              ) {
                return new Decimal(paidItem?.final_price ?? 0);
              }
              return new Decimal(0);
            }) ?? []
          );
        }) ?? [];
      return paidAmount?.reduce((acc, cur) => acc.add(cur), new Decimal(0));
    }
    return undefined;
  }, [props.deposit, props.invoiceType]);

  return (
    <SubmissionModal
      id={props.id}
      size={"xl"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Other Charge Items")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          {lastOrderPaidItem && (
            <Alert
              color="warning"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {lastOrderPaidItem?.quantize &&
                lastOrderPaidItem?.charge_mode === ChargeMode.ACCUMLATIVE && (
                  <i className="mdi mdi-calendar-arrow-left">
                    {i18n.t("Last Order Price")}:{" "}
                    {lastOrderPaidItem?.original_price}
                    <br />
                    {i18n.t("Last Quantity End")}:{" "}
                    {lastOrderPaidItem?.quantity_end}
                    {lastOrderPaidItem?.unit}
                    <br />
                    {i18n.t("Last Start Date")}:{" "}
                    {lastOrderPaidItem?.start_date
                      ? moment(
                          (lastOrderPaidItem?.start_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                    <br />
                    {i18n.t("Last End Date")}:{" "}
                    {lastOrderPaidItem?.end_date
                      ? moment(
                          (lastOrderPaidItem?.end_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                  </i>
                )}
              {lastOrderPaidItem?.quantize &&
                lastOrderPaidItem?.charge_mode === ChargeMode.STAND_ALONE && (
                  <i className="mdi mdi-calendar-arrow-left">
                    {i18n.t("Last Order Price")}:{" "}
                    {lastOrderPaidItem?.original_price}
                    <br />
                    {i18n.t("Last Quantity")}: {lastOrderPaidItem?.quantity_end}
                    {lastOrderPaidItem?.unit}
                    <br />
                    {i18n.t("Last Start Date")}:{" "}
                    {lastOrderPaidItem?.start_date
                      ? moment(
                          (lastOrderPaidItem?.start_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                    <br />
                    {i18n.t("Last End Date")}:{" "}
                    {lastOrderPaidItem?.end_date
                      ? moment(
                          (lastOrderPaidItem?.end_date as number) * 1000
                        ).format("YYYY-MM-DD")
                      : "N/A"}
                  </i>
                )}
              {!lastOrderPaidItem?.quantize && (
                <i className="mdi mdi-calendar-arrow-left">
                  {i18n.t("Last Order Price")}:{" "}
                  {lastOrderPaidItem?.original_price}
                  <br />
                  {i18n.t("Last Start Date")}:{" "}
                  {lastOrderPaidItem?.start_date
                    ? moment(
                        (lastOrderPaidItem?.start_date as number) * 1000
                      ).format("YYYY-MM-DD")
                    : "N/A"}
                  <br />
                  {i18n.t("Last End Date")}:{" "}
                  {lastOrderPaidItem?.end_date
                    ? moment(
                        (lastOrderPaidItem?.end_date as number) * 1000
                      ).format("YYYY-MM-DD")
                    : "N/A"}
                </i>
              )}
            </Alert>
          )}
          <Row className="ml-2 mb-1">
            {props.invoiceType === InvoiceType.Transfer && (
              <Col xs="12">
                <Alert
                  color="warning"
                  className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
                  role="alert"
                >
                  {`${i18n.t(`Max Transaction Amount`)}: $${maxTransferAmount}`}
                </Alert>
              </Col>
            )}
            <Col xs={4}>
              <Label>{i18n.t("Paid Type")}</Label>
              <Input
                type="text"
                name={"chargeType-"}
                disabled={true}
                value={"Miscellaneous"}
              />
            </Col>
            <Col xs="1"></Col>
            <Col xs="1"></Col>
            <Col xs={4}>
              <Label>{i18n.t("Name")}</Label>
              <Input
                type="text"
                name={"name-"}
                value={chargeName}
                onChange={(e) => {
                  setChargeName(e?.target?.value);
                }}
              />
            </Col>
            <Col xs="1"></Col>
            <Col xs="1"></Col>
            <Col xs={4}>
              <Label>{i18n.t("Price")}</Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Input
                  type="number"
                  max={maxTransferAmount?.toNumber()}
                  maxLength={8}
                  name={"paidItemPrice-"}
                  onChange={(e) => {
                    const price = new Decimal(e.target.value ?? 0);
                    setOriginalPrice(price?.toNumber());
                  }}
                  value={originalPrice}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <FormGroup hidden>
                <Label> {i18n.t("Financial Account")}: </Label>
                <Select
                  required
                  placeholder={i18n.t("Select...")}
                  value={paymentAccount}
                  onChange={(e: any) => setPaymentAccount(e)}
                  options={[
                    {
                      options: paymentAccounts,
                    },
                  ]}
                  classNamePrefix="select-selection"
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage?.msg}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default MiscellaneousPaidItem;
