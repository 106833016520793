import React, { useEffect, useState, useMemo } from "react";
import "./invoiceInfo.scss";
import moment from "moment-timezone";
import RentalPeriod, {
  RentPeriod,
} from "../../../components/Modal/Storages/RentalPeriod";
import {
  Alert,
  Row,
  Col,
  Input,
  Label,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap";
import SubmissionModal from "../../../components/Modal/SubmissionModal";
import { useTranslation } from "react-i18next";
import { Storage } from "src/models/storage";
import { Branch } from "src/models/branch";
import { MultiLangText } from "src/models/multiLangText";
import { PaidItem } from "src/models/paidItem";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
import Decimal from "decimal.js";
import { DepositOrder } from "src/models/depositOrder";
import { calPrice } from "./invoicePaidItem";
import Select from "react-select";
import { getBoxappPaymentAccountBackend } from "src/helpers/boxapp_payment_account_helper";
import { PaymentAccount } from "src/models/paymentAccount";

export interface StoragePaidItemProps {
  id?: string;
  deposit?: DepositOrder;
  branch: Branch;
  storage: Storage;
  modal: boolean;
  item?: PaidItem;
  invoiceType: InvoiceType;
  orderType: OrderType;
  onSuccess: (paidItem: PaidItem) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const StoragePaidItem = (props: StoragePaidItemProps) => {
  const { i18n } = useTranslation();
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [rentType, setRentType] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY">(
    "YEAR"
  );
  const [rentPeriod, setRentPeriod] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [paymentAccount, setPaymentAccount] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [paymentAccounts, setPaymentAccounts] = useState<
    { label: string; value: string }[]
  >([]);

  {
    /* 自訂 Payment Account 自動玲 REVENUE START*/
  }
  useEffect(() => {
    getBoxappPaymentAccountBackend()
      .getPaymentAccounts({
        branch_id: props.branch.id,
      })
      .then(({ payment_accounts }: any) => {
        if (!payment_accounts || !payment_accounts.data) {
          console.error("No payment accounts data found.");
          return;
        }

        // 查找 name 為 "REVENUE" 的賬戶
        const revenueAccountData = payment_accounts.data.find(
          (paymentAccount: PaymentAccount) =>
            paymentAccount.name === "REVENUE" &&
            paymentAccount.branch_id === props.branch?.id
        );

        // 格式化所有符合條件的賬戶
        const formattedAccounts = payment_accounts.data
          .filter(
            (paymentAccount: PaymentAccount) =>
              paymentAccount.name === "REVENUE" &&
              paymentAccount.branch_id === props.branch.id
          )
          .map((paymentAccount: PaymentAccount) => {
            return {
              label: `${paymentAccount.account_type} : ${paymentAccount.name} - ${paymentAccount.account_no}`,
              value: paymentAccount.id,
            };
          });

        setPaymentAccounts(formattedAccounts);

        // 設置選中的賬戶
        if (revenueAccountData) {
          const formattedRevenueAccount = {
            label: `${revenueAccountData.account_type}:${revenueAccountData.name}-${revenueAccountData.account_no}`,
            value: revenueAccountData.id,
          };
          console.log(
            "Setting revenueAccount as selected:",
            formattedRevenueAccount
          );
          setPaymentAccount(formattedRevenueAccount);
        } else if (formattedAccounts.length > 0) {
          console.log(
            "Setting first formatted account as selected:",
            formattedAccounts[0]
          );
          setPaymentAccount(formattedAccounts[0]);
        } else {
          console.warn("No accounts available to set as selected.");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch payment accounts:", error);
        // 你可以在這裡設置錯誤狀態或顯示錯誤信息
      });
  }, [props.branch.id]);
  {
    /* 自訂 Payment Account 自動玲 REVENUE END*/
  }

  useEffect(() => {
    if (props.item) {
      setOriginalPrice(Number(props.item?.original_price) ?? 0);
      setRentType(props.item?.rent_type ?? "YEAR");
      setRentPeriod(Number(props.item?.rent_period ?? 1));
      setStartDate(props.item?.start_date ?? 0);
      setEndDate(props.item?.end_date ?? 0);
    }
  }, [props.item]);

  const maxTransferAmount: Decimal | undefined = useMemo(() => {
    if (props.invoiceType === InvoiceType.Transfer) {
      const paidAmount: Decimal[] =
        props.deposit?.invoices?.flatMap((invoice) => {
          return (
            invoice?.paid_items?.map((paidItem) => {
              if (
                paidItem?.item_type === "STORAGE" &&
                (paidItem?.status === "COMPLETED" ||
                  paidItem?.status === "PARTIAL_COMPLETED")
              ) {
                return new Decimal(paidItem?.final_price ?? 0);
              }
              return new Decimal(0);
            }) ?? []
          );
        }) ?? [];
      return paidAmount?.reduce((acc, cur) => acc.add(cur), new Decimal(0));
    }
    return undefined;
  }, [props.deposit, props.invoiceType]);

  const onSubmit = async () => {
    const discount_amount = 0;

    if ((originalPrice ?? 0) < 0) {
      setErrorMessage(i18n.t("paid item price is invalid"));
      return;
    }

    setErrorMessage(undefined);

    props.onSuccess({
      id: props.item?.id,
      order_id: props.deposit?.id,
      order_type: OrderType.Deposit,
      item_id: props.storage?.id,
      item_type: "STORAGE",
      item_name: {
        en: props.storage?.code,
        zh: props.storage?.code,
        cn: props.storage?.code,
      } as MultiLangText,
      item: props.storage as Storage,
      start_date: startDate,
      end_date: endDate,
      rent_period: rentPeriod,
      rent_type: rentType,
      final_price: originalPrice - discount_amount,
      original_price: originalPrice,
      discount_amount: discount_amount,
      status: "DRAFT",
      sort: props.item?.sort ?? 0,
      payment_account_id: paymentAccount?.value,
    });
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <SubmissionModal
      id={props.id}
      size={"xl"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Storage Supplement Item")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          <Row className="ml-2 mb-1">
            {props.invoiceType === InvoiceType.Transfer && (
              <Col xs="12">
                <Alert
                  color="warning"
                  className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
                  role="alert"
                >
                  {`${i18n.t(`Max Transaction Amount`)}: $${maxTransferAmount}`}
                </Alert>
              </Col>
            )}
            <Col xs="6">
              <Label>
                {i18n.t("Rental Location")}-{props.storage?.code}
              </Label>
            </Col>
            <Col xs="5">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Input
                  type="number"
                  max={maxTransferAmount?.toNumber()}
                  maxLength={8}
                  name={"paidItemPrice"}
                  onChange={(e) => {
                    setOriginalPrice(Number(e.target.value ?? 0));
                  }}
                  value={originalPrice}
                />
              </div>
            </Col>
            <Col xs="1"></Col>

            {props.orderType === "Rent" && props.invoiceType === "Payment" && (
              <Col xs="12" className="mt-2">
                <RentalPeriod
                  defaultRentType={rentType}
                  rentPeriod={rentPeriod}
                  startDate={startDate ? Number(startDate) : moment().unix()}
                  endDate={endDate}
                  periodTitle={i18n.t("Rent")}
                  dateRequired={false}
                  allow_yearly={
                    !!(
                      props.branch?.allow_yearly && props.storage?.yearly_price
                    )
                  }
                  allow_monthly={
                    !!(
                      props.branch?.allow_monthly &&
                      props.storage?.monthly_price
                    )
                  }
                  allow_weekly={
                    !!(
                      props.branch?.allow_weekly && props.storage?.weekly_price
                    )
                  }
                  allow_daily={
                    !!(props.branch?.allow_daily && props.storage?.daily_price)
                  }
                  onChange={(e: RentPeriod) => {
                    setRentType(e.rent_type ?? "YEAR");
                    setRentPeriod(e.rent_period ?? 1);
                    setStartDate(e.start_date);
                    setEndDate(e.end_date);
                    setOriginalPrice(
                      calPrice(
                        {
                          rent_type: e.rent_type ?? "YEAR",
                          rent_period: e.rent_period ?? 1,
                        },
                        props.storage
                      )
                    );
                  }}
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col xs="12">
              <FormGroup hidden>
                <Label> {i18n.t("Financial Account")}: </Label>
                <Select
                  required
                  placeholder={i18n.t("Select...")}
                  value={paymentAccount}
                  onChange={(e: any) => setPaymentAccount(e)}
                  options={paymentAccounts}
                  classNamePrefix="select-selection"
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default StoragePaidItem;
