import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";
// import i18next from 'i18next';
import i18next from "i18next";
import { ModalProps } from "./ModalProps";

const ActionModal = (props: ModalProps) => {
  const { hideModal, store } = useGlobalModalContext();
  const { title, message, onConfirm, onCancel } = store?.modalProps || {};

  const handleModalToggle = () => {
    hideModal();
    if (onCancel) {
      onCancel();
    }
  };

  const handleModalActionConfirm = (e: any) => {
    hideModal();
    if (onConfirm) {
      onConfirm(e);
    }
  };
  return (
    <SweetAlert
      title={i18next.t(title ?? "")}
      warning
      showCancel
      show={true}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      confirmBtnText={i18next.t("OK")}
      cancelBtnText={i18next.t("CANCEL")}
      onConfirm={handleModalActionConfirm}
      onCancel={() => handleModalToggle()}
    >
      {message}
    </SweetAlert>
  );
};

export default ActionModal;
