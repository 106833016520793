import { getBoxappBranchBackend } from "../helpers/boxapp_branch_helper";
import { getBoxappAccountBackend } from "../helpers/boxapp_account_helper";
import { getBoxappRoleBackend } from "../helpers/boxapp_role_helper";
import { store } from "./storage";
import { duration } from "./duration";
import { getBoxappChargeTypeBackend } from "../helpers/boxapp_charge_type_helper";
import { getBoxappExpendTypeBackend } from "../helpers/boxapp_expend_type_helper";
import { MultiLangText } from "../models/multiLangText";
import { ExpendTypeCategory } from "../models/expendTypeCategory";
import { Pagination } from "../api/reponseResult";
import { ChargeTypeCategory } from "../models/chargeTypeCategory";
import { Branch } from "../models/branch";
import { Account } from "../models/account";
import { Role } from "../models/role";
import { Category } from "../models/category";
import { Feature } from "../models/feature";
import i18next from "i18next";
import { ExpendType } from "src/models/expendType";
import { getBoxappTemplateBackend } from "src/helpers/boxapp_template_helper";
import { ChargeType } from "src/models/chargeType";
import { CustomerType } from "./customerType";
import { ContractStatus } from "./contractStatus";
import { ChargeMode } from "./chargeMode";

export const chargeModeOptionGroup = [{
  options: [
    { label: i18next.t('STAND ALONE'), value: ChargeMode.STAND_ALONE },
    { label: i18next.t('ACCUMLATIVE'), value: ChargeMode.ACCUMLATIVE },
  ]
}]
export const optionNoticeMethods = [
  {
    options: [
      { label: i18next.t("WHATSAPP"), value: "WHATSAPP" },
      { label: i18next.t("EMAIL"), value: "EMAIL" },
      // { label: i18next.t("SMS"), value: "SMS" },
      // { label: i18next.t("IN_APP"), value: "IN_APP" },
    ],
  },
]

export const mfaOptionGroup = [
  {
    options: [
      {
        label: i18next.t("GOOGLE_AUTHENTICATOR"),
        value: "GOOGLE_AUTHENTICATOR",
      },
    ],
  },
];

export const optionPropertyTypes = [
  {
    options: [
      {
        label: i18next.t("Shop"),
        value: "SHOP",
      },
      {
        label: i18next.t("Office"),
        value: "OFFICE",
      },
      {
        label: i18next.t("Room"),
        value: "ROOM",
      },
      {
        label: i18next.t("Mini-store"),
        value: "MINI_STORE",
      },
      {
        label: i18next.t("Parking"),
        value: "PARKING",
      },
      {
        label: i18next.t("Rooftop"),
        value: "Rooftop",
      },
      {
        label: i18next.t("Land"),
        value: "Land",
      },
      {
        label: i18next.t("Locker"),
        value: "LOCKER",
      },
      {
        label: i18next.t("Pallet"),
        value: "PALLET",
      },
      {
        label: i18next.t("Mail Box"),
        value: "MAIL_BOX",
      },
      {
        label: i18next.t("Billboard"),
        value: "BILLBOARD",
      },
    ],
  },
];

export const optionLanguage = [
  {
    // label: i18next.t("Language"),
    options: [
      { label: i18next.t("English"), value: "en" },
      { label: i18next.t("Chinese"), value: "cn" },
      { label: i18next.t("Tranditional Chinese"), value: "zh" },
    ],
  },
];
export const optionOrderType = {
  label: i18next.t("Order Type"),
  options: [
    { label: i18next.t("RENTAL ORDER"), value: "Rent" },
    { label: i18next.t("DEPOSIT ORDER"), value: "Deposit" },
  ],
};
export const optionGender = [
  {
    options: [
      { label: i18next.t("M"), value: "M" },
      { label: i18next.t("F"), value: "F" },
      { label: i18next.t("N/A"), value: "N/A" },
    ],
  },
];

export const optionStorageStatus = [
  {
    label: i18next.t("Status"),
    options: [
      { label: i18next.t("AVAILABLE"), value: "AVAILABLE" },
      { label: i18next.t("PENDING"), value: "PENDING" },
      { label: i18next.t("RENT"), value: "RENT" },
      { label: i18next.t("DISABLE"), value: "DISABLE" },
    ],
  },
];

export const optionOrderStatus = [
  {
    label: i18next.t("Need Handle"),
    options: [
      { label: i18next.t("PENDING"), value: "PENDING" },
      { label: i18next.t("PARTIAL_COMPLETED"), value: "PARTIAL_COMPLETED" },
      { label: i18next.t("UNALLOCATED"), value: "UNALLOCATED" },
      { label: i18next.t("COMPLETED"), value: "COMPLETED" },
            { label: i18next.t("CANCELLED"), value: "CANCELLED" },
    ],
  },
  { label: "──────────", value: "", isDisabled: true },
  {
    label: i18next.t("Other Status"),
    options: [

      { label: i18next.t("VOID"), value: "VOID" },
      { label: i18next.t("CANCELLED"), value: "CANCELLED" },
    ],
  },
];

export const optionRentRecordStatus = [
  {
    label: i18next.t("Status"),
    options: [
      { label: i18next.t("PENDING"), value: "PENDING" },
      { label: i18next.t("CANCELLED"), value: "CANCELLED" },
      { label: i18next.t("TERMINATED"), value: "TERMINATED" },
      { label: i18next.t("RENT"), value: "RENT" },
    ],
  },
];

export const optionTemplateLang = [
  {
    options: [
      { label: i18next.t("EN"), value: "en" },
      { label: i18next.t("SC"), value: "sc" },
      { label: i18next.t("TC"), value: "tc" },
    ],
  },
];

export const optionExpendTypeCategory = () => {
  const cache = store.getWithExpiry(
    "expendTypeCategories"
  ) as Pagination<ExpendTypeCategory>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return [
        ...(cache?.data?.map((expendTypeCategory) => {
          return {
            label:
              expendTypeCategory.name?.[
              i18next.language as keyof MultiLangText
              ],
            value: expendTypeCategory.id,
          };
        }) ?? []),
      ];
    });
  }
  return getBoxappExpendTypeBackend()
    .getExpendTypeCategories()
    .then((data) => {
      const expendTypeCategories = data?.expend_type_categories;
      store.setWithExpiry(
        "expendTypeCategories",
        expendTypeCategories,
        duration.minute * 6
      );
      return [
        ...(expendTypeCategories?.data?.map((expendTypeCategory) => {
          return {
            label: expendTypeCategory?.name?.[
              i18next.language as keyof MultiLangText
            ] as string,
            value: expendTypeCategory?.id,
          };
        }) ?? []),
      ];
    })
    .catch((err) => {
      return [];
    });
};

export const optionExpendType = () => {
  const cache = store.getWithExpiry("expendTypes") as Pagination<ExpendType>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return [
        ...(cache?.data?.map((expendType) => {
          return {
            label: expendType.name?.[i18next.language as keyof MultiLangText],
            value: expendType.id,
          };
        }) ?? []),
      ];
    });
  }
  return getBoxappExpendTypeBackend()
    .getExpendTypes()
    .then((data) => {
      const expendTypes = data?.expend_types;
      store.setWithExpiry("expendTypes", expendTypes, duration.minute * 6);
      return [
        ...(expendTypes?.data?.map((expendType) => {
          return {
            label: expendType?.name?.[
              i18next.language as keyof MultiLangText
            ] as string,
            value: expendType?.id,
          };
        }) ?? []),
      ];
    })
    .catch((err) => {
      return [];
    });
};
export const optionChargeTypeCategory = () => {
  const cache = store.getWithExpiry(
    "chargeTypeCategories"
  ) as Pagination<ChargeTypeCategory>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return [
        ...(cache?.data?.map((chargeTypeCategory) => {
          return {
            label:
              chargeTypeCategory.name?.[
              i18next.language as keyof MultiLangText
              ],
            value: chargeTypeCategory.id,
          };
        }) ?? []),
      ];
    });
  }
  return getBoxappChargeTypeBackend()
    .getChargeTypeCategories()
    .then((data) => {
      const chargeTypeCategories = data?.charge_type_categories;
      store.setWithExpiry(
        "chargeTypeCategories",
        chargeTypeCategories,
        duration.minute * 6
      );
      return [
        ...(chargeTypeCategories?.data?.map((chargeTypeCategory) => {
          return {
            label:
              chargeTypeCategory.name?.[
              i18next.language as keyof MultiLangText
              ],
            value: chargeTypeCategory.id,
          };
        }) ?? []),
      ];
    })
    .catch((err) => {
      return [];
    });
};

export const optionBranches = () => {
  const cache = store.getWithExpiry("branches") as Pagination<Branch>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return [
        { label: i18next.t("ALL"), value: "ALL" },
        ...(cache?.data
          ?.filter((b) => !b?.disable)
          ?.sort((a, b) => a.sort - b.sort)
          ?.map((branch) => {
            return {
              label:
                branch.name?.[i18next.language as keyof MultiLangText] ?? "",
              value: branch.id ?? "",
            };
          }) ?? [
            {
              label: i18next.t("ALL"),
              value: "ALL",
            },
          ]),
      ];
    });
  }
  return getBoxappBranchBackend()
    .getBranches()
    .then((data) => {
      const branches = data?.branches;
      store.setWithExpiry("branches", branches, duration.minute * 1);
      return [
        { label: i18next.t("ALL"), value: "ALL" },
        ...(branches?.data
          ?.filter((b) => !b?.disable)
          ?.sort((a, b) => a.sort - b.sort)
          ?.map((branch) => {
            return {
              label:
                branch.name?.[i18next.language as keyof MultiLangText] ?? "",
              value: branch.id ?? "",
            };
          }) ?? [
            {
              label: i18next.t("ALL"),
              value: "ALL",
            },
          ]),
      ];
    })
    .catch((err) => {
      return [{ label: i18next.t("ALL"), value: "ALL" }];
    });
};

export const optionUser = () => {
  const cache = store.getWithExpiry("accounts") as Pagination<Account>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return (
        cache?.data?.map((account) => {
          return { label: account.display_name, value: account.id };
        }) ?? []
      );
    });
  }
  return getBoxappAccountBackend()
    .getAccounts({})
    .then((data) => {
      const accounts = data?.accounts;

      store.setWithExpiry("accounts", accounts, duration.minute * 6);
      return (
        accounts?.data?.map((account) => {
          return { label: account.display_name, value: account.id };
        }) ?? []
      );
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const optionRole = () => {
  const cache = store.getWithExpiry("roles") as Pagination<Role>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return (
        cache.data?.map((role) => {
          return { label: role.name, value: role.id };
        }) ?? []
      );
    });
  }
  return getBoxappRoleBackend()
    .getRoles({})
    .then((data) => {
      const roles = data?.roles;
      store.setWithExpiry("roles", roles, duration.minute * 6);
      return (
        roles?.data?.map((role) => {
          return { label: role.name, value: role.id };
        }) ?? []
      );
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const optionLeadFromGroup = [
  {
    options: [
      { label: i18next.t("Phone"), value: "Phone" },
      { label: i18next.t("Facebook"), value: "Facebook" },
      { label: i18next.t("Instagram"), value: "Instagram" },
      { label: i18next.t("Google Ad"), value: "Google Ad" },
      { label: i18next.t("WhatsApp"), value: "Whatsapp" },
      { label: i18next.t("Website"), value: "Website" },
      { label: i18next.t("Walkin"), value: "Walkin" },
      { label: i18next.t("Friend Refer"), value: "Friend Refer" },
      { label: i18next.t("Current Customer"), value: "Current Customer" },
      { label: i18next.t("Event"), value: "Event" },
      { label: i18next.t("Others"), value: "Others" },
    ],
  },
];

export const optionLeadCallStatus = [
  {
    options: [
      { label: i18next.t("PENDING"), value: "PENDING" },
      { label: i18next.t("FOLLOWING"), value: "FOLLOWING" },
      { label: i18next.t("CLOSING"), value: "CLOSING" },
      { label: i18next.t("FAILED"), value: "FAILED" },
    ],
  },
];
export const optionPriority = [
  {
    options: [
      { label: i18next.t("Low"), value: 1 },
      { label: i18next.t("Medium"), value: 2 },
      { label: i18next.t("High"), value: 3 },
      { label: i18next.t("Very High"), value: 4 },
    ],
  },
];

export const optionYN = [
  {
    options: [
      { label: i18next.t("Y"), value: "Y" },
      { label: i18next.t("N"), value: "N" },
    ],
  },
];

export const optionLeadStatus = [
  {
    options: [
      { label: i18next.t("NEW LEAD"), value: "NEW" },
      { label: i18next.t("i18next"), value: "i18next" },
      { label: i18next.t("INTERESTED"), value: "INTERESTED" },
      { label: i18next.t("DESIRED"), value: "DESIRED" },
      { label: i18next.t("CLOSING"), value: "CLOSING" },
      { label: i18next.t("CLOSED"), value: "CLOSED" },
    ],
  },
];

export const optionContractStatus = [
  {
    options: [
      { label: i18next.t("ACTIVE"), value: ContractStatus.ACTIVE },
      { label: i18next.t("TERMINATE"), value: ContractStatus.TERMINATE },
      { label: i18next.t("CANCEL"), value: ContractStatus.CANCEL },
    ],
  },
];
export const optionMasterContractStatus = [
  {
    options: [
      { label: i18next.t("ACTIVE"), value: "ACTIVE" },
      { label: i18next.t("CANCEL"), value: "CANCEL" },
      { label: i18next.t("TERMINATE"), value: "TERMINATE" },
    ],
  },
];

export const titleOptionGroup = [
  {
    options: [
      { label: i18next.t("N/A"), value: "N/A" },
      { label: i18next.t("Mr."), value: "Mr" },
      { label: i18next.t("Mrs."), value: "Mrs" },
      { label: i18next.t("Miss"), value: "Miss" },
    ],
  },
];
export const optionStatus: {
  options: Array<{ label: string; value: "Active" | "Disable" }>;

}[] = [
    {
      options: [
        { label: i18next.t("Active"), value: "Active" },
        { label: i18next.t("Disable"), value: "Disable" },
      ],
    },
  ];

export const optionTemplateTypeGroup = [
  {
    options: [
      { label: i18next.t("WHATSAPP-ORDER"), value: "WHATSAPP_ORDER" },
      { label: i18next.t("WHATSAPP-INVOICE"), value: "WHATSAPP_INVOICE" },
      { label: i18next.t("WHATSAPP-RECEIPT"), value: "WHATSAPP_RECEIPT" },
      { label: i18next.t("WHATSAPP-DEPOSIT-ORDER"), value: "WHATSAPP_DEPOSIT_ORDER" },
      { label: i18next.t("WHATSAPP-DEPOSIT-INVOICE"), value: "WHATSAPP_DEPOSIT_INVOICE" },
      { label: i18next.t("WHATSAPP-DEPOSIT-RECEIPT"), value: "WHATSAPP_DEPOSIT_RECEIPT" },
      { label: i18next.t("WHATSAPP-CONTRACT"), value: "WHATSAPP_CONTRACT" },
      { label: i18next.t("WHATSAPP-LICENSE-AGREEMENT"), value: "WHATSAPP_LICENSE_AGREEMENT" },
      { label: i18next.t("EMAIL"), value: "EMAIL" },
      { label: i18next.t("CONTRACT"), value: "CONTRACT" },
      { label: i18next.t("ORDER"), value: "ORDER" },
      { label: i18next.t("RECEIPT"), value: "RECEIPT" },
      { label: i18next.t("INVOICE"), value: "INVOICE" },
      { label: i18next.t("TRANSACTION"), value: "TRANSACTION" },
    ],
  },
];

export const optionTAndCTypeGroup = [
  {
    options: [
      { label: i18next.t("ORDER"), value: "ORDER" },
      { label: i18next.t("INVOICE"), value: "INVOICE" },
      { label: i18next.t("DEPOSIT ORDER"), value: "DEPOSIT_ORDER" },
      { label: i18next.t("DEPOSIT INVOICE"), value: "DEPOSIT_INVOICE" },
      { label: i18next.t("CONTRACT"), value: "CONTRACT" },
    ],
  },
];
export const optionCustomerTypeGroup = [
  {
    options: [
      {
        label: i18next.t("Individual"),
        value: CustomerType.CUSTOMER_INDIVIDUAL,
      },
      { label: i18next.t("Corporate"), value: CustomerType.CUSTOMER_CORPORATE },
    ],
  },
];

export const optionCustomerStatus = [
  {
    options: [
      {
        label: i18next.t("Active"),
        value: "Active",
      },
      { label: i18next.t("Disable"), value: "Disable" },
    ],
  },
];

export const optionCategory = (categories: Category[]) => {
  return [
    {
      options: [
        ...[{ label: i18next.t("GLOBAL"), value: undefined }],
        ...(categories?.map((category) => {
          return {
            label: category?.name?.[i18next.language as keyof MultiLangText],
            value: category?.id,
          };
        }) ?? []),
      ],
    },
  ];
};

export const optionFeature = (features: Feature[]) => {
  return [
    {
      options: [
        ...[{ label: i18next.t("GLOBAL"), value: undefined }],
        ...(features?.map((feature) => {
          return {
            label: feature?.name?.[i18next.language as keyof MultiLangText],
            value: feature?.id,
          };
        }) ?? []),
      ],
    },
  ];
};
export interface PaidItemChargeTypeOption extends ChargeType {
  label: string;
  value?: string;
  // type?: string;
  // unit_price?: number;
  // default_quantity?: number;
  // quantize?: boolean;
  // charge_name?: MultiLangText;
  // charge_mode?: string;
  // unit?: string;
}
export const optionPaidItemchargeType = (
  branchID: string
): Promise<PaidItemChargeTypeOption[]> => {
  const cache = store.getWithExpiry("branch-" + branchID) as Branch;

  if (cache) {
    return Promise.resolve(cache).then(() => {
      return [
        ...(cache?.charge_types // ?.find((branch) => branch?.id === branchID) // ?.data
          ?.sort((a, b) => a.sort - b.sort)
          .map((charge_type) => ({
            ...charge_type,
            label:
              charge_type?.charge_name?.[
              i18next.language as keyof MultiLangText
              ],
            value: charge_type?.id,
            // type: charge_type?.charge_type_category?.code,
            // unit_price: charge_type?.unit_price,
            // default_quantity: charge_type?.default_quantity,
            // quantize: charge_type?.quantize,
            // charge_name: charge_type?.charge_name,
            // charge_mode: charge_type?.charge_mode,
            // unit: charge_type?.unit,
          })) ?? []),
      ];
    });
  }
  return getBoxappBranchBackend()
    .getBranch(branchID)
    .then((data) => {
      console.log(data);
      const branch = data?.branch;
      store.setWithExpiry("branch-" + branchID, branch, duration.minute * 6);
      return [
        ...(branch?.charge_types // ?.find((branch) => branch?.id === branchID) // ?.data
          ?.sort((a, b) => a.sort - b.sort)
          .map((charge_type) => ({
            ...charge_type,
            label:
              charge_type?.charge_name?.[
              i18next.language as keyof MultiLangText
              ],
            value: charge_type?.id,
            // type: charge_type?.charge_type_category?.code,
            // unit_price: charge_type?.unit_price,
            // default_quantity: charge_type?.default_quantity,
            // quantize: charge_type?.quantize,
            // charge_name: charge_type?.charge_name,
            // charge_mode: charge_type?.charge_mode,
            // unit: charge_type?.unit,
          })) ?? []),
      ];
    })
    .catch((err) => {
      return [];
    });
};
export const optionInvoiceStatusGroup = [
  {
    options: [
      { label: i18next.t("PENDING"), value: "PENDING" },
      { label: i18next.t("PARTIAL_COMPLETED"), value: "PARTIAL_COMPLETED" },
      { label: i18next.t("UNALLOCATED"), value: "UNALLOCATED" },
      { label: i18next.t("COMPLETED"), value: "COMPLETED" },
      { label: i18next.t("CANCELLED"), value: "CANCELLED" },
      { label: i18next.t("VOID"), value: "VOID" },
      { label: i18next.t("DRAFT"), value: "DRAFT" },
    ],
  },
];
export const optionPaymentStatusGroup = [
  {
    options: [
      { label: i18next.t("PAID"), value: "PAID" },
      { label: i18next.t("UNALLOCATED"), value: "UNALLOCATED" },
      { label: i18next.t("VOID"), value: "VOID" },
    ],
  },
];
export const optionPayment = [
  {
    options: [
      { label: i18next.t("CASH"), value: "CASH" },
      { label: i18next.t("WALLET"), value: "WALLET" },
      { label: i18next.t("CHEQUE"), value: "CHEQUE" },
      { label: i18next.t("FPS"), value: "FPS" },
      { label: i18next.t("BANK TRANSFER"), value: "BANK TRANSFER" },
      { label: i18next.t("EPS"), value: "EPS" },
      { label: i18next.t("VISA"), value: "VISA" },
      { label: i18next.t("MASTER"), value: "MASTER" },
      { label: i18next.t("AE"), value: "AE" },
      { label: i18next.t("ALIPAY"), value: "ALIPAY" },
      { label: i18next.t("WECHAT PAY"), value: "WECHAT PAY" },
      { label: i18next.t("TAP & GO"), value: "TAP & GO" },
      { label: i18next.t("OCTOPUS"), value: "OCTOPUS" },
      { label: i18next.t("ONLINE PAYMENT"), value: "ONLINE PAYMENT" },
      { label: i18next.t("PAYPAL"), value: "PAYPAL" },
      { label: i18next.t("CANCEL"), value: "CANCEL" },
      { label: i18next.t("FORFEIT"), value: "FORFEIT" },
      { label: i18next.t("TRANSFER"), value: "TRANSFER" },
    ],
  },
];


export const optionPaymentAccountType = [
  {
    options: [
      { label: i18next.t("ASSET"), value: "ASSET" },
      // { label: i18next.t("DEPOSIT"), value: "DEPOSIT" },
      // { label: i18next.t("INCOME"), value: "INCOME" },
      // { label: i18next.t("CASH"), value: "CASH" },
    ],
  },
];
export const optionSchedulerStatus = [
  {
    label: i18next.t("Status"),
    options: [
      {
        label: i18next.t("ACTIVE"),
        value: "ACTIVE",
      },
      {
        label: i18next.t("INACTIVE"),
        value: "INACTIVE",
      },
      {
        label: i18next.t("CANCEL"),
        value: "CANCEL",
      },
      {
        label: i18next.t("COMPLETE"),
        value: "COMPLETE",
      },
      {
        label: i18next.t("INVALID"),
        value: "INVALID",
      },
    ],
  },
];
export const optionProductType = [
  {
    options: [
      {
        label: i18next.t("Door Card"),
        value: "DOOR_CARD",
      },
    ],
  },
];

export const optionCustomObject = [
  {
    options: [{ label: i18next.t("Customer"), value: "customer" }],
  },
];

export const optionFieldType = [
  {
    options: [{ label: i18next.t("String"), value: "string" }],
  },
];

export const optionExpendStatusGroup = [
  {
    options: [
      { label: i18next.t("PAID"), value: "PAID" },
      { label: i18next.t("VOID"), value: "VOID" },
      { label: i18next.t("CANCELLED"), value: "CANCELLED" },
    ],
  },
];

export const optionTemplates = () => {
  const cache = store.getWithExpiry("templates") as Pagination<Role>;
  if (cache) {
    return Promise.resolve(cache).then(() => {
      return (
        cache.data?.map((role) => {
          return { label: role.name, value: role.id };
        }) ?? []
      );
    });
  }
  return getBoxappTemplateBackend()
    .getTemplates({})
    .then((data) => {
      const templates = data?.templates;
      store.setWithExpiry("templates", templates, duration.minute * 6);
      return (
        templates?.data?.map((template) => {
          return { label: template.name, value: template.id };
        }) ?? []
      );
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
