import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import "./storage.scss";
// Redux

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { useTranslation } from "react-i18next";

import { DepositOrder } from "src/models/depositOrder";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import StorageRentRecordList from "src/pages/Storages/components/StorageRentRecordList";
import { Storage } from "src/models/storage";

interface StorageCurrentViewProps extends RouteComponentProps {
  modal: boolean;
  mode: string;
  order: Order;
  customer: Customer;
  storage: Storage;
  depositOrder: DepositOrder;
  onClose: () => void;
  onSuccess: (mode: string, storage: any) => void;
}

const StorageCurrentView = (props: StorageCurrentViewProps) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const { contextBranch } = useBranchContext();
  const [customer, setCustomer] = useState<Customer>(props.customer);
  const togglemodal = () => {
    props.onClose();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex={-1}
        toggle={togglemodal}
        size={"lg"}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Set Rental Record")}
          </ModalHeader>
          <ModalBody>
            <Card outline color="dark" className="border">
              <CardBody>
                <Row>
                  <Col xs="6">
                    <Row>
                      <Col>
                        <Label>{i18n.t("Storage")} </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{contextStorage?.code}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <Row>
                      <Col>
                        <Label>{i18n.t("Customer")} </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{customer?.first_name + customer?.last_name}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col xs="6">
                    <Label>{i18n.t("Active Rent Record")}</Label>
                  </Col>
                  <Col xs={12}>
                    <StorageRentRecordList
                      columnMode="LIGHT"
                      storage={props.storage}
                      branchID={contextBranch?.id ?? ""}
                      storageID={props.storage?.id ?? ""}
                      onSelect={(rentRecord) => {
                        setCustomer(rentRecord?.customer);
                        // setRentRecord(rentRecord);
                      }}
                    />
                  </Col>
                </Row>

                <Button type="button" color="secondary" onClick={togglemodal}>
                  {i18n.t("Close")}
                </Button>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(StorageCurrentView));
