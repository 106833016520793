import React, { useState, useEffect } from "react";
import "./storageSearch.scss";
import { useStorageContext } from "../../../context/StorageProviderContext";
import StorageTabs from "./StorageTabs";
import { useTranslation } from "react-i18next";
import {
  ModalHeader,
  Modal,
  ModalBody,
  Col,
  Row,
  Card,
  CardBody,
  Button,
} from "reactstrap";

import { RouteComponentProps, withRouter } from "react-router-dom";

import { Storage } from "../../../models/storage";
import { Category } from "src/models/category";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "src/context/GlobalModalContext";

export interface StorageSearchProps extends RouteComponentProps {
  branchId?: string;
  onSearched?: (storage: Storage) => void;
  onClose?: () => void;
  modal: boolean;
  selectStorageMode: "Full" | "OnlyAvailable";
}
export interface StorageGridProps {
  selectStorageMode: "Full" | "OnlyAvailable";
  search?: boolean;
  storages: Storage[];
  onSelected?: (storage: Storage) => void;
}

const StorageSearch = (props: StorageSearchProps) => {
  const { i18n } = useTranslation();
  const [category, setCategory] = useState<Category>();
  const [storages, setStorages] = useState<Storage[]>([]);
  const [fStorages, setFstorages] = useState<Storage[]>([]);
  const { showModal } = useGlobalModalContext();

  const togglemodal = () => {
    setCategory(undefined);
    setStorages([]);
    setFstorages([]);
    if (props.onClose) props.onClose();
  };

  useEffect(() => {
    let fStorages = [];
    if (category) {
      fStorages = storages.filter((storage) => {
        if (storage?.categories?.find((c) => c.id === category.id)) {
          return storage;
        }
      });
    } else {
      fStorages = storages;
    }

    setFstorages(fStorages);
  }, [category, storages]);

  const fetchStorage = async (branchId: string) => {
    try {
      const { storages } = await getBoxappStorageBackend().getStorages(
        branchId,
        {}
      );
      // showModal(MODAL_TYPES.ALERT_MODAL, {
      //   title: i18n.t("Storage Move Out Success"),
      //   messageType: "success",
      //   message: i18n.t("Update Storage Success"),
      // });
      setStorages(storages?.data ?? []);
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("Storage fetch failed"),
        messageType: "error",
        message: errorMsg,
      });
    }
  };
  useEffect(() => {
    if (props.modal && props.branchId) {
      fetchStorage(props.branchId);
    }
  }, [props.modal]);

  const selectStorage = (selectedStorage: Storage) => {
    if (props.onSearched) props.onSearched(selectedStorage);
  };

  const getStorageColor = (storage: Storage) => {
    // eslint-disable-next-line default-case
    switch (storage.status) {
      case "RENT":
        if (storage.rent_type === "YEAR") {
          return "primary";
        } else if (storage.rent_type === "MONTH") {
          return "info";
        }
        return "success";
      case "PENDING":
        return "warning";
      case "AVAILABLE":
        return "light";
      case "EXPIRED":
        return "danger";
      case "DISABLE":
        return "dark";
      default:
        return "";
    }
  };

  const StorageGrid = (props: StorageGridProps) => {
    const { contextStorage } = useStorageContext();

    const disable = (storage: Storage) => {
      if (props.selectStorageMode === "Full") {
        return false;
      }

      if (props.search) {
        if (storage.status !== "AVAILABLE") {
          return true;
        }
      }
      return false;
    };
    return !props.storages ? null : (
      <Row>
        {props.storages
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((storage, i) => {
            return (
              <Col
                key={i}
                xs={3}
                sm={2}
                md={contextStorage ? 2 : 1}
                className={`mt-2 mr-xs-1 mr-md-2`}
              >
                <Button
                  color={getStorageColor(storage)}
                  className={` btn waves-effect gridItem`}
                  disabled={disable(storage)}
                  onClick={() => {
                    if (props.onSelected) {
                      props.onSelected(storage);
                    }
                  }}
                >
                  <span>{storage.code}</span>
                </Button>
              </Col>
            );
          })}
      </Row>
    );
  };
  return (
    <Modal
      id={"storageSearch"}
      fullscreen="md"
      isOpen={props.modal}
      role="dialog"
      // backdrop="static"
      autoFocus={true}
      centered={true}
      className="searchModal"
      toggle={togglemodal}
    >
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          {i18n.t("Search Storages")}
        </ModalHeader>
        <ModalBody>
          <StorageGrid
            selectStorageMode={props.selectStorageMode}
            search={true}
            storages={fStorages}
            onSelected={selectStorage}
          />
        </ModalBody>
      </div>
    </Modal>
  );
};
export default withRouter(StorageSearch);
