import React, { useEffect, useRef } from 'react';

export const useInterval = (callback: Function, delay?: number) => {

    const savedCallback = useRef<Function>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);


    useEffect(() => {
        const tick = async () => {
            try {

                typeof savedCallback.current === 'function' &&
                    (await savedCallback.current());

            } catch (e) {
                console.error(e);
            }
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}