export enum ContractStatus {
  ACTIVE = "ACTIVE",
  END = "END",
  TERMINATE = "TERMINATE",
  CANCEL = "CANCEL",
}





export const contractStatusBadge = {
  "ACTIVE": "badge-success",
  "CANCEL": "badge-secondary",
  "TERMINATE": "badge-danger",
  "END": "badge-danger",
};
