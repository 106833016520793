import React, { useState, createContext, useContext } from "react";
import MessageModal from "../components/Modal/MessageModal";
import AlertModal from "../components/Modal/AlertModal";
import ActionModal from "../components/Modal/ActionModal";
import RemovalActionModal from "../components/Modal/RemovalActionModal";
import {
  MODAL_TYPES,
  ModalComponent,
  ModalProps,
} from "../components/Modal/ModalProps";
import { getMessaging, onMessage } from "firebase/messaging";

const MODAL_COMPONENTS = {
  [MODAL_TYPES.MESSAGE_MODAL]: MessageModal,
  [MODAL_TYPES.ALERT_MODAL]: AlertModal,
  [MODAL_TYPES.ACTION_MODAL]: ActionModal,
  [MODAL_TYPES.REMOVAL_ACTION_MODAL]: RemovalActionModal,
};

interface GlobalModalContextProps {
  onMessageListener?: () => Promise<any>;
  showModal: (modalType: MODAL_TYPES, modalProps: ModalProps) => void;
  hideModal: () => void;
  isMobile: () => boolean;
  store: ModalComponent;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  apiCalls: any;
  setApiCalls: (apiCalls: any) => void;
}

const initalState: GlobalModalContextProps = {
  onMessageListener: () => new Promise((resolve) => { }),
  showModal: (modalType: MODAL_TYPES, modalProps: ModalProps) => { },
  hideModal: () => { },
  isMobile: () => false,
  store: {
    modalType: undefined,
    modalProps: undefined,
    modalShow: false,
  },
  loading: false,
  setLoading: (loading: boolean) => { },
  apiCalls: [],
  setApiCalls: (apiCalls: any) => { },
};

const GlobalModalContext = createContext(
  initalState as GlobalModalContextProps
);
export const useGlobalModalContext = () => useContext(GlobalModalContext);


const GlobalModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [store, setStore] = useState<ModalComponent>();
  const [loading, setLoading] = useState<boolean>();
  const [apiCalls, setApiCalls] = useState([]);

  const messaging = getMessaging();
  // const { modalType, modalProps } = store || {};
  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log("payload", payload)
        resolve(payload);
      });
    });
  const showModal = (modalType: MODAL_TYPES, modalProps: ModalProps) => {
    setStore({
      // ...store,
      modalType,
      modalProps,
      modalShow: true,
    });
  };

  const hideModal = () => {
    setStore({
      // ...store,
      modalType: undefined,
      modalProps: undefined,
      modalShow: false,
    });
  };

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };
  const renderComponent = (p: ModalComponent) => {
    if (!p.modalType) {
      return null;
    }

    const ModalComponent = MODAL_COMPONENTS[p.modalType as MODAL_TYPES];
    if (!ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...p.modalProps} />;
  };

  return (
    <GlobalModalContext.Provider
      value={
        {
          store,
          onMessageListener,
          showModal,
          hideModal,
          isMobile,
          loading,
          setLoading,
          apiCalls,
          setApiCalls,
        } as GlobalModalContextProps
      }
    >
      {store && renderComponent(store)}
      {children}
    </GlobalModalContext.Provider>
  );
};
export default GlobalModalProvider;
