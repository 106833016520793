import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Account } from "../models/account";

export interface updateAccountRequest {
  username?: string;
  display_name?: string;
  email?: string;
  phone_number?: string;
  status?: string;
  mfa_enable?: boolean;
  // role?: Role;
  mfa_method?: string;

  name?: string;
}

class BoxappAccountBackend extends BoxappBackend {
  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  getAccounts = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/accounts`, query);
    } catch (err) {
      throw err;
    }
  };
  getAccount = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/accounts/${id}`);
    } catch (err) {
      throw err;
    }
  };

  changePassword = async (id: string, password: string): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/accounts/${id}`, { password: password });
    } catch (err) {
      throw err;
    }
  };

  createAccount = async (account: Account): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/accounts`, account);
    } catch (err) {
      throw err;
    }
  };

  updateAccount = async (
    id: string,
    account: updateAccountRequest
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/accounts/${id}`, account);
    } catch (err) {
      throw err;
    }
  };
}
let _BoxappAccountBackend: BoxappAccountBackend | null = null;

/**
 * Returns the firebase backend
 */
export const getBoxappAccountBackend = () => {
  if (!_BoxappAccountBackend) {
    _BoxappAccountBackend = new BoxappAccountBackend();
  }
  return _BoxappAccountBackend;
};
