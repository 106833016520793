import React, { useEffect, useState, useMemo, useCallback } from "react";

import moment from "moment-timezone";

import {
  Alert,
  Row,
  Col,
  Input,
  Label,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
} from "reactstrap";

import DatePicker from "react-datepicker";
// Redux
import SubmissionModal from "../../../components/Modal/SubmissionModal";

import { useTranslation } from "react-i18next";
import { RentRecord } from "src/models/rentRecord";
import { Storage } from "src/models/storage";

export interface MoveOutDateUpdateProps {
  rentRecord?: RentRecord;
  modal: boolean;
  storage?: Storage;
  onSuccess: (payload: {
    move_out_date?: number;
    order_id: string;
    customer_id: string;
  }) => void;
  onFailed: () => void;
  onClose: () => void;
}

export const MoveOutDateUpdate = (props: MoveOutDateUpdateProps) => {
  const { i18n } = useTranslation();
  const [moveOutDate, setMoveOutDate] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  useEffect(() => {
    if (props.rentRecord) {
      setMoveOutDate(props.rentRecord?.move_out_date ?? 0);
    }
  }, [props.rentRecord]);

  const onSubmit = async () => {
    setErrorMessage(undefined);

    props.onSuccess({
      customer_id: props.rentRecord?.customer_id ?? "",
      order_id: props.rentRecord?.order_id ?? "",
      move_out_date: moveOutDate,
    });
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <SubmissionModal
      size={"xl"}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${i18n.t("Rent Record Update")}`}
      show={props.modal ?? false}
    >
      <Card className="border">
        <CardBody>
          <Row className="ml-2 mb-1">
            <Col xs="12">
              <Row>
                <Col>
                  <Label>{i18n.t("Storage")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{props.storage?.code}</p>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Row>
                <Col>
                  <Label>{i18n.t("Order Number")} </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{props.rentRecord?.order_no}</p>
                </Col>
              </Row>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup>
                <Label>{`${i18n.t("Move Out Date")}`}</Label>
                <InputGroup>
                  <DatePicker
                    name={`moveOutDate`}
                    dateFormat="yyyy-MM-dd"
                    selected={
                      moveOutDate
                        ? moment.unix(moveOutDate).toDate()
                        : undefined
                    }
                    className="form-control"
                    onChange={(date: Date) =>
                      setMoveOutDate(moment(date).unix())
                    }
                    showYearDropdown
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {errorMessage && (
        <Row>
          <Col>
            <Alert
              color="danger"
              className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
              role="alert"
            >
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </SubmissionModal>
  );
};

export default MoveOutDateUpdate;
