export const hexToRGB = (hex_value: string): { r: number, g: number, b: number } => {
  if (!hex_value) {
    return { r: 0, g: 0, b: 0 }
  }
  if (hex_value.startsWith("#")) {
    hex_value = hex_value.slice(1);
  }
  const numericValue = parseInt(hex_value, 16);
  const r = numericValue >> 16 & 0xFF;
  const g = numericValue >> 8 & 0xFF;
  const b = numericValue & 0xFF;
  return { r, g, b }
}
export function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const getColor = (status: string) => {
  switch (status) {
    case "PAID":
    case "COMPLETED": {
      return "success";
    }
    case "PENDING": {
      return "warning";
    }
    case "VOID":
    case "PARTIAL_COMPLETED":
    case "TERMINATED": {
      return "danger";
    }
    case "CANCELLED":
    default: {
      return "secondary";
    }
  }
};


export const getInvoiceTypeColor = (invoice_type: string) => {
  switch (invoice_type) {
    case "Payment": {
      return "success";
    }
    case "Refund": {
      return "danger";

    }
    case "Forfeit": {
      return "primary";
    }
    case "Transfer": {
      return "warning";
    }
    default: {
      return "light";
    }
  }

}
export const getBadgeColor = (status?: string) => {
  switch (status) {
    case "COMPLETED": {
      return "badge-success";
    }
    case "SETTLED": {
      return "badge-success";
    }
    case "PENDING": {
      return "badge-warning";
    }
    case "DRAFT": {
      return "badge-secondary";
    }
    case "FORFEIT": {
      return "badge-primary";
    }
    case "CANCELLED": {
      return "badge-secondary";
    }
    case "PARTIAL_COMPLETED": {
      return "badge-danger";
    }
    case "UNALLOCATED": {
      return "badge-danger";
    }
    case "TERMINATED": {
      return "badge-danger";
    }
    default: {
      return "badge-link";
    }
  }
};

export const paidItemStatusColor = (status: string) => {
  switch (status) {
    case "VOID":
    case "COMPLETED":
      return "badge-success";
    case "CANCELLED":
      return "badge-secondary";

    case "PAID":
      return "badge-success";

    case "PENDING":
      return "badge-warning";

    case "PARTIAL_COMPLETED":
      return "badge-danger";

    default:
      return "badge-secondary";
  }
};
export const getStorageStatusColor = (
  status: string,
  rent_type?: "YEAR" | "MONTH"
) => {
  switch (status) {
    case "RENT":
      if (rent_type === "YEAR") {
        return "primary";
      } else if (rent_type === "MONTH") {
        return "info";
      }
      return "success";
    case "AVAILABLE":
      return "light";
    case "PENDING":
      return "warning";
    case "EXPIRED":
      return "danger";
    case "TERMINATED":
      return "danger";
    case "DISABLE":
      return "dark";
    case "CLOSED":
      return "";
    default:
      return "";
  }
};
