import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./storage.scss";
// Redux
import { MODAL_TYPES } from "../ModalProps";

import { useGlobalModalContext } from "../../../context/GlobalModalContext";

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { useTranslation } from "react-i18next";
import {
  TerminateRentStorageRequest,
  getBoxappStorageBackend,
} from "src/helpers/boxapp_storage_helper";
import { DepositOrder } from "src/models/depositOrder";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import StorageTerminateContractPanel from "./StorageTerminateContractPanel";
import StorageTerminateRefundPanel from "./StorageTerminateRefundPanel";
import { Data } from "src/api/reponseResult";
import { OrderType } from "src/util/orderType";
import { PaidItem } from "src/models/paidItem";

interface StorageTerminateProps extends RouteComponentProps {
  modal: boolean;
  mode: string;
  order: Order;
  customer: Customer;
  depositOrder: DepositOrder;
  onClose: () => void;
  onSuccess: (mode: string, storage: any) => void;
}
export enum Terminate {
  Contract = "Terminate Contract",
  Refund = "Deposit Refund",
}
const StorageTerminate = (props: StorageTerminateProps) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const { contextBranch } = useBranchContext();
  const [paidItems, setPaidItems] = useState<any[]>([]);
  useEffect(() => {
    if (props.depositOrder) {
      setPaidItems(
        props.depositOrder?.invoices
          ?.flatMap((e) => e?.paid_items ?? [])
          ?.filter((x) => x) ?? []
      );
    }
  }, [props.depositOrder]);

  const togglemodal = () => {
    props.onClose();
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Contract Terminate")}
          </ModalHeader>
          <ModalBody>
            <StorageTerminateContractPanel
              onSubmit={async (e: TerminateRentStorageRequest) => {
                showModal(MODAL_TYPES.ACTION_MODAL, {
                  title: i18n.t("Are you Confirm?"),
                  message: i18n.t(`The termination cannot resume`),
                  onConfirm: () => {
                    showModal(MODAL_TYPES.REMOVAL_ACTION_MODAL, {
                      title: i18n.t("Confirm to terminate"),
                      onConfirm: async () => {
                        try {
                          //terminate
                          if (
                            !contextStorage?.branch_id ||
                            !contextStorage?.id
                          ) {
                            throw new Error("storage not found");
                          }
                          const { storage } =
                            await getBoxappStorageBackend().terminateRentStorage(
                              contextStorage?.branch_id,
                              contextStorage?.id,
                              e
                            );
                          if (!storage) {
                            throw new Error("terminate failed");
                          }
                          if (storage) {
                            showModal(MODAL_TYPES.ALERT_MODAL, {
                              title: i18n.t("Terminate Success"),
                              messageType: "success",
                              message: i18n.t("Terminate Success"),
                            });
                            props.onSuccess(props.mode, storage);
                          }
                        } catch (e) {
                          const errorMsg =
                            (e as any)?.data?.detail ??
                            "" + (e as any)?.data?.message ??
                            "" + e?.toString();
                          showModal(MODAL_TYPES.ALERT_MODAL, {
                            title: i18n.t("rent rental location failed"),
                            messageType: "error",
                            message: errorMsg,
                          });
                        }
                      },
                    });
                  },
                });
              }}
              onCancel={() => {}}
              order={props.order}
              customer={props.customer}
              depositOrder={props.depositOrder}
            />
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(StorageTerminate));
