import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Label,
  FormGroup,
  Row,
  Alert,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { withTranslation, useTranslation } from "react-i18next";

// Redux

import "./storageRent.scss";

import { useStorageContext } from "../../../context/StorageProviderContext";

import { useBranchContext } from "../../../context/BranchProviderContext";

import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { CreateDepositOrderRequest } from "../../../helpers/boxapp_order_helper";

import { Contract } from "src/models/contract";
import { Customer } from "src/models/customer";
import StorageRentDepositPanel from "./StorageRentDepositPanel";
import { getBoxappDepositBackend } from "src/helpers/boxapp_deposit_helper";
import moment from "moment";
import contract from "src/template/contract";

export interface StorageRentNewDepositProps {
  onSuccess: () => void;
  onClose: () => void;
  modal: boolean;
  customer: Customer;
  contract: Contract;
}

const StorageRentNewDeposit = (props: StorageRentNewDepositProps) => {
  const { i18n } = useTranslation();
  const [process, setProcess] = useState<number[]>([]);
  const { contextStorage } = useStorageContext();

  const { contextBranch } = useBranchContext();
  const { showModal } = useGlobalModalContext();

  const togglemodal = () => {
    props.onClose();
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{i18n.t("Deposit")}</ModalHeader>
          <ModalBody>
            <Row>
              {props.customer?.remarks && (
                <Col xs="12">
                  <Col>
                    <Label className="font-size-13 font-weight-bold" htmlFor="">
                      {i18n.t("Customer Remarks (Admin View Only)")}
                    </Label>
                  </Col>
                  <Col>
                    <Alert
                      color="success"
                      className="alert-dismissible fade show  font-size-13"
                      role="warning"
                    >
                      <p>{props.customer?.remarks}</p>
                    </Alert>
                  </Col>
                </Col>
              )}
              {props.contract?.remarks && (
                <Col xs="12">
                  <Col>
                    <Label className="font-size-13 font-weight-bold" htmlFor="">
                      {i18n.t("Contract Remarks")}
                    </Label>
                  </Col>
                  <Col>
                    <Alert
                      color="success"
                      className="alert-dismissible fade show  font-size-13"
                      role="warning"
                    >
                      <p>{props.contract?.remarks}</p>
                    </Alert>
                  </Col>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs="12">
                <Card>
                  <CardHeader className={"bg-success"}>
                    {i18n.t("Contract")}
                  </CardHeader>
                  <CardBody>
                    <Row className="border-bottom mb-2">
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Contract ID")}: <br />
                          {i18n.t(props.contract?.id)}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Deposit Number")}: <br />
                          {i18n.t(
                            props.contract?.deposit_order?.order_no ?? "N/A"
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Contract Period")}: <br />
                          {`${moment
                            .unix(props.contract?.start_date)
                            .format("YYYY-MM-DD")} - ${moment
                            .unix(props.contract?.end_date)
                            .format("YYYY-MM-DD")} (${
                            props.contract?.rent_period
                          } ${i18n.t(props.contract?.rent_type)})`}
                        </p>
                      </Col>
                    </Row>
                    <Label className="font-weight-bold">
                      {i18n.t("Price")}:
                    </Label>
                    <Row className="border-bottom mb-2">
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Original Price")}: <br />
                          {props.contract?.original_price}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Discount Amount")}: <br />
                          {props.contract?.discount_amount}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Final Price")}: <br />
                          {props.contract?.final_price}
                        </p>
                      </Col>
                    </Row>
                    <Label className="font-weight-bold">
                      {i18n.t("Remark")}:
                    </Label>
                    <Row>
                      <Col>
                        <p className="mb-2">
                          {props.contract?.remarks ?? "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <StorageRentDepositPanel
              step={0}
              onSubmit={async (req: CreateDepositOrderRequest) => {
                try {
                  if (req && contextBranch?.id) {
                    const res = await getBoxappDepositBackend().create({
                      ...req,
                      customer_id: props.customer?.id,
                      storage_id: contextStorage?.id,
                      branch_id: contextBranch?.id,
                      contract_id: props.contract?.id,
                    });
                    if (res) {
                      showModal(MODAL_TYPES.ALERT_MODAL, {
                        title: i18n.t("Create Deposit Success"),
                        messageType: "success",
                        message: i18n.t("Create Deposit Success"),
                      });
                    }
                    props.onSuccess();
                  } else {
                    throw new Error("Deposit request is empty");
                  }
                } catch (e) {
                  const errorMsg =
                    (e as any)?.data?.detail ??
                    "" + (e as any)?.data?.message ??
                    "" + e?.toString();
                  showModal(MODAL_TYPES.ALERT_MODAL, {
                    title: i18n.t("rent rental location failed"),
                    messageType: "error",
                    message: errorMsg,
                  });
                }
              }}
              branch={contextBranch}
              storage={contextStorage}
              // mode={props.mode}
              isFinished={true}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 2));
              }}
              open={true}
            ></StorageRentDepositPanel>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentNewDeposit);
