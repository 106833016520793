export const styles = `
    table {
        width: 100%;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        border: 1px solid #ddd;
        margin: 20px 0;
    }
    th, td {
        border: 1px solid #ddd;
        padding: 8px 12px;
        text-align: left;
    }
    th {
        background-color: #f2f2f2;
        font-weight: bold;
    }
    tr:nth-child(even) {
        background-color: #f9f9f9;
    }
`;



export function getDownloadTableTableHTML(title: string, tableOuterHTML: string) {
    return `
        <html>
        <head>
            <style>
                ${styles}
            </style>
        </head>
        <body>
            ${title + tableOuterHTML}
        </body>
        </html>
    `;
}


export function getDownloadTablePrintDateHTML(i18nFunction: any) {
    const currentDate = new Date().toISOString().slice(0, 10);
    const localizedPrintDateText = i18nFunction("Print Date");
    return `<div>${localizedPrintDateText}: ${currentDate}</div>`;
}

export function generateDownloadableHTML(titleText: string, printFileNames: string[], downloadDateLabel: string) {

    const dataTableElement = document.querySelector(".table-responsive");
    if (!dataTableElement) return;

    let table = dataTableElement.querySelector("table");
    if (!table) return;

    let clonedTable = table.cloneNode(true) as HTMLTableElement;
let tdBeforeDivs = clonedTable.querySelectorAll(".tdBefore");
tdBeforeDivs.forEach(div => {

    div.remove(); 
});
    let links = clonedTable.querySelectorAll("a");
    links.forEach((link: HTMLAnchorElement) => {
        let textNode = document.createTextNode(link.textContent || "");
        link.parentNode?.replaceChild(textNode, link);
    });

    let title = `<h1>${titleText}</h1>`;
    const currentDate = new Date().toISOString().slice(0, 10);
    const downloadDateText = `${downloadDateLabel}: ${currentDate}`;

    printFileNames.forEach(printFileName => {
        let combinedHTML = getDownloadTableTableHTML(title + `<div>${downloadDateText}</div>`, clonedTable.outerHTML);
        const blob = new Blob([combinedHTML], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `${printFileName}.html`;
        a.click();
          console.log(clonedTable.outerHTML);
    });
}