import React from "react";

interface SymbolProps {
  label: string;
}
export const Offsymbol = (props: SymbolProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {props.label}
    </div>
  );
};

export const OnSymbol = (props: SymbolProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {props.label}
    </div>
  );
};
