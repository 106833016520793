import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Alert, Col, Row } from "reactstrap";
import DataTable from "../../DataTable";
import { truncatedString } from "../../../util/string";

import { useTranslation } from "react-i18next";
import { getBoxappCustomerBackend } from "../../../helpers/boxapp_customer_helper";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { Branch } from "../../../models/branch";
import { Customer } from "../../../models/customer";
import { MODAL_TYPES } from "../ModalProps";
import { Column } from "../../DataTable/ColumnFilter";
import { Button } from "react-bootstrap";
import CustomerCreate from "src/pages/Customers/customerCreate";

export interface CustomerSearchProps {
  modal?: boolean;
  id: string;
  onClose: () => void;
  branch: Branch;
  onSearched: (customer: Customer) => void;
}

const CustomerSearch = (props: CustomerSearchProps) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalData, setTotalDate] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [focus, setFocus] = useState(false); // 新增狀態追踪焦點
  const inputRef = useRef<HTMLInputElement>(null);
  const { i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [modal, setModal] = useState<boolean>(false);
  const togglemodal = () => {
    props.onClose();
  };
  let filterTimeout: NodeJS.Timeout;
  const setSearchText = (filter: any) => {
    clearTimeout(filterTimeout);
    if (!filter) return;

    filterTimeout = setTimeout(() => {
      setSearch(filter);
      setFocus(true); // 設置需要聚焦
      fetchCustomers(filter, page, limit);
    }, 1500);
  };

  const fetchCustomers = async (filter: any, page: number, limit: number) => {
    setLoading(true);
    try {
      setCustomers([]);
      const response = await getBoxappCustomerBackend().getCustomers({
        ...filter,
        branch_ids: props.branch?.id ?? undefined,
        page: page,
        limit: limit,
      });
      setCustomers(response.customers?.data ?? []);
      setTotalPage(response?.customers?.pageCount ?? 0);
      setPage(response.customers?.page ?? 0);
      setTotalDate(response.customers?.total ?? 0);
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("fetch customers failed"),
        messageType: "error",
        message: errorMsg,
      });
    } finally {
      setLoading(false);
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    fetchCustomers(search, page, limit);
  }, []);

  const openModal = () => {
    setModal(true);
  };
  const updateSuccess = (customer: Customer) => {
    props.onSearched(customer);
    setModal(false);
  };

  const closeModal = () => {
    setModal(false);
  };

  const getRows = () => {
    return customers.map((customer, i) => {
      const row: any = Object.assign({}, customer);
      row["name"] = customer.other_name;
      row["customerTypeGroup"] = i18n.t(customer.customer_type);
      row["idNo"] = truncatedString(customer.id_no, 19);
      row["phone"] = customer.phone;
      row["remarks"] = customer.remarks;
      row.data = customer;
      return row;
    });
  };
  const data = {
    columns: [
      {
        label: i18n.t("Phone"),
        field: "phone",
        sort: "asc",
        searchable: true,
      },
      {
        label: i18n.t("Customer Name"),
        field: "name",
        sort: "asc",
        searchable: true,
      },
      {
        label: i18n.t("Customer Type"),
        field: "customerTypeGroup",
        sort: "asc",
      },
      {
        label: i18n.t("idNo"),
        field: "idNo",
        sort: "asc",
        searchable: true,
      },
      {
        label: i18n.t("Remarks"),
        field: "remarks",
        sort: "asc",
      },
    ],
    rows: getRows() as any,
  } as {
    columns: Column[];
    rows: any[];
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="searchModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Search Customer")}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-2">
              <Col xs={10}></Col>
              <Col xs={2}>
                <div className="text-sm-right">
                  <Button
                    type="button"
                    color="primary"
                    className="btn-rounded waves-effect waves-light "
                    onClick={() => openModal()}
                  >
                    <i className="mdi mdi-plus mr-1"></i>{" "}
                    {i18n.t("Add Customer")}
                  </Button>
                </div>
              </Col>
            </Row>

            <CustomerCreate
              modal={modal}
              mode={"create"}
              customer={undefined}
              onSuccess={updateSuccess}
              onClose={closeModal}
            />
            <DataTable
              noRecordsFoundLabel={i18n.t("No Records")}
              responsive
              striped
              displayEntries={false}
              data={data}
              loading={loading}
              activePage={Number(page)}
              totalPage={Number(totalPage)}
              totalData={Number(totalData)}
              entitiesCount={Number(limit)}
              onClear={() => {
                setSearchText({});
              }}
              placeholderText={`${i18n.t("Please search by")}: ${i18n.t(
                "other_name"
              )}, ${i18n.t("last_name")}, ${i18n.t("first_name")}, ${i18n.t(
                "Phone"
              )}, ${i18n.t("Phone")}, ${i18n.t("Code")}`}
              onSearch={(search: string) => {
                setSearchText({
                  code: search,
                  phone: search,
                  id_no: search,
                  first_name: search,
                  last_name: search,
                  other_name: search,
                });
              }}
              onEntitiesCountChange={(limit) => {
                setLimit(limit);
                fetchCustomers(search, 0, limit);
              }}
              onRowClick={(row) => {
                props.onSearched(row.data as Customer);
              }}
              onPage={(page) => {
                fetchCustomers(search, page, limit);
              }}
            />
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default CustomerSearch;
