
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'
import { Data, ResponseResult } from 'src/api/reponseResult';

class BoxappNotificationBackend extends BoxappBackend {

  updateNotifications = async (notice: any): Promise<Data> => {
    try {

      return await this.put(`${this.baseURL}/notifications`, notice);
    } catch (err) {
      throw err;
    }
  };

  readNotifications = async (param: {
    ids: string[]
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/notifications/read`, {
        ids: param.ids.join(',')
      });
    } catch (err) {
      throw err;
    }
  };

  getNotifications = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/notifications`, query);
    } catch (err) {
      throw err;
    }
  };


}

let _BoxappNotificationBackend: BoxappNotificationBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappNotificationBackend = () => {
  if (!_BoxappNotificationBackend) {
    _BoxappNotificationBackend = new BoxappNotificationBackend();
  }
  return _BoxappNotificationBackend;
};

export { getBoxappNotificationBackend };
