import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { PaymentAccount } from "../models/paymentAccount";
import { Data, ResponseResult } from "../api/reponseResult";

class BoxappPaymentAccountBackend extends BoxappBackend {
  createPaymentAccount = async (payment_account: {
    name: string;
    account_no: string;
    payment_method?: string;
    branch_id?: string;
    account_type: string;
  }): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/payment-accounts`, payment_account);
    } catch (err) {
      throw err;
    }
  };

  updatePaymentAccount = async (
    id: string,
    payment_account: {
      name?: string;
      account_no?: string;
      payment_method?: string;
      branch_id?: string;
      account_type?: string;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/payment-accounts/${id}`, payment_account);
    } catch (err) {
      throw err;
    }
  };
  getPaymentAccounts = async (query: {
    branch_id?: string;
    account_type?: string;
  }): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/payment-accounts`, query);
    } catch (err) {
      throw err;
    }
  };
  getPaymentAccount = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/payment-accounts/${id}`);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappPaymentAccountBackend: BoxappPaymentAccountBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappPaymentAccountBackend = () => {
  if (!_BoxappPaymentAccountBackend) {
    _BoxappPaymentAccountBackend = new BoxappPaymentAccountBackend();
  }
  return _BoxappPaymentAccountBackend;
};

export { getBoxappPaymentAccountBackend };
