import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { ChargeType } from "../models/chargeType";
import { Data, ResponseResult } from "../api/reponseResult";
import { ChargeTypeCategory } from "../models/chargeTypeCategory";
import { MultiLangText } from "src/models/multiLangText";
import { ChargeMode } from "src/util/chargeMode";

class BoxappChargeTypeBackend extends BoxappBackend {
  createChargeType = async (chargeType: {
    quantize: boolean;
    charge_type_category_id?: string;
    charge_name: MultiLangText;
    charge_mode: ChargeMode;
    unit_price: number;
    unit?: string;
    default_quantity: number;
    branches?: string[];
    sort: number;
  }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/chargeTypes`, chargeType);
    } catch (err) {
      throw err;
    }
  };

  updateChargeType = async (
    id: string,
    chargeType: {
      quantize: boolean;
      charge_type_category_id?: string;
      charge_name: MultiLangText;
      charge_mode: ChargeMode;
      unit_price: number;
      unit?: string;
      default_quantity: number;
      branches?: string[];
      sort: number;
    }
  ): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/chargeTypes/${id}`, chargeType);
    } catch (err) {
      throw err;
    }
  };
  getChargeTypes = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/chargeTypes`);
    } catch (err) {
      throw err;
    }
  };
  getChargeType = async (id: string): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/chargeTypes/${id}`);
    } catch (err) {
      throw err;
    }
  };
  createChargeTypeCategory = async (
    param: {
      code: string;
      name: MultiLangText;
    }
  ): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/chargeTypeCategories`, param);
    } catch (err) {
      throw err;
    }
  };
  getChargeTypeCategories = async (): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/chargeTypeCategories`);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappChargeTypeBackend: BoxappChargeTypeBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappChargeTypeBackend = () => {
  if (!_BoxappChargeTypeBackend) {
    _BoxappChargeTypeBackend = new BoxappChargeTypeBackend();
  }
  return _BoxappChargeTypeBackend;
};

export { getBoxappChargeTypeBackend };
