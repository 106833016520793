import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGlobalModalContext } from "../../context/GlobalModalContext";
import { Alert, ModalProps } from "reactstrap";
import i18next from "i18next";

const MessageModal = (props: ModalProps) => {
  const { hideModal, store } = useGlobalModalContext();

  const { title, messageType, message } = store?.modalProps || {};

  const handleModalToggle = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
    hideModal();
  };
  return (
    <SweetAlert
      title={i18next.t(title ?? "")}
      success={messageType === "success"}
      show={true}
      confirmBtnText={i18next.t("OK")}
      cancelBtnText={i18next.t("CANCEL")}
      onConfirm={handleModalToggle}
    >
      <Alert
        color="success"
        className="alert-dismissible fade show  mt-4 mb-4 pt-2 font-size-16"
        role="alert"
      >
        <i className="bx bx-error mr-2 font-size-24"> </i>
        {message && i18next.exists(message) ? i18next.t(message) : message}
      </Alert>
    </SweetAlert>
  );
};

export default MessageModal;
