import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import propertyTranslationENG from "./locales/property/en/translation.json";
import propertyTranslationZH from "./locales/property/zh/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        property: propertyTranslationENG,
      },
      zh: {
        property: propertyTranslationZH,
      },
    },
    lng: "zh",  // 直接設置默認語言為繁體中文
    fallbackLng: "zh", // 如果找不到對應的語言資源，就回落到繁體中文
    defaultNS: "property",
    supportedLngs: ["en", "zh"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;