import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Category } from "../models/category";

class BoxappCategoryBackend extends BoxappBackend {
  createCategory = async (category: Category): Promise<Data> => {
    try {
      return await this.post(`${this.baseURL}/categories`, category);
    } catch (err) {
      throw err;
    }
  };
  updateCategory = async (id: string, category: Category): Promise<Data> => {
    try {
      return await this.put(`${this.baseURL}/categories/${id}`, category);
    } catch (err) {
      throw err;
    }
  };
  getCategories = async (query: any): Promise<Data> => {
    try {
      return await this.get(`${this.baseURL}/categories`, query);
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappCategoryBackend: BoxappCategoryBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappCategoryBackend = () => {
  if (!_BoxappCategoryBackend) {
    _BoxappCategoryBackend = new BoxappCategoryBackend();
  }
  return _BoxappCategoryBackend;
};

export { getBoxappCategoryBackend };
